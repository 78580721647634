import axios from "axios";
import {Ch, De, Fr, Gb, Jp, Tr} from "react-flags-select";
import React from 'react';

export async function getIp (){
    const ipRes = await axios.get('https://geolocation-db.com/json/');
    let currency = '';
    let language = '';

    if(ipRes && ipRes.data){
        if (ipRes.data['country_code'] === 'TR'){
            currency = 'TL';
            language = 'tr';
        }else{
            currency = 'EUR';
            language = 'en'
        }
    }
    return {currency,ip:ipRes.data.IPv4,lang:language}
}

export function findFlag(name){
    if (name === 'Türkçe'){
        return <Tr fontSize={24}/>
    }else if (name === 'İngilizce'){
        return <Gb fontSize={24}/>
    }else if (name === 'Fransızca'){
        return <Fr fontSize={24}/>
    }else if (name === 'Almanca'){
        return <De fontSize={24}/>
    }else if (name === 'Çince'){
        return <Ch fontSize={24}/>
    }else if (name === 'Japonca'){
        return <Jp fontSize={24}/>
    }
}

export function validateYouTubeUrl(urlToParse){
    if (urlToParse) {
        var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (urlToParse.match(regExp)) {
            return true;
        }
    }
    return false;
}

export function validateYouTubeUrlChannel(url){
    if (!url.includes("youtube.com/@")){
        return false;
    }
    return true;
}

export function validateInstagramUrl(url){
    if (!url.includes("instagram.com/")){
        return false;
    }
    return true;
}

export function validateFacebookUrl(url){
    if (!url.includes("facebook.com/")){
        return false;
    }
    return true;
}

export function validateTwitterUrl(url){
    if (!url.includes("twitter.com/")){
        return false;
    }
    return true;
}


export const Base64 = {
    btoa: (input = '',optChars) => {
        let str = input;
        let output = '';
        let  chars =  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        for (
          let block = 0, charCode, i = 0, map = chars;
          str.charAt(i | 0) || ((map = '='), i % 1);
          output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
        ) {
          charCode = str.charCodeAt((i += 3 / 4));

          if (charCode > 0xff) {
            return input;
            // throw new Error(
            //   "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.",
            // );
          }

          block = (block << 8) | charCode;
        }

        return output;
      },

      atob: (input = '',optChars) => {
        let  chars =  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        let str = input.replace(/=+$/, '');
        let output = '';

        if (str.length % 4 == 1) {
          return input;
          // throw new Error(
          //   "'atob' failed: The string to be decoded is not correctly encoded.",
          // );
        }
        for (
          let bc = 0, bs = 0, buffer, i = 0;
          (buffer = str.charAt(i++));
          ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
            ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
            : 0
        ) {
          buffer =  chars.indexOf(buffer);
        }

        return output;
      },
};

export const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
}
