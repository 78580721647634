import React, {useState} from 'react';
import {useEffect} from "react";

import DoctorServiceWithoutToken from '../../service/doctor/doctor.service.without.token';
import DoctorBoxHomePage from './DoctorBoxHomePage';
const DoctorListHomePage = ({setLoading,selectedBranchId}) =>{

    const doctorService = new DoctorServiceWithoutToken();
    const [doctorList,setDoctorList] = useState([]);

    useEffect(()=>{
        setLoading(true);
        if (selectedBranchId){
            doctorService.getDoctorListByBranchId(selectedBranchId).then(res=>{
                if (res&&res.success && res.object.length>0){
                    setDoctorList(res.object);
                }else{
                    setDoctorList([]);
                }
                setLoading(false);
            }).catch(error=>{
                setLoading(false);
            })
        }
    },[selectedBranchId])

    return(
        doctorList.length>0&&doctorList.map(doctor=>{
            return(
                <DoctorBoxHomePage doctor={doctor}/>
            )
        })
    )
}

export default DoctorListHomePage;
