import React, { useContext, useState } from "react";
import {
    Paper,
    makeStyles,
    TextField,
    Button
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {useNavigate} from "react-router-dom";
import { MeetContext } from "./MeetContext";
import logo from "../../../assets/img/logo.png";
import swal from 'sweetalert';

// stylings for the page
const useStyles = makeStyles(() => ({
    background: {
        backgroundColor: "#1a98d5",
        height: "80vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        colorScheme: "dark",
        flexDirection: "column",
    },
    card: {
        backgroundColor: "rgb(0, 30, 60)",
        colorScheme: "light",
        border: "1px solid rgb(19, 47, 76)",
        color: "white",
        padding: "5rem",
    },
    input: {
        width: "350px",
    },
}));

const MeetStartPage = () => {
    const classes = useStyles();

    // we will be preferring dark theme for our page
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    type: "dark",
                },
            }),
        []
    );

    const history = useNavigate();
    const [name, setName] = useContext(MeetContext);

    return (
        <div className={classes.background}>
            <Paper className={classes.card} elevation={4}>
                <div style={{margin:'5%'}}><img src={logo} /></div>
                <ThemeProvider theme={theme}>
                    <div style={{ marginBottom: "1.5rem" }}>
                        <TextField
                            label="Toplantıda gözükecek isminizi girin"
                            variant="outlined"
                            color="default"
                            className={classes.input}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div style={{ marginBottom: "1.5rem", textAlign: "center" }}>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => {

                                if (name === "") {
                                    //handleClick();
                                    swal("Hata!", "Lütfen bir isim giriniz.", "error");
                                    return;
                                }

                                history(`/meet`);
                            }}
                        >
                            Toplantıya Katıl
                        </Button>
                    </div>
                </ThemeProvider>
            </Paper>

        </div>
    );
};

export default MeetStartPage;
