import BaseService from "../base/base.service";

export default class BranchServiceWithoutToken extends BaseService {

    constructor() {
        super();
        this.endpoint = '/web/birim';
    }

    list = () => {
        return this.httpClient.get(this.endpoint + "/list", this.headers).then(res => {
            return res.data;
        })
    }

    findMostPreferBranches = () => {
        return this.httpClient.get(this.endpoint + "/most", this.headers).then(res => {
            return res.data;
        })
    }

}
