import React from "react";
import { Paper, makeStyles, Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {useNavigate} from "react-router-dom";
import logo from "../../../assets/img/logo.png";

// stylings for the page
const useStyles = makeStyles(() => ({
    background: {
        backgroundColor: "#1a98d5",
        height: "80vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        colorScheme: "dark",
        flexDirection: "column",
    },
    card: {
        backgroundColor: "rgb(0, 30, 60)",
        colorScheme: "dark",
        border: "1px solid rgb(19, 47, 76)",
        color: "white",
        padding: "3rem",
    },
    input: {
        width: "350px",
    },
}));

const ThankYouPage = () => {
    const classes = useStyles();

    // we will be preferring dark theme for our page
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    type: "dark",
                },
            }),
        []
    );

    // we will use this to navigate next page
    const history = useNavigate();

    return (
        <div className={classes.background}>
            <Paper className={classes.card} elevation={4}>
                <div style={{margin:'5%',display:"flex",justifyContent:'center'}}><img src={logo} /></div>
                <h4>DoctorDedi' yi tercih ettiğiniz için teşekkür eder, sağlıklı günler dileriz.</h4>
                <ThemeProvider theme={theme}>
                    <div style={{ marginBottom: "1.5rem" }}></div>
                    <div style={{ marginBottom: "1.5rem", textAlign: "center" }}>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => {
                                history(`/profile`);
                            }}
                        >
                            Profile git
                        </Button>
                    </div>
                </ThemeProvider>
            </Paper>
        </div>
    );
};

export default ThankYouPage;
