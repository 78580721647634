import BaseService from "../base/base.service";

export default class DoctorService extends BaseService {

    constructor(token) {
        super(token);
        this.endpoint = '/doctor';
    }

    getDoctorListByBranchId = (branchId) => {
        return this.httpClient.get(this.endpoint + "/getDoctorListByBranchId", {params : {branchId:branchId},headers: this.headersWithParams}).then(res => {
            return res.data;
        })
    }

    getDoctorListByBranchId = (branchId) => {
        return this.httpClient.get(this.endpoint + "/getDoctorListByBranchId", {params : {branchId:branchId},headers: this.headersWithParams}).then(res => {
            return res.data;
        })
    }

    getCvByDoctorId = (doctorId) => {
        return this.httpClient.get(this.endpoint + "/getCvByDoctorId", {params : {doctorId:doctorId},headers: this.headersWithParams}).then(res => {
            return res.data;
        })
    }

    getDoctorById = (id) => {
        return this.httpClient.get(this.endpoint + "/getByDoctorId", { params: { id }, headers : this.headersWithParams});
    }

    getDoctorByUserId = (id) => {
        return this.httpClient.get(this.endpoint + "/getByid", { params: { userId: id }, headers : this.headersWithParams});
    }

    getDoctorGalleryById = (id) => {
        return this.httpClient.get(this.endpoint + "/galeryList", { params: { doctorId:id }, headers : this.headersWithParams});
    }

    getDoctorVideoGalleryById = (id) => {
        return this.httpClient.get(this.endpoint + "/videogaleryList", { params: { doctorId:id }, headers : this.headersWithParams});
    }

    deleteImage = (id) => {
        return this.httpClient.get(this.endpoint + "/deleteImage", { params: { imageId:id }, headers : this.headersWithParams});
    }

    deleteVideo = (id) => {
        return this.httpClient.get(this.endpoint + "/deleteVideo", { params: { videoId:id }, headers : this.headersWithParams});
    }

    saveYoutubeVideo = (data) => {
        return this.httpClient.post(this.endpoint + "/saveYoutubeVideo", data,this.headersParams).then(res => {
            return res.data;
        });
    }

    updateDoctor = (image,data) => {
        let formData = new FormData();
        formData.append('profilePhoto', image);
        formData.append('doctorInfo', data);

        return this.httpClient.post(this.endpoint + "/save", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        }).then(res => {
            return res.data;
        })
    }

    uploadPhoto = (files,doctorId) => {
        let formData = new FormData();

        files.forEach(file=>{
            formData.append('files', file);
        })

        formData.append('doctorId', doctorId);

        return this.httpClient.post(this.endpoint + "/saveOrUpdateDoctorMultiPhoto", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        }).then(res => {
            return res.data;
        })
    }

    uploadVideo = (files,doctorId) => {
        let formData = new FormData();

        files.forEach(file=>{
            formData.append('files', file);
        })

        formData.append('doctorId', doctorId);

        return this.httpClient.post(this.endpoint + "/saveOrUpdateDoctorMultiVideo_", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        }).then(res => {
            return res.data;
        })
    }

    uploadCv = (file,doctorId) => {
        let formData = new FormData();

        formData.append('cv', file);

        formData.append('doctorId', doctorId);

        return this.httpClient.post(this.endpoint + "/saveOrUpdateCV", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        }).then(res => {
            return res.data;
        })
    }

    getDoctorCalendarList = (data) => {
        return this.httpClient.post(this.endpoint + "/doctorcalendardaylist", data,this.headers).then(res => {
            return res.data;
        })
    }

}
