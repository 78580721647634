import React, {useEffect, useState} from "react";
import "./SliderFlex.css";
import BranchServiceWithoutToken from "../../service/branch/branch.service.without.token";
import {useNavigate} from "react-router-dom";

const Slider = () => {

  const [branchList,setBranchList] = useState([]);
  const [nth,setNth] = useState(1);
  const [lastPage,setLastPage] = useState(0);
  const branchService = new BranchServiceWithoutToken();
  const [dataSize,setDataSize] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    branchService.list().then(res=>{
      if (res&&res.object.length>0&&res.success === true){
        setBranchList(res.object);
      }
    })
  },[])

  useEffect(() => {
    if (window.innerWidth <= 768){
      setDataSize(1);
    }else if(window.innerWidth <= 992){
      setDataSize(2)
    }else{
      setDataSize(4);
    }
  },[window.innerWidth])

  useEffect(()=>{
    if (branchList.length>0){
      setLastPage(Math.ceil(branchList.length / dataSize))
    }
  },[branchList])

  const onClickLeftIcon=()=>{
    let nthNew = nth-1;
    setNth(nthNew);
  }

  const onClickRightIcon=()=>{
    let nthNew = nth+1;
    setNth(nthNew);
  }

  function paginate(array) {
    return array.slice((nth - 1) * dataSize, nth * dataSize);
  }

  const goDoctorsAndBrancesPage=(branch)=>{
    let selectedBranch = {
      name : branch.birimAdi,
      value : branch.id,
      disable : false
    }
    navigate('doctorandbranch',{state:{isHomePageRoute:true,branch:selectedBranch}});
  }

  return (
    <div className="slider-container">
      <div className="slider-header"> BRANŞ SEÇİNİZ </div>
      <div className="box-area">
        <div className="left-angle-div">
          {nth !==1 &&<div className="angle-icon-div">
            <span className="angle-icon" onClick={onClickLeftIcon}><i className="fa-solid fa-angle-left fa-2xl"></i></span>
          </div>}
        </div>
        {branchList.length > 0 && paginate(branchList).map((branch,keyind) => {
          return (
              <div key={keyind} className="slider-box">
                <div className="detail">
                  <div style={{display:'flex',height:150,justifyContent:"center",alignItems:"center"}}>
                   <img src= {branch.iconPath} width="150px" alt="icon" />
                  </div>
                  <div style={{display:'flex',height:200,padding:'3%'}}>
                    <p>
                      <span style={{fontWeight:'bold',fontSize:'18px'}}>{branch.birimAdi}</span><br/>
                      {branch.aciklama}
                    </p>
                  </div>
                  <div style={{display:'flex',height:50,justifyContent:"center",alignItems:"center"}}>
                    <a href="#" style={{textDecoration:"none"}}><span className="select-button" onClick={()=>goDoctorsAndBrancesPage(branch)}> Seç </span></a>
                  </div>
                </div>
              </div>
          )
        })}
        <div className="right-angle-div">
        {nth !== (lastPage) && branchList.length > 0 &&<div className="angle-icon-div">
          <span className="angle-icon" onClick={onClickRightIcon}><i className="fa-solid fa-angle-right fa-2xl"></i></span>
        </div>}
        </div>
      </div>
    </div>
  );
};

export default Slider;
