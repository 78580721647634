import React from "react";
import img1 from "../../assets/img/img1.png";
import img2 from "../../assets/img/img2.png";
import img3 from "../../assets/img/img3.png";
import img4 from "../../assets/img/img4.png";
import bgheart from "../../assets/img/bg-heart.png";

import "./media-flex.css";

const MediaFlex = () => {
    return (
        <div className="media-container">
            <div className="media-title-area">
                <h1 className="media-title">MEDYA</h1>
                <h3 className="media-title2">Medya</h3>
                <div className="bgheart">
                    <img src={bgheart} alt="bgheart" width="15%" className="img-fluid" />
                </div>
            </div>
            <div className="media-slider-area">
                <div className="items">
                    <div className="entry">
                        <img src={img1} alt="img1" className="img-fluid" />
                    </div>
                    <div className="entry">
                        <img src={img2} alt="img2"className="img-fluid" />
                    </div>
                    <div className="entry">
                        <img src={img3} alt="img3" className="img-fluid" />
                    </div>
                    <div className="entry">
                        <img src={img4} alt="Smiling person" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MediaFlex;
