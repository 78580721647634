import React from "react";
import cvd from "../../assets/img/cvd.png";

const BlogCheckup = ()=> {
    return(
        <section>
            <h1 className="blog-title">BLOG</h1>
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-content shadow">
                            <div className="pages-content-header">
                                <h2>Check-Up</h2>
                                <img className="content-img img-fluid" height={450} width={450} src="https://images.unsplash.com/photo-1505751172876-fa1923c5c528?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="cvd" />
                                <p style={{ paddingTop: "25px" }}>
                                    Türkçe’de en doğru karşılığı “sağlık kontrolü” olan Check Up,
                                    Check up nedir sorusunu kısaca genel sağlık taraması olarak cevaplandırabiliriz. Ancak bu kontrol ve taramaların herhangi bir hastalık veya şikâyete dayandırılmaması yönüyle diğer sağlık taramalarından ayrılmaktadır. Yapılan sağlık taramalarında yaş, genetik yapı ve çevresel faktörler göz önünde bulundurulmaktadır.
                                    <br />
                                    <br />
                                    Check-up doktorun hasta ile yaptığı görüşme, laboratuvar ve radyolojik tetkikleri doğrultusunda yapılan bir değerlendirmedir. Kişinin yaşam, beslenme tarzı, alışkanlıkları ve ailevi riskler göz önünde bulundurarak hastanın sağlık durumu değerlendirilir.

                                    Check up sonuçlarına göre hastanın yapması gereken ve dikkat edilmesi gereken konular hakkında bilgi verilir. Eğer bir rahatsızlık teşhis edilirse ya da hastanın bir sağlık sorunu var ise, hasta doğru bölüme doğru yönlendirilir.
                                </p>
                                <br />
                                <br />
                                <p>
                                    <b>Neden Yaptırmalısınız?</b>
                                </p>
                                <p>
                                    İleride hastalığa dönüşebilecek riskleriniz tespit edilebiliyor. Hastalıklarınızın erken teşhisinde önemli rol oynuyor.
                                    Ciddi pek çok hastalık, erken teşhis ve tedavi sonucunda önlenebilir. Hastalığın teşhisi geciktikçe risk de artmaktadır. Oysa düzenli yapılan Check-Up’lar sayesinde ileride oluşabilecek hastalıkların önüne geçilebildiği gibi, başlangıç aşamasındaki rahatsızlıkların ilerlemesi de engellenebilir.

                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogCheckup