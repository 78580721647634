import BaseService from "../base/base.service";

export default class AuthService extends BaseService {

    constructor(token) {
        super(token);
        this.endpoint = '/auth';
    }

    signIn = (data) => {
        return this.httpClient.post(this.endpoint + "/signin", data, null).then(res => {
            return res.data;
        }).catch(err=>{
            console.log(err);
        })
    }

    forgotPassword = (email) => {
        return this.httpClient.get(this.endpoint + "/forgotPassword", {params:{email:email}}).then(res => {
            return res.data;
        })
    }

    changePassword = (data) => {
        return this.httpClient.post(this.endpoint + "/changePassword", data,this.headers).then(res => {
            return res.data;
        })
    }

    signUp = (data) => {
        return this.httpClient.post(this.endpoint + "/signup", data, null).then(res => {
            return res.data;
        })
    }

    approveAggrements = (data) => {
        return this.httpClient.post(this.endpoint + "/approveAggrements", data, null).then(res => {
            return res.data;
        })
    }

}
