import React from 'react';
import './doctor-box.css';
import ReactStars from "react-rating-stars-component";
import {Button} from "primereact/button";
import {
    addBranch, addDate,
    addDoctor,
    setActiveStep, setAppointmentType
} from "../../redux/actions/appointment.actions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {APPOINTMENT_TYPE} from "../../redux/const";

const DoctorBox = ({doctor,showBranchInfo,showPaymentButton}) =>{

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const locale = useSelector((state) => state.auth.locale);

    const onSelect = ( ) => {
        dispatch(addDoctor({id:doctor.id,name:doctor.name,surname:doctor.surname}));
        dispatch(setActiveStep(2));
    }

    const paymentForEmergencyDoctor=()=>{
        dispatch(addDoctor({id:doctor.id}));
        dispatch(addBranch({id:doctor.branchId}));
        dispatch(setAppointmentType(APPOINTMENT_TYPE.EMERGENCY));
        dispatch(addDate(new Date()))
        navigate('/profile');
    }

    const getShowedButton=()=>{
        return showPaymentButton ?
            <Button label="Randevu Al" className="p-button-outlined" style={{color:'white'}} onClick={() => paymentForEmergencyDoctor()}/> :
            <Button label="Seç" className="p-button-outlined" style={{color:'white'}} onClick={() => onSelect()}/>
    }

    return(
        <div className="doctor-box-div">
            <div style={{display:"flex",justifyContent:'center',alignItems:"center",padding:'5%'}}>
                <img src={doctor.photoUrl ? doctor.photoUrl : require('../../assets/img/2.1.png')} className="profile-photo-sidebar" style={{ borderRadius: '50%', backgroundColor:'white'}} />
            </div>
            <div style={{display:"flex",flex:4,flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <div className="doctor-box-span" ><span className="doctor-box-span-text" style={{fontWeight:'bold'}}>{doctor.unvan + ' ' + doctor.name + ' ' + doctor.surname}</span></div>
                {showBranchInfo && <div className="doctor-box-span" ><span className="doctor-box-span-text" style={{fontWeight:'bold',textDecoration:"underline"}}>{doctor.branch}</span></div>}
                <div className="doctor-box-span" ><span className="doctor-box-span-text">Seans Ücreti: { locale ? locale.currency === 'TL' ?  doctor.perPriceWithTl : doctor.perPriceWithEuro : doctor.perPriceWithTl} {locale&&locale.currency}</span></div>
                <div className="doctor-box-span" >
                    <ReactStars
                    color="light-gray"
                    count={5}
                    edit={false}
                    size={24}
                    value={doctor.rate ? doctor.rate : 0}
                    activeColor="#ffd700"
                    />
                </div>
                <div className="doctor-box-span" >
                    {getShowedButton()}
                </div>
            </div>
        </div>
    )
}

export default DoctorBox;
