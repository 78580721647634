import PhotoGalleriaEdit from "./PhotoGalleriaEdit";
import React, {useState} from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import VideoGalleriaEdit from "./VideoGalleriaEdit";

const GalleriaTabView=()=>{

    const [activeIndex, setActiveIndex] = useState(0);

    return(
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="Fotoğraflarım">
                <PhotoGalleriaEdit/>
            </TabPanel>
            <TabPanel header="Videolarım">
                <VideoGalleriaEdit/>
            </TabPanel>
        </TabView>
    )
}

export default GalleriaTabView;
