import React from 'react';
import {Card} from "primereact/card";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import {Button} from "primereact/button";
import AuthService from "../service/rest/auth.service";

function ForgotPassword(){

    const authService = new AuthService();

    const _initialValues = () => {
        return {
            email: ""
        };
    };

    const _validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string()
                .email("Geçersiz e-posta formatı")
                .required("Zorunlu Alan")
        });
    };

    const _handleSubmit = (values, { resetForm }) => {
        authService.forgotPassword(values.email).then(res=>{
            if (res&&res.success){
                swal("Başarılı!", "Yeni şifreniz eposta adresinize ve sistemde kayıtlı telefon numaranıza gönderildi.", "success");
                resetForm();
            }else{
                swal("Hata!", res.message, "error");
            }
        })
    }

    return(
        <div style={{display:"flex",justifyContent:'center'}}>
            <Card style={{ width: '35rem', marginBottom: '2em',marginTop:'2em',padding:'1em' }}>
                <Formik
                    initialValues={_initialValues()}
                    validationSchema={_validationSchema}
                    onSubmit={_handleSubmit}
                >
                    {({
                          values,
                          handleChange,
                          handleSubmit,
                          errors,
                          touched,
                          setFieldTouched,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <Field
                                    style={{ margin: "1%" }}
                                    value={values.email}
                                    onChange={handleChange("email")}
                                    onBlur={() => setFieldTouched("email")}
                                    className={
                                        "form-control" +
                                        (errors.email && touched.email
                                            ? " is-invalid"
                                            : "")
                                    }
                                    placeholder="E-posta Adresi "
                                />
                                {errors.email && touched.email && (
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                )}
                            </div>
                            <div style={{display:"flex",marginTop:'1rem',justifyContent:"center"}}>
                                <Button label="Yeni Şifre Gönder"
                                        className="p-button-info p-button-sm" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card>
        </div>
    )
}

export default ForgotPassword;
