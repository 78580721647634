import React,{useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import './appointment.css'
import SelectComponent from "../select/SelectComponent";
import BranchService from "../../service/branch/branch.service";
import { ProgressSpinner } from 'primereact/progressspinner';
import DateAndTimePicker from "./DateAndTimePicker";
import {
    addAppointmentId,
    addBranch, addExchangePrice, addPrice,
    setActiveStep as storeActiveStep, setIsPaymentStep
} from "../../redux/actions/appointment.actions";
import DoctorList from "./DoctorList";
import PaymentForm from "./payment/PaymentForm";
import AppointmentService from "../../service/appointment/appointment.service";
import swal from 'sweetalert';
import {APPOINTMENT_TYPE} from "../../redux/const";
import AppointmentDetail from "./AppointmentDetail";

function getSteps() {
    return ["Birim Seç", "Doktor Seç", "Tarih Seç","Randevu Bilgileri","Ödeme"];
}

const Appointment = ({appointmentType=APPOINTMENT_TYPE.NORMAL}) => {

    const user = useSelector((state) => state.auth.user);
    const appointment = useSelector((state) => state.appointment);
    const locale = useSelector((state) => state.auth.locale);

    let activeStep = useSelector(state => state.appointment.activeStep);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const branchService = new BranchService(user.accessToken);
    const appointmentService = new AppointmentService(user.accessToken);

    const steps = getSteps();
    const dispatch = useDispatch();

    useEffect(()=>{
        if (appointmentType === APPOINTMENT_TYPE.NORMAL){
            getBranchList();
        }
    },[]);

    const previousStep = () => {
        if (activeStep == 0){
            dispatch(storeActiveStep(0))
        }else{
            dispatch(storeActiveStep(activeStep-1))
        }
    }

    const getBranchList = () =>{
        branchService.list().then(res=>{
            if (res&&res.object.length > 0){
                let dataSearch = [];
                res.object.map(branch=>{
                    dataSearch.push({
                        name : branch.birimAdi,
                        disabled: false,
                        value: branch.id
                    })
                })
                setData(dataSearch);
            }
        })
    }

    const angleArea=()=>{
        return (
            <div className="container angles-area">
                <div className="angle-icon-div">
                    Geri <span style={{color:'#0885bf'}} className="angle-icon" onClick={()=>previousStep()}><i className="fa-solid fa-angle-left fa-2xl"></i></span>
                </div>
            </div>
        )
    }

    const setBranchIdAndNext= async (branchId) =>{
        let res = await branchService.findById(branchId);
        if (res.object){
            dispatch(addBranch({id:res.object.id,birimAdi:res.object.birimAdi}));
            dispatch(storeActiveStep(1));
            //nextStep();
        }
    }

    const goPaymentStep= async ()=>{
        try {
            let appointmentTime = getAppointmentTime();

            let currency = locale.currency === 'EUR' ? 'EURO' : locale.currency;

            let request={
                userId: user.userId,
                doctorId: appointment.doctor.id,
                doctorCalendarId: appointment.doctorCalendarId,
                appointmentTime,
                paymentDataDto:{
                    currency: currency
                },
                appointmentType : appointment.appointmentType
            }

            let resAppointment =  await appointmentService.add(request);

            if(resAppointment && resAppointment.success === true){
                dispatch(storeActiveStep(4));
                dispatch(setIsPaymentStep(true));
                dispatch(addExchangePrice(resAppointment.object.paymentDataDto.exchangePrice));
                dispatch(addPrice(resAppointment.object.paymentDataDto.price));
                dispatch(addAppointmentId(resAppointment.object.id));
            }else if(resAppointment && resAppointment.success === false){{
                if(resAppointment.message){
                    swal("Hata",resAppointment.message,"error");
                }
            }
           }
        }catch (e){
            console.log(e);
        }
    }

    const getAppointmentTime=()=>{
        if (appointment.appointmentType === APPOINTMENT_TYPE.EMERGENCY){
            let selectedDate = new Date();

            let day = selectedDate.getDate();
            let month = selectedDate.getMonth() + 1;
            let year = selectedDate.getFullYear();
            let hour = selectedDate.getHours();
            let minute =  selectedDate.getMinutes();
            let minuteTxt = minute.length == 1 ? '0' + minute : minute;
            return  day + '/' + month + '/' + year + ' ' + hour + ':' + minuteTxt;
        }else{
            let selectedDate = new Date(appointment.selectedDate);
            let day = selectedDate.getDate();
            let month = selectedDate.getMonth() + 1;
            let year = selectedDate.getFullYear();
            return  day + '/' + month + '/' + year + ' ' + appointment.hour;
        }

    }

    const renderLoading=()=>{
        return(
            <div className="container flex-div">
                <ProgressSpinner style={{width: '50px', height: '50px'}} />
            </div>
        )
    }

    return (
        <div className="appointment-container">
            {activeStep != 0 && activeStep != 4 && appointment.appointmentType !== APPOINTMENT_TYPE.EMERGENCY && angleArea()}
            <div className="appointment-title">
                <h3>RANDEVU OLUŞTUR</h3>
            </div>
            <div className="appointment-step">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep < 1 ? (
                    <div className="container flex-div">
                        <SelectComponent id="branchSelector"
                                         selectTitle="Branş Seç"
                                         options={data}
                                         autoReload={false}
                                         withImage={false}
                                         setSelectedValue={(val)=>setBranchIdAndNext(val)}/>
                    </div>
                ) : activeStep === 1 ? (
                    <div className="container flex-div">
                        <DoctorList setLoading={(val) => setLoading(val)}/>
                    </div>
                ) : activeStep === 2 ? (
                    <div className="container flex-div">
                        <DateAndTimePicker/>
                    </div>
                ): activeStep === 3 ? (
                    <div className="container flex-div">
                        <AppointmentDetail goPaymentStep={()=>goPaymentStep()}/>
                    </div>
                ) :activeStep === 4 ? (
                    <div className="container flex-div">
                        <PaymentForm/>
                    </div>
                ) : ''}
            </div>
            {loading&&renderLoading()}
        </div>
    );
};

export default Appointment;
