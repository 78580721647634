import React, {useEffect, useState} from "react";
import { MultiSelect } from 'primereact/multiselect';

const languagesData = [
    { name: 'Türkçe'},
    { name: 'İngilizce' },
    { name: 'Fransızca'},
    { name: 'Almanca'},
    { name: 'Çince' },
    { name: 'Japonca'}
];

const defaultLanguage= [{name:'Türkçe'}];

function MultiSelectComponent({languages,setLanguages}){
    const [selectedLanguages, setSelectedLanguages] = useState(defaultLanguage);

    useEffect(()=>{
        setSelectedLanguages(languages);
    },[languages])

    const onChange=(e)=>{
        if (setLanguages){
            setLanguages(e.value);
        }
        setSelectedLanguages(e.value);
    }

    return(
        <MultiSelect
            value={selectedLanguages}
            options={languagesData} onChange={onChange}
            optionLabel="name" placeholder="Konuştuğunuz dilleri seçin"
        />

    )
}

export default MultiSelectComponent;
