import { JitsiMeeting } from "@jitsi/react-sdk";
import { Button } from "primereact/button";
import React, { useEffect, useCallback, useContext } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { onShowDialog } from "../../../core/Dialog/DialogProvider";
import { Base64 } from "../../../util/util";
import { MeetContext } from "./MeetContext";

const MeetMobilePage = () => {
  const domain = "meet.jit.si";
  const navigate = useNavigate();
  const [info, setInfo] = useState({ name: null, rmId: null });
  const meet = useRef(null);
  const [meetapi, setMeetapi] = useState(null);
  const { param } = useParams();

  const onLoadIosLink = () => {
    
    try {
      const strobj=Base64.atob(param)
      const Info=JSON.parse(strobj);

      const token = Info["accessToken"];
      const isMobileMeet = Info["isMobileMeet"];

      if (token && isMobileMeet) {
        const name = Info["name"];
        const rmId = Info["videoUUID"];
        setInfo({ name: name, rmId: rmId });
        window.ReactNativeWebView.postMessage(JSON.stringify({ handle: 3 }));
      } else {
        window.ReactNativeWebView.postMessage(JSON.stringify({ handle: 6 }));
      }
    } catch (error) {}
  }
  


  useEffect(() => {
    try {

      if(param){
        onLoadIosLink();
      }

      const token = localStorage.getItem("accessToken");
      const isMobileMeet = localStorage.getItem("isMobileMeet");

      if (token && isMobileMeet) {
        const name = localStorage.getItem("name");
        const rmId = localStorage.getItem("videoUUID");
        setInfo({ name: name, rmId: rmId });
        window.ReactNativeWebView.postMessage(JSON.stringify({ handle: 3 }));
      } else {
        window.ReactNativeWebView.postMessage(JSON.stringify({ handle: 6 }));
      }
    } catch (error) {}
  }, [param]);

  // ALL OUR HANDLERS
  const handleClose = () => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ handle: 0 }));
    console.log("handleClose");
  };

  // Toplantı odasından ayrılan katılımcılar hakkında etkinlik bildirimleri sağlar.
  // {
  //     id: string // the id of the participant
  // }
  const handleParticipantLeft = async (participant = { id: "" }) => {
    // window.ReactNativeWebView.postMessage(JSON.stringify({handle:1}));
    console.log("handleParticipantLeft", participant);
    await getParticipants();
  };

  // Odaya katılan yeni katılımcılar hakkında etkinlik bildirimleri sağlar.
  const handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant);
    await getParticipants();
  };

  // Yerel kullanıcı video konferansa katıldığında tetiklenen olay bildirimleri sağlar.
  // {
  //     roomName: string, // the room name of the conference
  //     id: string, // the id of the local participant
  //     displayName: string, // the display name of the local participant
  //     avatarURL: string, // the avatar URL of the local participant
  //     breakoutRoom: boolean // whether the current room is a breakout room
  // }
  const handleVideoConferenceJoined = async (
    participant = {
      roomName: "string", // the room name of the conference
      id: "string", // the id of the local participant
      displayName: "string", // the display name of the local participant
      avatarURL: "string", // the avatar URL of the local participant
      breakoutRoom: "boolean", // whether the current room is a breakout room
    }
  ) => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ handle: 2 }));
    console.log("handleVideoConferenceJoined", participant);
    await getParticipants();
  };

  // Yerel kullanıcı video konferanstan ayrıldığında tetiklenen olay bildirimleri sağlar.
  const handleVideoConferenceLeft = (participant = { roomName: "" }) => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ handle: 0 }));
  };

  // GETTING ALL PARTICIPANTS
  const getParticipants = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(meetapi.getParticipantsInfo());
      }, 0);
    });
  };

  useEffect(() => {
    if (meetapi) {
      meetapi.addEventListeners({
        readyToClose: handleClose,
        participantLeft: handleParticipantLeft,
        participantJoined: handleParticipantJoined,
        videoConferenceJoined: handleVideoConferenceJoined,
        videoConferenceLeft: handleVideoConferenceLeft,
      });
    }

    return () => {
      setMeetapi(null);
    };
  }, [meetapi]);

  //    useEffect(() => {
  //     viewMeet&&onShowDialog(viewMeet,{height:"100vh",width:"100vw",contentClassName:"overflow-hidden 100vw 100vh "})
  //      return () => {
  //         onShowDialog(null,{height:"100vh",width:"100vw"})
  //      };
  //    }, [viewMeet])
  const spiner = !meetapi && (
    <div className="w-100 d-flex mt-5 align-items-center flex-column justify-content-center">
      <h3 className="m-3 main-blue-color">Görüşme Başlatılıyor...</h3>
      <span className="pi pi-spin overflow-hidden pi-spinner " style={{ fontSize: "5em", color: "#1a98d5" }}></span>
    </div>
  );

  return (
    <React.Fragment>
      {spiner}
      {info.rmId && (
        <JitsiMeeting
          domain={domain}
          roomName={info.rmId}
          configOverwrite={{
            prejoinPageEnabled: false,
            enableWelcomePage: false,
            startWithAudioMuted: true,
            disableModeratorIndicator: true,
            startScreenSharing: true,
            enableEmailInStats: false,
            disableDeepLinking: true,
          }}
          interfaceConfigOverwrite={{
            DEFAULT_LOGO_URL: "https://www.doctordedi.com/assets/img/favicon.png",
            DEFAULT_WELCOME_PAGE_LOGO_URL: "https://www.doctordedi.com/assets/img/favicon.png",
            APP_NAME: "Doctordedi",
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            MOBILE_APP_PROMO: false,
          }}
          userInfo={{
            displayName: info.name,
          }}
          onApiReady={(externalApi) => {
            console.log("hazır");
            setMeetapi(externalApi);

            // buradan Jitsi Meet Harici API'sine özel olay dinleyicileri ekleyebilirsiniz
            // komutları yürütmek için yerel olarak da saklayabilirsiniz
          }}
          getIFrameRef={(iframeRef) => {
            iframeRef.className = "vh-100 vw-100 position-absolute overflow-hidden   bottom-0 top-0 left-0 right-0  ";
            iframeRef.style.zIndex = 1500;
            meet.current = iframeRef;
          }}
        />
      )}
    </React.Fragment>
  );
};

export default MeetMobilePage;
