import BaseService from "../base/base.service";

export default class AppointmentServiceWithoutToken extends BaseService {

    constructor() {
        super();
        this.endpoint = '/web/appointment';
    }


}
