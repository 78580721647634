import BaseService from "../base/base.service";
import {isObjectEmpty} from "../../util/util";

export default class UserProfileService extends BaseService {

    constructor(token) {
        super(token);
        this.endpoint = '/userprofile';
    }

    getUserProfileById = (id) => {
        return this.httpClient.get(this.endpoint + "/getByid", { params : { userId:id },headers : this.headersWithParams}).then(res => {
            return res.data;
        })
    }
    updateUserProfile = (payload) => {
        return this.httpClient.post(this.endpoint + "/update", { userProfilePhoto: payload.profilePhoto, userProfileRequestDto: payload.userProfile },this.headers).then(res => {
            return res.data;
        })
    }

    updateUserProfileWithoutImage = (payload) => {
        return this.httpClient.post(this.endpoint + "/updateWithoutImage", payload, this.headers).then(res => {
            return res.data;
        })
    }

    updateUser = (image,data) => {
        let formData = new FormData();
        if (!isObjectEmpty(image)){
            formData.append('userProfilePhoto', image);
        }
        formData.append('userProfileRequestDto', data);

        return this.httpClient.post(this.endpoint + "/update", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        }).then(res => {
            return res.data;
        })
    }

}
