import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearState} from "../../../redux/actions/appointment.actions";
import {PaymentApproved} from "../../payment_approved/PaymentApproved";
import {useNavigate} from "react-router-dom";
import {ProgressSpinner} from "primereact/progressspinner";

const PaymentOk=()=>{

    let appointment = useSelector(state => state.appointment);
    let auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showApproved,setShowApproved] = useState(false);

    useEffect(()=>{
        if(auth.isMobilePayment){
            window.ReactNativeWebView.postMessage(JSON.stringify({success:true,message:"Ödemeniz başarı ile alınmıştır."}));
            return ;
        }

        if (!appointment.id){
            return navigate('/profile');
        }

        setTimeout(()=>{
            dispatch(clearState());
            setShowApproved(true);
        },3000)

    },[])

    const renderLoading=()=>{
        return(
            <div className="container flex-div">
                <ProgressSpinner style={{width: '50px', height: '50px'}} />
            </div>
        )
    }

    return(
        <div>
            {!showApproved && renderLoading()}
            {showApproved && <PaymentApproved/>}
        </div>
    )

}

export default PaymentOk;
