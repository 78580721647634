import React, {useEffect, useState} from 'react'
import Styles from './Styles'
import Cards from 'react-credit-cards';
import {useSelector} from "react-redux";
import UserProfileService from "../../../service/user-profile/user.profile.service";
import crypto from "crypto";
import {ErrorMessage, Formik,Field } from "formik";
import * as Yup from "yup";
import AppointmentService from "../../../service/appointment/appointment.service";
import ParameterService from "../../../service/parameter/parameter.service";
import {ProgressSpinner} from "primereact/progressspinner";
import creditcardsecure from "../../../assets/img/creditcardsecure.png"
import visa from "../../../assets/img/visa.png"
import mastercard from "../../../assets/img/mastercard.png"
import {Button} from "primereact/button";
import {onCloseDialog, onShowDialog} from "../../../core/Dialog/DialogProvider";
import swal from "sweetalert";
import {setIsMobilePayment} from "../../../redux/actions/auth.action";
import PaymentService from "../../../service/payment/payment.service";

const PaymentForm=()=>{

    const user = useSelector(state => state.auth.user);
    const appointment = useSelector(state => state.appointment);
    const locale = useSelector(state => state.auth.locale);

    const [params,setParams] = useState(null);
    const [loading,setLoading] = useState(false);
    const [focus,setFocus] = useState('');
    const [basket,setBasket] = useState([]);
    const [currencyTxt,setCurrencyTxt] = useState(null);
    const [currency,setCurrency] = useState(locale.currency);
    const [price,setPrice] = useState(appointment.price); //kullanıcıya gösterilen price
    const [exchangePrice,setExchangePrice] = useState(appointment.exchangePrice); // ödemeye giden kurla hesalanmıs price
    const [ip,setIp] = useState(locale.ip);
    const [exchangeCurrency,setExchangeCurrency] = useState(null);
    const [orderId,setOrderId] = useState(null);
    const [userProfile,setUserProfile] = useState(null);
    const [token,setToken] = useState(null);
    const parameterService = new ParameterService(user.accessToken);
    const userProfileService = new UserProfileService(user.accessToken);
    const appointmentService = new AppointmentService(user.accessToken);
    const paymentService = new PaymentService(user.accessToken);
    const [aggrementChecked, setAggrementChecked] = useState(false);

    const renderLoading=()=>{
        return(
            <div className="loading-div">
                <ProgressSpinner style={{width: '30px', height: '30px'}} />
            </div>
        )
    }

    useEffect(()=>{
        setLoading(true);
        parameterService.findAllParametersByParameterType("PAYMENT").then(res=>{
            if(res && res.success === true && res.object.length > 0){
                let params = {};
                res.object.map(elem=>{
                    params[elem.key] = {key:elem.key,value:elem.value};
                });
                setParams(params);
            }
        }).catch(err =>{
            setLoading(false);
        });

        userProfileService.getUserProfileById(user.userId).then(async res=>{
            if(res && res.success === true && res.object ){
                setUserProfile(res.object);
            }
        }).catch(err =>{
            setLoading(false);
        })

        setIsMobilePayment(false);

    },[]);

    useEffect(()=>{
        appointmentService.findById(appointment.id).then(async res=>{
            if(res && res.success === true && res.object && currency){
                if(currency === 'TL'){
                    setCurrencyTxt('TL');
                    setExchangeCurrency('TL');
                }else{
                    setCurrencyTxt('EUR');
                    setExchangeCurrency('TL');
                }
                setOrderId(res.object.paymentDataDto.orderId);
                setLoading(false);
            }
        }).catch(err =>{
            setLoading(false);
        })
    },[currency]);

    useEffect(()=>{
        if (params != null && userProfile != null && orderId != null && exchangePrice != null && ip != null){
            createToken(params);
            var basket = JSON.stringify([
                ['DoctorDedi', exchangePrice, 1]
            ]);
            setBasket(basket);
        }
    },[params,ip,orderId,exchangePrice,userProfile]);

    const renderAggrement=()=>{

        const view=<div style={{display:"flex",flexDirection:'column',height:'100%'}}>
            <div style={{display:"flex",height:'90%'}}>
                <iframe src="./documents/Satış Sözleşmesi.pdf" height="100%" width="100%"/>
            </div>
            <div style={{display:"flex",height:'9%',justifyContent:"center",marginTop:'1%'}}>
                <Button label="Okudum, onaylıyorum" onClick={()=> {
                    setAggrementChecked(true);
                    onCloseDialog();
                }} className="p-button-outlined p-button-primary" />
            </div>
        </div>

        onShowDialog(view,{width:'80%',height:'100%'});

    }

    const createToken=(params)=>{
        const hashSTR = `${params.merchant_id.value}${ip}${orderId}${userProfile.email}${exchangePrice}${params.payment_type.value}${params.installment_count.value}${exchangeCurrency}${params.test_mode.value}${params.non_3d.value}`;
        const paytr_token = hashSTR + params.merchant_salt.value;
        const token = crypto.createHmac('sha256', params.merchant_key.value).update(paytr_token).digest('base64');
        setToken(token);
    }

    const _validationSchema = () => {
        return Yup.object().shape({
            card_number: Yup.string().required("Zorunlu alan").test('len', '16 karakter olmalı', val => val&&val.length === 16),
            cc_owner: Yup.string().required("Zorunlu alan"),
            expiry_month: Yup.string().required("Zorunlu alan").test('len', '2 karakter olmalı', val => val&&val.length === 2),
            expiry_year: Yup.string().required("Zorunlu alan").test('len', '2 karakter olmalı', val => val&&val.length === 2),
            cvv: Yup.string().required("Zorunlu alan").test('len', '3 karakter olmalı', val => val&&val.length === 3)
        });
    };

    const forwardAskida=()=>{
        paymentService.forwardAskida(orderId).then(res=>{

        })
    };

    return(
        <Styles>
            <Formik
                initialValues={{
                    card_number: '',
                    cc_owner : '',
                    expiry_month:'',
                    expiry_year:'',
                    cvv:''
                }}
                validationSchema={_validationSchema}
            >
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      setFieldTouched,
                      resetForm,
                      isValid
                  }) => (
                    <div>
                        <form style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}}
                              onSubmit={(e) => {
                                  if(!aggrementChecked){
                                      e.preventDefault();
                                      swal("Uyarı","Lütfen satış sözleşmesini onaylayın","warning");
                                      return false;
                                  }

                                  if(!isValid) {
                                      e.preventDefault();
                                      return;
                                  }

                                  forwardAskida();

                              }} action= "https://www.paytr.com/odeme"  method= "post" >
                            <div style={{display:'flex',justifyContent:'space-between',backgroundColor:'#0885bf'}}>
                                <span style={{fontSize:'0.9rem',color:'white',fontFamily: 'inherit'}}>DOCTORDEDİ</span>
                                <span style={{fontSize:'0.7rem',color:'white'}}> Güvenli bağlantı <i
                                    className="fa-solid fa-lock"></i> </span>
                            </div>
                            <div>{loading&&renderLoading()}</div>
                            <p style={{textAlign:"center"}}>Hesabınızdan çekilecek tutar : {price} {currencyTxt} </p>
                            <Cards
                                number={values.card_number || ''}
                                name={values.cc_owner || ''}
                                expiry={values.expiry_month + '/' + values.expiry_year || ''}
                                cvc={values.cvv || ''}
                                focused={focus}
                                placeholders={{name:'Ad/Soyad'}}
                                locale={{valid:'Son.Kul.Tar.'}}
                            />
                            <div>
                                <Field
                                    name="card_number"
                                    style={{ margin: "1%" }}
                                    value={values.card_number}
                                    onChange={handleChange("card_number")}
                                    onBlur={() => setFieldTouched("card_number")}
                                    onFocus={(e)=>setFocus(e.target.name)}
                                    className={
                                        "form-control" +
                                        (errors.card_number && touched.card_number
                                            ? " is-invalid"
                                            : "")
                                    }
                                    placeholder="Kart Numarası"
                                />
                                {errors.card_number && touched.card_number && (
                                    <ErrorMessage
                                        name="card_number"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                )}
                            </div>
                            <div>
                                <Field
                                    name="cc_owner"
                                    style={{ margin: "1%" }}
                                    value={values.cc_owner}
                                    onChange={handleChange("cc_owner")}
                                    onBlur={() => setFieldTouched("cc_owner")}
                                    onFocus={(e)=>setFocus(e.target.name)}
                                    className={
                                        "form-control" +
                                        (errors.cc_owner && touched.cc_owner
                                            ? " is-invalid"
                                            : "")
                                    }
                                    placeholder="Ad/Soyad"
                                />
                                {errors.cc_owner && touched.cc_owner && (
                                    <ErrorMessage
                                        name="cc_owner"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                )}
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{display:"flex",flex:2,flexDirection:"column",margin:0}}>
                                    <Field
                                        name="expiry_month"
                                        style={{ margin: "1%" }}
                                        value={values.expiry_month}
                                        onChange={handleChange("expiry_month")}
                                        onBlur={() => setFieldTouched("expiry_month")}
                                        onFocus={(e)=>setFocus(e.target.name)}
                                        className={
                                            "form-control" +
                                            (errors.expiry_month && touched.expiry_month
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Ay"
                                    />
                                    {errors.expiry_month && touched.expiry_month && (
                                        <ErrorMessage
                                            name="expiry_month"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    )}
                                </div>
                                <div style={{display:"flex",flex:2,flexDirection:"column",marginLeft:'1%'}}>
                                    <Field
                                        name="expiry_year"
                                        style={{ margin: "1%" }}
                                        value={values.expiry_year}
                                        onChange={handleChange("expiry_year")}
                                        onBlur={() => setFieldTouched("expiry_year")}
                                        onFocus={(e)=>setFocus(e.target.name)}
                                        className={
                                            "form-control" +
                                            (errors.expiry_year && touched.expiry_year
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Yıl"
                                    />
                                    {errors.expiry_year && touched.expiry_year && (
                                        <ErrorMessage
                                            name="expiry_year"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    )}
                                </div>
                                <div style={{display:"flex",flex:2,flexDirection:"column",marginLeft:'1%'}}>
                                    <Field
                                        name="cvv"
                                        style={{ margin: "1%" }}
                                        value={values.cvv}
                                        onChange={handleChange("cvv")}
                                        onBlur={() => setFieldTouched("cvv")}
                                        onFocus={(e)=>setFocus('cvc')}
                                        className={
                                            "form-control" +
                                            (errors.cvv && touched.cvv
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="CVC"
                                    />
                                    {errors.cvv && touched.cvv && (
                                        <ErrorMessage
                                            name="cvv"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="field-checkbox" style={{display:'flex',alignItems:"center"}}>
                                <input checked={aggrementChecked} type="checkbox" onChange={e => setAggrementChecked(e.target.checked)} />
                                <p style={{width:'auto',marginLeft:'1% !important'}} htmlFor="binary"><a className="aggrement-a" onClick={renderAggrement}>Satış sözleşmesi</a><span className="info-p">'ni okudum, kabul ediyorum. (*)</span></p>
                            </div>

                            <div className="buttons">
                                <button  disabled={loading} type="submit" onClick={() => handleSubmit()}>
                                    Öde
                                </button>
                                <button
                                    type="button"
                                    onClick={resetForm}
                                >
                                    Temizle
                                </button>
                            </div>
                            <div style={{display:'flex',borderTop:'1px solid #0885bf'}}>
                                <div style={{display:'flex',flex:1,justifyContent:'flex-start'}}>
                                    <img src={creditcardsecure} width="50" height="50"/>
                                </div>
                                <div style={{display:'flex',flex:2,justifyContent:'flex-end'}}>
                                    <img src={visa} width="50" height="50"/>
                                    <img src={mastercard} width="50" height="50"/>
                                </div>
                            </div>
                            <input type="hidden" name="merchant_id" value= {params !== null && params['merchant_id'].value}/>
                            <input type="hidden" name="user_ip" value= { ip!== null && ip }/>
                            <input type="hidden" name="merchant_oid" value= { orderId !== null && orderId }/>
                            <input type="hidden" name="email" value= {userProfile !== null && userProfile.email}/>
                            <input type="hidden" name="payment_type" value= {params !== null && params['payment_type'].value}/>
                            <input type="hidden" name="payment_amount" value= { exchangePrice !== null && exchangePrice }/>
                            <input type="hidden" name="currency" value= { exchangeCurrency!== null && exchangeCurrency } />
                            <input type="hidden" name="test_mode" value= {params !== null && params['test_mode'].value} />
                            <input type="hidden" name="non_3d" value= {params !== null && params['non_3d'].value} />
                            <input type="hidden" name="merchant_ok_url" value= {params !== null && params['merchant_ok_url'].value} />
                            <input type="hidden" name="merchant_fail_url" value= {params !== null && params['merchant_fail_url'].value} />
                            <input type="hidden" name="user_name" value= {userProfile !== null && userProfile.user.username} />
                            <input type="hidden" name="user_address" value= { ip!== null && ip }/>
                            <input type="hidden" name="user_phone" value= {userProfile !== null && userProfile.phoneNumber} />
                            <input type="hidden" name="user_basket" value= {basket} />
                            <input type="hidden" name="debug_on" value=  {params !== null && params['debug_on'].value} />
                            <input type="hidden" name="client_lang" value="tr"/>
                            <input type="hidden" name="paytr_token" value= { token != null && token}/>
                            <input type="hidden" name="non3d_test_failed" value=  {params !== null && params['non3d_test_failed'].value} />
                            <input type="hidden" name="installment_count" value= {params !== null && params['installment_count'].value}/>
                            <input type="hidden" name="card_type" value= {params !== null && params['card_type'].value} />
                            <input type="hidden" name="no_installment" value="1"/>
                            <input type="hidden" name="max_installment" value="0"/>
                            <input type="hidden" name="lang" value="tr"/>
                        </form>
                    </div>
                )}
            </Formik>
        </Styles>
    )
}

export default PaymentForm;
