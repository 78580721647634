import React from "react";
import './dynamic.search.css'
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import {useNavigate} from "react-router-dom";

const DynamicSearchResult=({data})=>{

    const navigate = useNavigate();

    const goDoctorsAndBrancesPage=(branch)=>{
        let selectedBranch = {
            name : branch.birimAdi,
            value : branch.id,
            disable : false
        }
        navigate('/doctorandbranch',{state:{isHomePageRoute:true,branch:selectedBranch}});
    }

    return(
        <Card style={{color:'black', border:'2px solid #0885bf',position:'absolute',backgroundColor:'white',padding:'0em',width:'100%',marginTop:'0.5em',maxHeight:'300px',overflowY:'scroll'}}>
            {
                 data.map((elem,index)=>{
                    let style = index === 0 ? {display:'flex',justifyContent:"center",paddingTop:'1.25em'} : {display:'flex',justifyContent:"center"};

                    let branchName = elem[0];
                    let branchId =   elem[1][0].birimId;

                        return(
                            <div>
                                <div style={{display:"flex",justifyContent:'start',marginTop:'1.25em',marginLeft:'1em',zIndex:2}}>
                                    <a href='' style={{textDecoration:'none'}} onClick={()=>goDoctorsAndBrancesPage({id:branchId,birimAdi:branchName})}>
                                        <span style={{padding:'5px',borderRadius:'5px',color:'white',backgroundColor:'#0885bf',fontWeight:"bold"}}>
                                        <i className="fa-solid fa-user-doctor"></i>
                                            {branchName}
                                    </span>
                                    </a>
                                </div>
                                <Divider style={{height:'2px',position:'absolute',top:'0'}}/>
                                {
                                 elem[1].map((doctor,i)=>{
                                    return (
                                        <div  style={i === 0 ? {marginLeft:'1em',display:'flex',justifyContent:"start",backgroundColor:'#eee',marginTop:'1em'} :  i%2 === 0 ? {marginLeft:'1em',display:'flex',justifyContent:"start",backgroundColor:'#eee'} :  {marginLeft:'1em',display:'flex',justifyContent:"start",backgroundColor:'white'}}>
                                            <a style={{textDecoration:'none',paddingLeft:'1em'}} href={'/doctor/'+doctor.id}><span className="resultText">{doctor.name + ' ' + doctor.surname}</span></a>
                                        </div>
                                    )
                                 })
                            }
                            </div>
                 )
                })
            }
        </Card>
    )

}

export default DynamicSearchResult;
