export const AUTH_CONSTANT = {
    SIGN_IN: 'SIGN_IN',
    LOG_OUT: 'LOG_OUT',
    LOGIN_ERR: 'LOGIN_ERR',
    CLEAR_ERR: 'CLEAR_ERR',
    SET_LOCALE: 'SET_LOCALE',
    SET_REQUEST_USER: 'SET_REQUEST_USER',
    SET_LOGIN_LOADING: 'SET_LOGIN_LOADING',
    SET_PASSWORD_CHANGED: 'SET_PASSWORD_CHANGED',
    SET_IS_MOBILE_PAYMENT: 'SET_IS_MOBILE_PAYMENT',
    SET_PHONE: 'SET_PHONE',
    APPROVE_AGGREMENTS: 'APPROVE_AGGREMENTS',
    SET_KVKK_AGGREMENT: 'SET_KVKK_AGGREMENT',
    SET_PRIVACY_AGGREMENT: 'SET_PRIVACY_AGGREMENT'
}

export const APPOINTMENT_CONSTANT = {
    ADD_BRANCH: 'ADD_BRANCH',
    ADD_DOCTOR: 'ADD_DOCTOR',
    ADD_HOUR: 'ADD_HOUR',
    ADD_DATE: 'ADD_DATE',
    ADD_PRICE: 'ADD_PRICE',
    ADD_EXCHANGE_PRICE: 'ADD_EXCHANGE_PRICE',
    ADD_DOCTOR_CALENDAR_ID: 'ADD_DOCTOR_CALENDAR_ID',
    ADD_APPOINTMENT_ID: 'ADD_APPOINTMENT_ID',
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
    SET_IS_NEW_APPOINTMENT: 'SET_IS_NEW_APPOINTMENT',
    SET_IS_PAYMENT_STEP: 'SET_IS_PAYMENT_STEP',
    SET_IS_PAYMENT_FAIL: 'SET_IS_PAYMENT_FAIL',
    SET_APPOINTMENT_TYPE: 'SET_APPOINTMENT_TYPE',
    SET_EMERGENCY_DOCTOR_APPOINTMENT_ID: 'SET_EMERGENCY_DOCTOR_APPOINTMENT_ID',
    CLEAR_STATE: 'CLEAR_STATE'
}


export const HOURS= [
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30"
]

export const APPOINTMENT_TYPE={
    'NORMAL':'NORMAL',
    'EMERGENCY':'EMERGENCY'
}
