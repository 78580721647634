import BaseService from "../base/base.service";

export default class AppointmentService extends BaseService {

    constructor(token) {
        super(token);
        this.endpoint = '/appointment';
    }

    add = (data) => {
        return this.httpClient.post(this.endpoint + "/add", data ,this.headers).then(res => {
            return res.data;
        })
    }

    cancel = (data) => {
        return this.httpClient.post(this.endpoint + "/cancel" , data ,this.headers).then(res => {
            return res.data;
        })
    }

    detail = (id) => {
        return this.httpClient.get(this.endpoint + "/detail/" + id  ,this.headers).then(res => {
            return res.data;
        })
    }

    fetchActiveAppointmentsByUser = (userId) => {
        return this.httpClient.get(this.endpoint + "/getAppointmentActiveByUser", { params: { userId },  headers: this.headersWithParams });
    }

    fetchPastAppointmentsByUser = (userId) => {
        return this.httpClient.get(this.endpoint + "/getAppointmentPastByUser", { params: { userId },  headers: this.headersWithParams });
    }

    fetchActiveAppointmentsByDoctor = (userId) => {
        return this.httpClient.get(this.endpoint + "/getAppointmentActiveByDoctor", { params: { userId },  headers: this.headersWithParams });
    }

    fetchPastAppointmentsByDoctor = (userId) => {
        return this.httpClient.get(this.endpoint + "/getAppointmentPastByDoctor", { params: { userId },  headers: this.headersWithParams });
    }

}
