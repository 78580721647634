import BaseService from "../base/base.service";

export default class BranchService extends BaseService {

    constructor(token) {
        super(token);
        this.endpoint = '/birim';
    }

    list = () => {
        return this.httpClient.get(this.endpoint + "/list", this.headers).then(res => {
            return res.data;
        })
    }

}
