import React, {useEffect, useState} from "react";
import './main-header-flex.css';

import LoginFormFlex from "../Forms/LoginFormFlex";
import SignUpFormFlex from "../Forms/SignUpFormFlex";
import {useSelector} from "react-redux";
import swal from "sweetalert";
import DynamicSearchResult from "../custom/DynamicSearchResult";
import DoctorServiceWithoutToken from "../../service/doctor/doctor.service.without.token";
import appstorelogo from '../../assets/img/appstorelogo.png'
import googleplaylogo from '../../assets/img/gplaylogo.png'

const MainHeader = () => {

    const [visibleForm, setVisibleForm] = useState(false);
    const [showLoginArea, setShowLoginArea] = useState(true);
    const [word, setWord] = useState(null);
    const [searchResult, setSearchResult] = useState([]);
    const user = useSelector(state => state.auth.user);

    const doctorService = new DoctorServiceWithoutToken();

    useEffect(()=>{
        setWord(null);
    },[])

    useEffect(()=>{
        if (user.accessToken){
            setShowLoginArea(false);
        }else{
            setShowLoginArea(true);
        }
    },[user.accessToken]);

    useEffect(()=>{
        if (word&&word.length>=3){
            getData();
        }
    },[word])

    const renderLoginArea=()=>{
        return(
            <>
                {!visibleForm && <LoginFormFlex onChangeForm={onChangeForm} />}
                {visibleForm && <SignUpFormFlex onChangeForm={onChangeForm} />}
            </>
        )
    }

    const renderLoginAreaMobile=()=>{
        return(
            <section style={{marginTop:'5%',marginBottom:'5%'}}>
                <div className="container">
                    <div className="row">
                        <div className="login_form">
                            {!visibleForm && <LoginFormFlex onChangeForm={onChangeForm} />}
                            {visibleForm && <SignUpFormFlex onChangeForm={onChangeForm} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const onChangeForm = () => {
        setVisibleForm(!visibleForm);
    };

    const search=()=>{
        if (!word){
            swal("Uyarı!", "Aramak için birşeyler yazın.", "warning");
            return;
        }
        if (word.length<3){
            swal("Uyarı!", "En az 3 karakter ile aratınız.", "warning");
        }else{
            getData();
        }
    }

    const getData=()=>{
        doctorService.dynamicSearch(word).then(res=>{
            if (res){
                if (res.success&&res.object){
                    setSearchResult(Object.entries(res.object))
                }
            }
        })
    }

  return (
      <div className="content1-container">
          <div className="left-side">
            <div style={{height:'100%',display:'flex',flexDirection:'column',alignItems:'start',justifyContent:"center"}}>
                <div style={{display:'flex',height:'20%'}}>
                    <a href="#"><img className="rotateZ270 flip-with-scale" src={appstorelogo} alt="appstore" width="144px" height="47px"/></a>
                </div>
                <div style={{display:'flex',height:'20%'}}>
                    <a href="https://play.google.com/store/apps/details?id=com.doctordedi&hl=tr&gl=US"><img className="rotateZ270" src={googleplaylogo} alt="appstore" width="144px" height="47px"/></a>
                </div>
            </div>
          </div>
          <div className="center-side">
              <div className="input-area" style={{zIndex:3}}>
                  <div style={{position:'relative',zIndex:3}}>
                      <input
                          type="search"
                          className="input"
                          onChange={(e) => setWord(e.target.value)}
                          placeholder="Branş veya doktor ara"
                      />
                      {/*  <button onClick={search}>
                          <i className="pi pi-search" style={{color:"white"}}></i>
                      </button> */}
                      {searchResult.length > 0 && word && word.length>=3 && <DynamicSearchResult data={searchResult}/>}
                  </div>
              </div>
              {window.innerWidth <= 768 ?  showLoginArea && renderLoginAreaMobile() :
                  <div className="content-area">
                  <div className="img-area" style={{zIndex: 1}}>

                  </div>
                  <div className="login-area" style={{zIndex:2}}>
                      {showLoginArea && renderLoginArea()}
                  </div>
              </div>}
          </div>
          <div className="right-side">

          </div>
      </div>
  );
};

export default MainHeader;
