import SelectSearch, {fuzzySearch} from "react-select-search";
import React, {useEffect, useState} from "react";
import './styles.css';

const SelectComponent = ({options,selectTitle,setSelectedValue,withImage,selectedBranch,autoReload}) => {

    const [value, setValue] = useState(selectedBranch);

    useEffect( ()=>{
        if(autoReload){
            setSelectedValue(selectedBranch.value);
            setValue(selectedBranch);
        }
    },[selectedBranch])

    function renderOptions(props, option, snapshot, className) {
        const imgStyle = {
            borderRadius: "50%",
            verticalAlign: "middle",
            marginRight: 10
        };

        return (
            <button
                value={option.value}
                {...props}
                className={className}
                type="button"
            >
      <span>
        <img
            alt=""
            style={imgStyle}
            width="32"
            height="32"
            src={option.photo}
        />
        <span>{option.name}</span>
      </span>
            </button>
        );
    }

    const setValues = value => {
        setValue(value);
        setSelectedValue(value);
    }

    return(
        <SelectSearch
            className="select-search"
            options={options}
            value={value}
            onChange={setValues}
            renderOption={withImage&&renderOptions}
            search
            filterOptions={fuzzySearch}
            placeholder={selectTitle}
        />
    )
}

export default SelectComponent;
