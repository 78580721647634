import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DoctorService from "../../service/doctor/doctor.service";
import './appointment-dialog.css'
import {ProgressSpinner} from "primereact/progressspinner";
import {addPrice} from "../../redux/actions/appointment.actions";

const AppointmentDetail=({showDialog,goPaymentStep})=>{

    let appointment = useSelector((state) => state.appointment);
    let user = useSelector((state) => state.auth.user);
    let locale = useSelector((state) => state.auth.locale);
    let dispatch = useDispatch();

    const doctorService = new DoctorService(user.accessToken);
    const [doctor,setDoctor] = useState({tibbiBirim:{birimAdi:''},name:'',surname:'',unvan:''});
    const [price,setPrice] = useState(null);
    const [loading,setLoading] = useState(true);
    const [currency,setCurrency] = useState(null);

    useEffect(()=>{
        setLoading(true);
        doctorService.findById(appointment.doctor.id).then(async res=>{
            if(res&&res.success === true && res.object){
                setDoctor(res.object);
                if(locale.currency === 'TL'){
                    setPrice(res.object.perPriceWithTl);
                    setCurrency('TL');
                    dispatch(addPrice(res.object.perPriceWithTl))
                }else{
                    setPrice(res.object.perPriceWithEuro);
                    setCurrency('EUR');
                    dispatch(addPrice(res.object.perPriceWithEuro));
                }
                setLoading(false);
            }
        }).catch(error => {
            alert(error);
            setLoading(false);
        })
    },[])

    const renderLoading=()=>{
        return(
            <div className="loading-div">
                <ProgressSpinner style={{width: '50px', height: '50px'}} />
            </div>
        )
    }

    const getDate=()=>{
        let date = new Date(appointment.selectedDate);
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    }

    return(
        <Card title="Randevu Bilgileri" style={{width:'100%'}}>
            {loading&&renderLoading()}
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                <div>Branş : {doctor.tibbiBirim != null && doctor.tibbiBirim.birimAdi}</div>
                <div>Doktor : {doctor && doctor.unvan + ' ' + doctor.name + ' ' +doctor.surname}</div>
                <div>Randevu Ücreti :{price && price} {currency&&currency === 'TL' ? 'TL' : 'EUR' }</div>
                <div>Randevu Zamanı : {getDate()} {appointment.hour}</div>
                <div><Button label="Ödeme Adımına İlerle" icon="pi pi-arrow-circle-right" onClick={goPaymentStep}/></div>
            </div>
        </Card>
    )
}

export default AppointmentDetail;
