import React, {useEffect, useState} from 'react';
import './doctor-voting.css'
import CommentLinkServiceWithoutToken from "../../service/comment-link/comment.link.service.without.token";
import DoctorServiceWithoutToken from "../../service/doctor/doctor.service.without.token";
import {useNavigate, useParams} from "react-router-dom";
import AppointmentServiceWithoutToken from "../../service/appointment/appointment.service.without.token";
import {Rating} from "@mui/material";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import swal from 'sweetalert';
import CommentServiceWithoutToken from "../../service/comment/comment.service.without.token";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import {useSelector} from "react-redux";

const DoctorVoting = () => {

    const [showForm,setShowForm] = useState(false);
    const [doctor,setDoctor] = useState({name:'',surname:'',tibbiBirim: {birimAdi: ''}});
    const [appointment,setAppointment] = useState({appointmentTime:''});
    const [user,setUser] = useState({id:''});
    const [commentLink,setCommentLink] = useState({id:''});
    const [rate,setRate] = useState(0);
    const loginUser = useSelector(state=> state.auth.user);

    const commentLinkService = new CommentLinkServiceWithoutToken();
    const appointmentServiceWithoutToken = new AppointmentServiceWithoutToken();
    const commentServiceWithoutToken = new CommentServiceWithoutToken();

    const doctorService = new DoctorServiceWithoutToken();
    let { param } = useParams();
    let navigate = useNavigate();

    useEffect(()=>{

        commentLinkService.findByCommentLink(window.location.href).then(async res=>{
            if (res && res.success === true && res.object == null){
                navigate('/');
                return;
            }

            if (res && res.success === true){
                if (res.object.used === true){
                    swal("Hata!", "Bu link daha önceden kullanılmış.", "error");
                    return;
                }else{

                    let params =  param.split('&');
                    let appointmentId = params[0].split('=')[1];
                    let userId = params[1].split('=')[1];
                    let doctorId = params[2].split('=')[1];

                    let doctorRes =  await doctorService.findById(doctorId);
                    let appointmentRes =  await appointmentServiceWithoutToken.findById(appointmentId);

                    if (doctorRes && doctorRes.success === true && doctorRes.object){
                        setDoctor(doctorRes.object);
                    }

                    if (appointmentRes && appointmentRes.success === true && appointmentRes.object){
                        setAppointment({appointmentTime:appointmentRes.object.appoinmentTime,id:appointmentRes.object.id});
                    }

                    setUser({id:userId});
                    setCommentLink({id:res.object.id});
                    setShowForm(true);

                }
            }


        })
    },[])

    const _validationSchema = () => {
        return Yup.object().shape({
            comment: Yup.string()
                .required("Zorunlu alan")
        });
    };

    const _handleSubmit =(values,{ resetForm })=>{
        if(rate === 0){
            swal("Lütfen oy veriniz.");
            return;
        }

        commentServiceWithoutToken.createWithoutToken({
            doctor:{id : doctor.id},
            user:  {id : user.id},
            appointment:  {id : appointment.id},
            commentLink:  {id : commentLink.id},
            comment:  values.comment,
            rating : rate
        }).then(res=>{
            if (res&&res.success === true){
                swal('Geri bildiriminiz için teşekkürler',"","success");
                resetForm();
                setRate(0);
                if (loginUser.accessToken){
                    navigate('/profile');
                }else{
                    navigate('/')
                }
                setShowForm(false);
            }
        })
    }


    const formatDate=()=>{
        let date = new Date(appointment.appointmentTime);
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + (date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes()) ;
    }

    const renderForm=()=>{
        return(
            <div className="doctor-voting-container">
                <Card title="Randevu Bilgileri" className="doctor-voting-card" >
                    <div className="info-div">
                        <label>Randevu zamanı : </label> {formatDate()}  <br/>
                        <label>Doctor Adı : </label> {doctor.name + ' ' + doctor.surname} <br/>
                        <label>Branş Adı :  </label> {doctor.tibbiBirim.birimAdi} <br/>
                    </div>

                    <Formik
                        initialValues={{
                            comment: ""
                        }}
                        onSubmit={_handleSubmit}
                        validationSchema={_validationSchema}
                    >{({
                           values,
                           handleChange,
                           handleSubmit,
                           errors,
                           touched,
                           setFieldTouched
                       }) => (
                    <Form>
                        <div className="form-container">
                        <Field
                            value={values.comment}
                            onBlur={() => setFieldTouched("comment")}
                            onChange={handleChange("comment")}
                            className={
                                "form-control" +
                                (errors.comment && touched.comment ? " is-invalid" : "")
                            }
                            as="textarea"
                            placeholder="Geri bildiriminiz bizim için değerli"
                            style={{height:'12rem'}}
                        />
                        </div>
                        {errors.comment && touched.comment && (
                            <ErrorMessage
                                name="comment"
                                component="div"
                                className="invalid-feedback"
                            />
                        )}
                        <div className="rating-container">
                            <Rating
                                value={rate}
                                onChange={(event, newValue) => {
                                    setRate(newValue);
                                }}
                            />
                        </div>
                        <br/>
                        <div className="vote-button-container">
                            <Button  type="submit"
                                        onClick={handleSubmit}>GÖNDER</Button>
                        </div>
                    </Form>
                    )}
                    </Formik>
                </Card>
            </div>
        )
    }

    return(
        showForm ? renderForm() : ''
    );
}
export default DoctorVoting;
