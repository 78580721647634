import {APPOINTMENT_CONSTANT} from "../const";

export const addBranch = (branch) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.ADD_BRANCH,payload : {branch:branch}});
}

export const addDoctor = (doctor) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.ADD_DOCTOR,payload : {doctor:doctor}});
}

export const addDate = (selectedDate) => (dispatch) => {
    if (isNaN(selectedDate)){
        selectedDate = new Date();
    }
    dispatch({type : APPOINTMENT_CONSTANT.ADD_DATE,payload : {selectedDate:selectedDate}});
}

export const addHour = (hour) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.ADD_HOUR,payload : {hour:hour}});
}

export const addPrice = (price) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.ADD_PRICE,payload : {price:price}});
}

export const addExchangePrice = (exchangePrice) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.ADD_EXCHANGE_PRICE,payload : {exchangePrice:exchangePrice}});
}

export const addDoctorCalendarId = (doctorCalendarId) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.ADD_DOCTOR_CALENDAR_ID,payload : {doctorCalendarId:doctorCalendarId}});
}

export const addAppointmentId = (appointmentId) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.ADD_APPOINTMENT_ID,payload : {appointmentId:appointmentId}});
}

export const setActiveStep = (activeStep) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.SET_ACTIVE_STEP,payload : {activeStep:activeStep}});
}

export const setIsNewAppointment = (isNewAppointment) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.SET_IS_NEW_APPOINTMENT,payload : {isNewAppointment:isNewAppointment}});
}

export const setIsPaymentStep = (isPaymentStep) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.SET_IS_PAYMENT_STEP,payload : {isPaymentStep:isPaymentStep}});
}

export const setIsPaymentFail = (isPaymentFail) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.SET_IS_PAYMENT_FAIL,payload : {isPaymentFail:isPaymentFail}});
}

export const setAppointmentType = (appointmentType) => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.SET_APPOINTMENT_TYPE,payload : {appointmentType:appointmentType}});
}

export const clearState = () => (dispatch) => {
    dispatch({type : APPOINTMENT_CONSTANT.CLEAR_STATE ,payload : null});
}
