import { SnackbarProvider } from "notistack";
import { Button } from "primereact/button";
import*as React from "react";
import { ApiStatusServiceInstance } from "../../service/axios.configuration.service";
export const NotistackRef = React.createRef(null);

export const onSetRef = (ref) => {
    NotistackRef.current=ref;
}

/**
 *
 * @param {*} msg
 * @param {'default' | 'error' | 'success' | 'warning' | 'info'} option
 */
export const onShowSnackBar = (msg,options={variant:'error'}) => {
     try {
         const cvrt=msgMiddleware(msg);
         NotistackRef.current.enqueueSnackbar(cvrt,options);
     } catch (error) {
        console.log(error)

     }

}


const msgMiddleware = (msg) => {

    if(!msg){
        return null;
    }
  if(typeof msg==="object"){
    if( msg instanceof Error ){

        if(msg.status || msg.response.request.status){
            return  msg.request.responseText
        }

        return msg.message;
    }
  }



    if(React.isValidElement(msg)){
        return msg;
    }


    return JSON.stringify(msg);


}


const SnackBarProvider = ({ children }) => {

    //api hatalarını yakalama ve göstermek için
    React.useEffect(() => {
        if (ApiStatusServiceInstance) {
            try {
                ApiStatusServiceInstance.onError('error-listener', message => {
                    onShowSnackBar(<h4>{message}</h4>);
                });
            } catch (error) {
                onShowSnackBar(error);
            }

        }
    }, [ApiStatusServiceInstance])


    return <SnackbarProvider
        sx={{
            "& .SnackbarContent-root": {
                color: "white",
                bgcolor: "#0885bf"
            }
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top', }}
        style={{color:"white"}}
        ref={onSetRef}
        action={key => <Button icon="pi pi-times"  style={{color:"white"}}    onClick={() => NotistackRef.current.closeSnackbar(key)} className="p-button-rounded p-button-text "   />}
    >
        {children}
    </SnackbarProvider>

}

export default SnackBarProvider;
