import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {ErrorMessage, Field, Formik} from "formik";
import * as Yup from "yup";
import SmsCheckService from "../../service/sms-check-service/sms.check.service";
import {Card} from "primereact/card";
import {useAuth} from "../../auth/use.auth";

const SmsCodeChecker=()=>{

    let auth = useSelector(state=> state.auth);
    const {onLogin} = useAuth();

    const [validationError, setValidationError] = useState('');

    const smsCheckService = new SmsCheckService();

    const _initialValues =  () =>{
        return {
            smsCode:''
        }
    }
    const _handleSubmit = values =>{

        smsCheckService.checkSms({userId:auth.user.userId,smsCode:values.smsCode}).then(res =>{
            if (res.message && res.success === false){
                setValidationError(res.message);
                return;
            }else if(res.success === true){
                onLogin({
                    usernameOrEmail: auth.requestUser.usernameOrEmail,
                    password: auth.requestUser.password,
                });
            }
        }).catch(error=>{
            setValidationError(error.response.data.message);
        })
    }

    const _validationSchema=()=>{
        return Yup.object().shape({
            smsCode: Yup.string()
                .required('Zorunlu alan')

        })
    }

    return(
        <Card style={{margin:'5%',boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
            <Formik
                initialValues={_initialValues()}
                onSubmit={_handleSubmit}
                validationSchema={_validationSchema}
            >
                {({values,handleChange,handleSubmit,errors,touched,setFieldTouched}) => (
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'1rem'}}>
                        <div style={{padding:'1rem'}}>
                            <span>Lütfen <strong>{auth&&auth.user&&auth.user.phone}</strong> numaralı telefonunuza gönderilen 4 haneli kodu giriniz.</span>
                        </div>
                        <div style={{width:'40%'}}>
                            <Field
                                style={{margin:'1%'}}
                                value={values.smsCode}
                                onChange={handleChange('smsCode')}
                                onBlur={() => setFieldTouched('smsCode')}
                                onFocus={()=> setValidationError('')}
                                className={
                                    'form-control' +
                                    (errors.smsCode && touched.smsCode ? ' is-invalid' : '')
                                }
                                placeholder="Sms Code"
                            />
                            {(errors.smsCode && touched.smsCode) &&
                                <ErrorMessage
                                    name="smsCode"
                                    component="div"
                                    className="invalid-feedback" />
                            }
                        </div>
                        <div>
                            <button type="submit" className="Signup-btn" onClick={()=>handleSubmit()}>
                            Gönder
                            </button>
                        </div>
                        <div>
                            <span style={{color:'red',fontSize:'12px'}}>{validationError}</span>
                        </div>
                    </div>
                )}
            </Formik>
        </Card>
    )
}

export default SmsCodeChecker;
