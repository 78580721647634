import React, {useState} from "react";
import "../css/contact.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import contactimg from "../assets/img/contact1.png";
import contactimg1 from "../assets/img/contactimg1.png";
import contactimg2 from "../assets/img/contactimg2.png";
import ContactServiceWithoutToken from "../service/contact/contact.service.without.token";
import swal from "sweetalert";
import {ProgressSpinner} from "primereact/progressspinner";

const ContactPage = () => {
  const contactService = new ContactServiceWithoutToken();
  const [loading, setLoading] = useState(false);

  const _initialValues = () => {
    return {
      name: "",
      surname: "",
      email: "",
      title: "",
      detail: "",
    };
  };

  const _validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required("Zorunlu alan"),
      surname: Yup.string().required("Zorunlu alan"),
      email: Yup.string()
        .email('Geçersiz e-posta formatı')
        .required("Zorunlu Alan"),
      title: Yup.string().required("Zorunlu Alan"),
      detail: Yup.string()
        .required("Zorunlu alan")
        .min(25, "En az 25 karakter girebilirsiniz"),
    });
  };

  const _handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    contactService
      .sendEmail({
        ...values,
        nameSurname: values.name + " " + values.surname,
      })
      .then((res) => {
        if (res && res.success) {
          if (res.message) {
            resetForm();
            return swal("Başarılı!", res.message, "success");
          }
        } else {
          if (res.message) {
            return swal("Hata!", res.message, "error");
          }
          swal(
            "Hata!",
            "Bir sorun oluştu. Lütfen sonra tekrar deneyiniz.",
            "error"
          );
        }
      })
      .catch((res) => {
        swal("Hata!", "Lütfen sonra tekrar deneyiniz.", "error");
      }).finally(() => {
        setLoading(false);
    });
  };

  const renderLoading=()=>{
    return(
        <div style={{display:"flex",justifyContent:"center"}} className="container">
          <ProgressSpinner style={{width: '30px', height: '30px'}} />
        </div>
    )
  }

  return (
    <Formik
      initialValues={_initialValues()}
      validationSchema={_validationSchema}
      onSubmit={_handleSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldTouched,
      }) => (
        <section className="contactsec">
          <img className="contactimg" src={contactimg1} alt="contact" />

          <div className="contactformbase">
            <img
              className="contactborderimg img-responsive"
              style={{ backgroundColor: "white" }}
              src={contactimg}
              alt="contact"
            />
            <h3 className="contactformh3">İLETİŞİM</h3>
            {loading&&renderLoading()}
              <div className="div_contactform_namemail">
                <Field
                  style={{
                    margin: "1%",
                    borderRadius: "2.5em",
                    textAlign: "center",
                    border: "3px solid #0785c7",
                  }}
                  value={values.name}
                  onChange={handleChange("name")}
                  onBlur={() => setFieldTouched("name")}
                  className={
                    "form-control" +
                    (errors.name && touched.name ? " is-invalid" : "")
                  }
                  placeholder="Ad"
                />
                {errors.name && touched.name && (
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                )}
              </div>
              <div className="div_contactform_namemail">
                <Field
                  style={{
                    margin: "1%",
                    borderRadius: "2.5em",
                    textAlign: "center",
                    border: "3px solid #0785c7",
                  }}
                  value={values.surname}
                  onChange={handleChange("surname")}
                  onBlur={() => setFieldTouched("surname")}
                  className={
                    "form-control" +
                    (errors.surname && touched.surname ? " is-invalid" : "")
                  }
                  placeholder="Soyad"
                />
                {errors.surname && touched.surname && (
                  <ErrorMessage
                    name="surname"
                    component="div"
                    className="invalid-feedback"
                  />
                )}
              </div>
              <div className="div_contactform_namemail">
                <Field
                  style={{
                    margin: "1%",
                    borderRadius: "2.5em",
                    textAlign: "center",
                    border: "3px solid #0785c7",
                  }}
                  value={values.email}
                  onBlur={() => setFieldTouched("email")}
                  onChange={handleChange("email")}
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                  placeholder="Email Adresiniz"
                />

                {errors.email && touched.email && (
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                )}
              </div>

              <Field
                style={{
                  margin: "1%",
                  borderRadius: "2.5em",
                  textAlign: "center",
                  border: "3px solid #0785c7",
                }}
                value={values.title}
                onBlur={() => setFieldTouched("title")}
                onChange={handleChange("title")}
                className={
                  "form-control" +
                  (errors.title && touched.title ? " is-invalid" : "")
                }
                placeholder="Konu"
              />

              {errors.title && touched.title && (
                <ErrorMessage
                  name="title"
                  component="div"
                  className="invalid-feedback"
                />
              )}

              <Field
                style={{
                  margin: "1%",
                  borderRadius: "2.5em",
                  padding: "7em",
                  textAlign: "center",
                  border: "3px solid #0785c7",
                }}
                value={values.detail}
                onBlur={() => setFieldTouched("detail")}
                onChange={handleChange("detail")}
                className={
                  "form-control" +
                  (errors.detail && touched.detail ? " is-invalid" : "")
                }
                placeholder="Mesajınız"
              />

              {errors.detail && touched.detail && (
                <ErrorMessage
                  name="detail"
                  component="div"
                  className="invalid-feedback"
                />
              )}

              <button
                type="submit"
                className="contactformbutton"
                onClick={() => handleSubmit()}
              >
                GÖNDER
              </button>
          </div>

          <img className="contactimg" src={contactimg2} alt="contact" />
        </section>
      )}
    </Formik>
  );
};

export default ContactPage;
