import React, {useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DoctorProfile } from "./views/doctor/DoctorProfile";
import { UserProfile } from "./views/user/UserProfile";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import HomePagesFlex from "./pages/HomePagesFlex";
import TopBanner from "./components/TopBanner/TopBanner";
import "bootstrap/dist/css/bootstrap.css";
import "./css/main.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import FooterNew from "./components/Footer/FooterNew";
import SmsCodeChecker from "./components/sms/SmsCodeChecker";
import LoginPageNew from "./pages/LoginPageNew";
import DoctorsAndBranches from "./pages/DoctorsAndBranches";
import EmergencyDoctor from "./components/EmergencyDoctor/EmergencyDoctor";
import DoctorVoting from "./components/doctor-voting/DoctorVoting";
import FaqPage from "./pages/FaqPage";
import AuthProvider from "./auth/auth.provider";
import ProtectedRoute from "./auth/protected.route";
import PaymentOk from "./components/appointment/payment/PaymentOk";
import NotFoundPage from "./pages/404/NotFoundPage";
import ThankYouPage from "./components/jitsi/native/ThankYouPage";
import { MeetProvider } from "./components/jitsi/native/MeetContext";
import MeetPage from "./components/jitsi/native/MeetPage";
import MeetStartPage from "./components/jitsi/native/MeetStartPage";
import BlogPage from "./pages/BlogPage";
import Blog from "./components/blog/Blog";
import EmergencyDoctorSelect from "./components/EmergencyDoctor/EmergencyDoctorSelect";
import BranchSearch from "./components/search/BranchSearch";
import BePartner from "./components/be-partner/BePartner";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Contanier from "./core/Contanier/Contanier";
import { useSelector } from "react-redux";
import BlogCheckup from "./components/blog/BlogCheckup";
import PaymentForm from "./components/appointment/payment/mobile/PaymentForm";
import MeetMobilePage from "./components/jitsi/native/MeetMobilePage";
import MobilePaymentFail from "./components/appointment/payment/mobile/MobilePaymentFail";
import ApproveAggrements from "./pages/ApproveAggrements";
import {Dialog} from "primereact/dialog";
import popup from './POPUP.jpg'

export default function App() {
  let user = useSelector((state) => state.auth.user);
  const [visible,setVisible] = useState(true);

  return (
    <BrowserRouter>
      <Contanier>
        <AuthProvider>
          <TopBanner />
          <MeetProvider>
            <Routes>
              <Route  path="/" element={<HomePagesFlex />} />
              <Route path="/doctor/:doctorId" element={<DoctorProfile />} />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/smscodechecker" element={<SmsCodeChecker />} />
              <Route path="/login" element={<LoginPageNew />} />
              <Route path="/thankyoupage" element={<ThankYouPage />} />
              <Route path="/doctorandbranch" element={<DoctorsAndBranches />} />
              <Route path="/comment/:param" element={<DoctorVoting />} />
              <Route path="/sss" element={<FaqPage />} />
              <Route path="/payment-ok" element={<PaymentOk />} />
              <Route path="/mobile-payment-fail" element={<MobilePaymentFail />} />
              <Route path="/mobile-payment/:param" element={<PaymentForm />} />
              <Route path="*" element={<NotFoundPage />} />

              <Route path="/mobile-meet/meet/:param" element={<MeetMobilePage />} />
              <Route path="/meet-prepare" element={<MeetStartPage />} />
              <Route path="/meet" element={<MeetPage />} />
              <Route path="blog" element={<BlogPage />} />
              <Route path="blog/:id" element={<Blog />} />
              <Route path="blog/2" element={<BlogCheckup />} />
              <Route
                path="emergencydoctor"
                element={<EmergencyDoctorSelect />}
              />
              <Route path="/bepartner" element={<BePartner />} />
              <Route path="search" element={<BranchSearch />} />
              <Route path="forgotpassword" element={<ForgotPassword />} />

              <Route
                path="/changepassword"
                element={
                  <ProtectedRoute>
                    <ChangePassword />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/approveaggrements"
                element={
                  <ProtectedRoute>
                    <ApproveAggrements />
                  </ProtectedRoute>
                }
              />



            </Routes>
          </MeetProvider>
          <FooterNew />
          {user && user.userRoles && user.userRoles[0] && user.userRoles[0].name === "ROLE_DOCTOR" ? (
            ""
          ) : (
            <EmergencyDoctor />
          )}
        </AuthProvider>
      </Contanier>
      <>
        <Dialog
            visible={visible}
            onHide={()=> setVisible(false)}
            style={{ width: '50vw',minHeight: '15vw' }}
        >
          <img src={popup} width="100%"  alt=""/>
        </Dialog>
      </>
    </BrowserRouter>
  );
}
