import React from "react";
import "./advantage-flex.css";
import advantage from "../../assets/img/advantage.png";
import doctoradvantage from "../../assets/img/doctoradvantage.png";
import {useNavigate} from "react-router-dom";

export default function AdvantageFlex() {

    const navigate = useNavigate();

    return (
        <div className="advantage-container">
            <div className="advantage-title">
                <h1>
                    Üye Olmanın <br/> Avantajları
                </h1>
            </div>
            <div className="advantage-content">
                <div className="advantage-image-area">
                    <div>
                        <img src={advantage} alt="advantage" className="advantage-img img-fluid"/>
                    </div>
                    <div className="advantage-box">
                        <img src={doctoradvantage} alt="doctoradvantage" className="img-fluid"/>
                    </div>
                </div>
                <div className="advantage-text-area">
                      <div className="text-box3">
                        <h1 className="text-header">Kolay ve rahat</h1>
                        En basit şekilde daha iyi hasta bakımı istediğiniz zaman ve yerde, geleneksel bakım konumlarının ötesinde hastalarla bağlantı kurun.
                        <br/>
                        <div className="line">
                            ------------------------------------------
                        </div>
                        <h1 className="text-header">Hasta Çeşitliliği</h1>
                        Ücretsiz kapasilerinizi sunmak, yeni GKV ve PKV hastaları edinmek veya mevcut hastaları tedavi etmek isteyip istemediğini bakılmaksızın arttırırsınız.
                    </div>
                </div>
            </div>
            <div className="advantage-button-area">
                <button onClick={() => navigate('/bepartner')} className="button-1">DOKTOR SAĞLIK DANIŞMANI OL</button>
            </div>
        </div>
    );
}
