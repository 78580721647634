import React, {useEffect, useState} from "react";
import "./signup-form-flex.css";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import AuthService from "../../service/rest/auth.service";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import {useNavigate} from "react-router-dom";
import {AUTH_CONSTANT} from "../../redux/const";
import {useDispatch} from "react-redux";
import {onCloseDialog, onShowDialog} from "../../core/Dialog/DialogProvider";
import {Button} from "primereact/button";
import swal from 'sweetalert';
import {setPhone, setRequestUser} from "../../redux/actions/auth.action";

const SignUpForm = (props) => {

  const authService = new AuthService();

  const [phone, setPhoneNumber] = useState(null);
  const [kvkkAggrementChecked, setKvkkAggrementChecked] = useState(false);
  const [privacyAggrementChecked, setPrivacyAggrementChecked] = useState(false);
  const [validationError, setValidationError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _initialValues =  () =>{
    return {
      name:'',
      surname:'',
      email:'',
      password:'',
      passwordConfirm:'',
    }
  }

  const _handleSubmit = values =>{

    if(!kvkkAggrementChecked || !privacyAggrementChecked){
      swal("Uyarı","Lütfen sözleşmeleri onaylayın","warning");
      return;
    }

    if (phone === undefined || phone === null || phone === '' || !isValidPhoneNumber(phone)){
       setValidationError('Lütfen telefon numaranızı kontrol ediniz.');
       return;
    }

    dispatch(setRequestUser({usernameOrEmail:values.email,password:values.password}));
    dispatch(setPhone(phone));

    authService.signUp({...values,phone:phone, kvkkAggrementApproved:kvkkAggrementChecked,privacyAggrementApproved:privacyAggrementChecked}).then(res =>{
      if (res.message && res.success === false){
        return setValidationError(res.object);
      }else if(res.success === true){
        if (res.object.smsCheck === false){
          dispatch({type : AUTH_CONSTANT.SIGN_IN,payload : {userId:res.object.id}});
          navigate('/smscodechecker');
        }
      }
    }).catch(error=>{
      setValidationError(error.response.data.message);
    })
  }

  const phoneOnChange=(val)=>{
    setPhoneNumber(val);
    setValidationError('');
  }

  const _validationSchema=()=>{
    return Yup.object().shape({
      name: Yup.string()
          .required('Zorunlu alan'),
      surname: Yup.string()
          .required('Zorunlu alan'),
      email: Yup.string()
          .required('Zorunlu alan')
          .email('Geçersiz e-posta formatı'),
      password: Yup.string()
          .min(6,'En az 6 karakter girebilirsiniz')
          .required("Zorunlu alan"),
      passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password')],'Parolalar eşleşmiyor')
          .required("Zorunlu alan")

    })
  }

  const renderAggrementKVKK=()=>{

    const view=<div style={{display:"flex",flexDirection:'column',height:'100%'}}>
      <div style={{display:"flex",height:'90%'}}>
        <iframe src="./documents/KVKK Sözleşmesi.pdf" height="100%" width="100%"/>
      </div>
      <div style={{display:"flex",height:'9%',justifyContent:"center",marginTop:'1%'}}>
        <Button label="Okudum, onaylıyorum" onClick={()=> {
          setKvkkAggrementChecked(true);
          onCloseDialog();
        }} className="p-button-outlined p-button-primary" />
      </div>
    </div>

    onShowDialog(view,{width:'80%',height:'100%'});

  }

  const renderAggrementPricacy=()=>{

    const view=<div style={{display:"flex",flexDirection:'column',height:'100%'}}>
      <div style={{display:"flex",height:'90%'}}>
        <iframe src="./documents/Gizlilik Sözleşmesi.pdf" height="100%" width="100%"/>
      </div>
      <div style={{display:"flex",height:'9%',justifyContent:"center",marginTop:'1%'}}>
        <Button label="Okudum, onaylıyorum" onClick={()=> {
          setPrivacyAggrementChecked(true);
          onCloseDialog();
        }} className="p-button-outlined p-button-primary" />
      </div>
    </div>

    onShowDialog(view,{width:'80%',height:'100%'});

  }

  return (
      <>
      <Formik
          initialValues={_initialValues()}
          onSubmit={_handleSubmit}
          validationSchema={_validationSchema}
      >
        {({values,handleChange,handleSubmit,errors,touched,setFieldTouched}) => (
            <Form className="Signup-Form">
              <div className="SignupForm-content">
                <h3 className="SignupForm-title">Yeni Hesap Oluşturun</h3>
                <div className="form-group">
                  <Field
                      style={{margin:'1%'}}
                      value={values.name}
                      onChange={handleChange('name')}
                      onBlur={() => setFieldTouched('name')}
                      className={
                          'form-control' +
                          (errors.name && touched.name ? ' is-invalid' : '')
                      }
                      placeholder="Ad"
                  />
                  {(errors.name && touched.name) &&
                      <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback" />
                  }
                </div>
                <div className="form-group">
                  <Field
                      style={{margin:'1%'}}
                      value={values.surname}
                      onChange={handleChange('surname')}
                      onBlur={() => setFieldTouched('surname')}
                      className={
                          'form-control' +
                          (errors.surname && touched.surname ? ' is-invalid' : '')
                      }
                      placeholder="Soyad"
                  />
                  {(errors.surname && touched.surname) &&
                      <ErrorMessage
                          name="surname"
                          component="div"
                          className="invalid-feedback" />
                  }
                </div>
                <div className="form-group">
                  <Field
                      style={{margin:'1%'}}
                      type="email"
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={() => setFieldTouched('email')}
                      className={
                          'form-control' +
                          (errors.email && touched.email ? ' is-invalid' : '')
                      }
                      placeholder="E-posta Adresi"
                  />
                  {(errors.email && touched.email) &&
                      <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback" />
                  }
                </div>
                <div>
                      <PhoneInput
                          className="form-control form-control-not-block"
                          placeholder="Telefon"
                          value={phone}
                          onChange={phoneOnChange}
                      />
                </div>

                <div className="form-group">
                  <Field
                      style={{margin:'1%'}}
                      value={values.password}
                      onChange={handleChange('password')}
                      onBlur={() => setFieldTouched('password')}
                      type="password"
                      className={
                          'form-control' +
                          (errors.password && touched.password ? ' is-invalid' : '')
                      }
                      placeholder="Şifre"
                  />
                  {(errors.password && touched.password) &&
                      <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback" />
                  }
                </div>
                <div className="Form-group">
                  <Field
                      style={{margin:'1%'}}
                      value={values.passwordConfirm}
                      onChange={handleChange('passwordConfirm')}
                      onBlur={() => setFieldTouched('passwordConfirm')}
                      type="password"
                      className={
                          'form-control' +
                          (errors.passwordConfirm && touched.passwordConfirm ? ' is-invalid' : '')
                      }
                      placeholder="Şifre Tekrar"
                  />
                  {(errors.passwordConfirm && touched.passwordConfirm) &&
                      <ErrorMessage
                          name="passwordConfirm"
                          component="div"
                          className="invalid-feedback" />
                  }
                </div>
                <div className="field-checkbox">
                  <input checked={kvkkAggrementChecked} type="checkbox" onChange={e => setKvkkAggrementChecked(e.target.checked)} />
                  <label htmlFor="binary"><a className="aggrement-a" onClick={renderAggrementKVKK}>KVKK sözleşmesi</a><span className="info-p">'ni okudum, kabul ediyorum. (*)</span></label>
                </div>
                <div className="field-checkbox">
                  <input checked={privacyAggrementChecked} type="checkbox" onChange={e => setPrivacyAggrementChecked(e.target.checked)} />
                  <label htmlFor="binary"><a className="aggrement-a" onClick={renderAggrementPricacy}>Gizlilik sözleşmesi </a> <span className="info-p">'ni okudum, kabul ediyorum. (*)</span></label>
                </div>
                <div className="signup-button-div">
                  <span style={{color:'red',fontSize:'12px'}}>{validationError}</span>
                </div>
                <div className="signup-button-div">
                  <button type="submit" className="Signup-btn">
                    Üye Ol
                  </button>
                </div>

                <p className="signup-forgot-password">
                  Bir hesabınız varsa
                  <span
                      onClick={() => {
                        props.onChangeForm();
                      }}
                  >
                    <b>Giriş Yap</b>
                  </span>
                </p>
              </div>
            </Form>)}
      </Formik>
      </>
  );
};

export default SignUpForm;
