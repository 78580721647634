import BaseService from "../base/base.service";

export default class CommentServiceWithoutToken extends BaseService {

    constructor() {
        super();
        this.endpoint = '/web/comment';
    }

    getCommentListById = (doctorId) => {
        return this.httpClient.get(this.endpoint + "/getCommentPublishedList" , { params : { doctorId: doctorId }}).then(res => {
            return res.data;
        })
    }

}
