import BaseService from "../base/base.service";

export default class ParameterService extends BaseService {

    constructor(token) {
        super(token);
        this.endpoint = '/parameter';
    }

    findAllParametersByParameterType = (parameterType) => {
        return this.httpClient.get(this.endpoint + "/findAllParametersByParameterType/" + parameterType, this.headers).then(res => {
            return res.data;
        })
    }

}
