import React from "react";
import "../payment_approved/paymentApproved.css";
import paymentimg from "../../assets/img/paymentapproved.png"

export const PaymentApproved=() =>{
    return(

        <div className="payment-container">
                <div className="payment-status">
                    <div className="payment-img">
                        <img src={paymentimg} />
                    </div>
                    <div className="payment-text">
                        <b>    Ödemeniz <br/> Başarıyla Alınmıştır.</b>
                    </div>
                    <div  className="payment-text1" >
                      DOCTORDEDİ’yi Tercih Ettiğiniz <br/>İçin Teşekkür Ederiz. Sağlıklı Günler Dileriz.
                    </div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <a href={'/profile'} >Profile Git</a>
                    </div>
                </div>
        </div>

        );

}

