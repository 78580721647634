import BaseService from "../base/base.service";

export default class SmsCheckService extends BaseService {

    constructor() {
        super();
        this.endpoint = '/web/sms';
    }

    checkSms = (data) => {
        return this.httpClient.post(this.endpoint + "/check", data).then(res => {
            return res.data;
        })
    }

}
