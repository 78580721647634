import React from "react";
import { Link } from "react-router-dom";
import "./Blogcard.css";

const Blogcard = (
  props = {
    title: null,
    id: 0,
    description: null,
    subtitle: null,
    date: null,
    user: { name: "Admin", surname: null, branch: null, icon: null },
  }
) => {
  const linkStyle = {
    textDecoration: "none",
  };

  const user = props.user
    ? props.user
    : { name: "Admin", surname: "", branch: null, icon: null };

  return (
    <Link style={linkStyle} to={"/blog/" + props.id}>
      <div className="w-100">
        <div className="card__footer  ">
          <span className="tag tag-blue">Sağlık</span>
          <div className="user">
            <img
              src="https://cdn-icons-png.flaticon.com/512/921/921130.png"
              alt="user__image"
              className="user__image"
              width={32}
              height={32}
            />
            <div className="user__info">
              <h5>{user.name + " " + user.surname}</h5>
            </div>
          </div>
        </div>
        <div className="main-sperator" style={{ height: "0.5px" }}></div>
        <div className="card__header"></div>
        <div className="card__body">
          <h4>{props.title}</h4>
          <h5>{props.subtitle}</h5>
          <p>{props.description}</p>
        </div>
      </div>
    </Link>
  );
};

export default Blogcard;
