import BaseService from "../base/base.service";

export default class PaymentService extends BaseService {

    constructor(token) {
        super(token);
        this.endpoint = '/payment';
    }

    updatePaymentStatus = (appointmentId) => {
        return this.httpClient.put(this.endpoint + "/updatePaymentStatus/" + appointmentId, null ,this.headers).then(res => {
            return res.data;
        })
    }

    getByOrderId = (orderId) => {
        return this.httpClient.get(this.endpoint + "/getByOrderId", {params : {orderId:orderId},headers:null}).then(res => {
            return res.data;
        })
    }

    forwardAskida = (orderId) => {
        return this.httpClient.get(this.endpoint + "/forwardAskida", {params : {orderId:orderId},headers:null}).then(res => {
            return res.data;
        })
    }

}
