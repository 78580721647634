import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AuthContext} from "./auth.context";
import {useDispatch} from "react-redux";
import {logout, setRequestUser, signIn} from "../redux/actions/auth.action";
import {clearState} from "../redux/actions/appointment.actions";
import { onShowSnackBar } from "../core/SnackBar/SnackBarProvider";

const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const handleLogin =   (data) => {
         dispatch(setRequestUser(data));
        signIn(data,navigate,location,dispatch);
    };

    const handleLogout = () => {
        dispatch(logout());
        dispatch(clearState());
        clearInterval(window.mInterval);
        onShowSnackBar("Çıkış Yapıldı!",{variant:"info"});
    };

    const _setLoading = (val) => {
        setLoading(val);
    };

    const value = {
        onLogin: handleLogin,
        onLogout: handleLogout,
        setLoading: _setLoading,
        getLoading: loading,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
