import BaseService from "../base/base.service";

export default class CommentLinkServiceWithoutToken extends BaseService {

    constructor() {
        super();
        this.endpoint = '/web/commentlink';
    }

    findByCommentLink = (link) => {
        return this.httpClient.get(this.endpoint + "/findByLink", {params : {link:link},headers:null}).then(res => {
            return res.data;
        })
    }

    findByAppointmentId = (appointmentId) => {
        return this.httpClient.get(this.endpoint + "/findByAppointmentId", {params : {appointmentId:appointmentId},headers:null}).then(res => {
            return res.data;
        })
    }

}
