import React, { useState } from "react";
import LoginFormFlex from "../components/Forms/LoginFormFlex";
import SignUpFormFlex from "../components/Forms/SignUpFormFlex";
import "../css/loginNew.css";
import logo from "../assets/img/doctordedilogo1.png";

const LoginPageNew = () => {
  const [visibleForm, setVisibleForm] = useState(false);

  const onChangeForm = () => {
    setVisibleForm(!visibleForm);
  };
  return (
    <section className="login-page-section">
      <div className="container">
        <div
          className="login-img"
          style={{ backgroundImage: "url(assets/img/calendar.png)" }}
        ></div>
        <div
          className="login-img1"
          style={{ backgroundImage: "url(assets/img/doctor-woman.png)" }}
        ></div>
        <div
          className="login-img2"
          style={{
            backgroundImage: "url(assets/img/login-bg.png)",
            float: "left",
          }}
        ></div>
        <div className="login-logo">
          <img src={logo} alt={'logo'}/>
        </div>
        <div className="row">
          <div className="login_form">
            {!visibleForm && <LoginFormFlex onChangeForm={onChangeForm} />}
            {visibleForm && <SignUpFormFlex onChangeForm={onChangeForm} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPageNew;
