import {
    Navigate,
    useLocation
} from 'react-router-dom';
import React from 'react';
import {useSelector} from "react-redux";

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    let user = useSelector(state => state.auth.user);

    if (user&& user.userRoles && user.userRoles.length>0 && user.userRoles[0].name === 'ROLE_DOCTOR') {
        if (!location.pathname.includes('/changepassword')) {
            if (user && !user.passwordChanged) {
                return <Navigate to="/changepassword" replace state={{from: location}}/>;
            }
        }
        if (!location.pathname.includes('/approveaggrements')) {
            if (user && (!user.kvkkAggrementApproved || !user.privacyAggrementApproved) && user.passwordChanged){
                return <Navigate to="/approveaggrements" replace state={{from: location}}/>;
            }
        }
    }

    if (!user.accessToken) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return children;
};

export default ProtectedRoute;
