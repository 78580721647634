import React, {useEffect, useState} from "react";
import "../Forms/LoginForm.css";
import {useDispatch, useSelector} from "react-redux";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import {useAuth} from "../../auth/use.auth";
import {AUTH_CONSTANT} from "../../redux/const";
import {useNavigate} from "react-router-dom";

const LoginForm = (props) => {
  const auth = useSelector((state) => state.auth);
  const {onLogin} = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginUser,setLoginUser] = useState({username:'',password:''});

  const myStateRef = React.useRef(loginUser);
  const setMyState = data => {
      myStateRef.current = data;
      setLoginUser(data);
  };

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                if (myStateRef.current.username && myStateRef.current.password){
                    _handleSubmit({usernameOrEmail:myStateRef.current.username,password:myStateRef.current.password})
                }
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    useEffect(()=>{
        dispatch({type : AUTH_CONSTANT.CLEAR_ERR});
    },[])

  const _handleSubmit = (values) => {
      dispatch({type : AUTH_CONSTANT.CLEAR_ERR});

      onLogin({
          usernameOrEmail: values.usernameOrEmail,
          password: values.password,
      });
  };

  const _validationSchema =   Yup.object( {
      usernameOrEmail: Yup.string()
        .required("Zorunlu alan")
        .email("Geçersiz e-posta formatı"),
      password: Yup.string().required("Zorunlu alan"),
    });

    const _handleChangeUsername=(e)=>{
        setMyState({...loginUser,username: e.target.value })
    }

    const _handleChangePassword=(e)=>{
        setMyState({...loginUser,password: e.target.value })
    }

  return (
    <Formik
      initialValues={{
        usernameOrEmail: "",
        password: "",
      }}
      onSubmit={_handleSubmit}
      validationSchema={_validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldTouched,
        setFieldValue
      }) => (
        <div style={{zIndex:2}} className="Form">
          <div
            className="Form-content"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h3 className="Form-title"> Hesabınıza Giriş Yapın</h3>
            <div className="form-group">
              <Field
                style={{ margin: "1%" }}
                value={values.usernameOrEmail}
                onChange={(e) => {
                    _handleChangeUsername(e);
                    setFieldValue('usernameOrEmail',e.target.value);
                }}
                onBlur={() => setFieldTouched("usernameOrEmail")}
                className={
                  "form-control" +
                  (errors.usernameOrEmail && touched.usernameOrEmail
                    ? " is-invalid"
                    : "")
                }
                placeholder="E-posta Adresi "
              />
              {errors.usernameOrEmail && touched.usernameOrEmail && (
                <ErrorMessage
                  name="usernameOrEmail"
                  component="div"
                  className="invalid-feedback"
                />
              )}
            </div>
            <div className="form-group">
              <Field
                style={{ margin: "1%" }}
                value={values.password}
                onChange={(e) => {
                    _handleChangePassword(e);
                    setFieldValue('password',e.target.value);
                }}
                type="password"
                onBlur={() => setFieldTouched("password")}
                className={
                  "form-control" +
                  (errors.password && touched.password ? " is-invalid" : "")
                }
                placeholder="Şifre"
              />
              {errors.password && touched.password && (
                <ErrorMessage
                  name="password"
                  component="div"
                  className="invalid-feedback"
                />
              )}
            </div>
            <div className="login-button-div">
                <span style={{color:"red"}}>{auth.err}</span>
              <button
                type="submit"
                className="login-btn"
                onClick={() => handleSubmit()}
              >
                  {auth.loginLoading &&<i style={{marginLeft:'-12px',marginRight:'8px'}} className="fa fa-solid fa-spinner fa-spin"></i>}
                  Giriş Yap
              </button>
              <p className="Forgot-password " onClick={()=> navigate('/forgotpassword')}>
                <span>Şifrenizi mi unuttunuz?</span>
              </p>
              <p className="Forgot-password">
                Bir hesabınız yok mu ?
                <span
                  onClick={() => {
                    props.onChangeForm();
                  }}
                >
                  <b>Kaydol!</b>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default LoginForm;
