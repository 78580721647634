import { AUTH_CONSTANT } from "../const";

const INITIAL_STATE={
    user:{
        userId : '',
        doctorId:'',
        accessToken : '',
        status : false,
        userRoles : [],
        passwordChanged: false,
        kvkkAggrementApproved:false,
        privacyAggrementApproved:false,
        phone:''
    },
    err:null,
    loginLoading: false,
    locale: {currency:'',ip:'',lang:''},
    requestUser:{usernameOrEmail:'',password:''},
    isMobilePayment:false
}

export const auth = (state = INITIAL_STATE,action) => {
    switch (action.type) {
        case  AUTH_CONSTANT.SIGN_IN :
            return {...state,user: action.payload}
        case  AUTH_CONSTANT.LOGIN_ERR :
            return {...state,err: action.payload.err}
        case  AUTH_CONSTANT.CLEAR_ERR :
            return {...state,err: null}
        case  AUTH_CONSTANT.SET_LOGIN_LOADING :
            return {...state,loginLoading: action.payload.loginLoading}
        case  AUTH_CONSTANT.SET_LOCALE :
            return {...state,locale: action.payload.locale}
        case  AUTH_CONSTANT.SET_PASSWORD_CHANGED :
            return {...state,user: {...state.user,passwordChanged: action.payload.passwordChanged}}
        case  AUTH_CONSTANT.SET_REQUEST_USER :
            return {...state,requestUser: action.payload.requestUser}
        case  AUTH_CONSTANT.SET_IS_MOBILE_PAYMENT :
            return {...state,isMobilePayment: action.payload.isMobilePayment}
        case  AUTH_CONSTANT.SET_PHONE :
            return {...state, user : {...state.user,phone: action.payload.phone}}
        case  AUTH_CONSTANT.SET_KVKK_AGGREMENT:
            return {...state,user:{...state.user,kvkkAggrementApproved: action.payload.kvkkAggrementApproved}}
        case  AUTH_CONSTANT.SET_PRIVACY_AGGREMENT:
            return {...state,user:{...state.user,privacyAggrementApproved: action.payload.privacyAggrementApproved}}
        case  AUTH_CONSTANT.LOG_OUT :
            return INITIAL_STATE;
        default :
            return state;
    }
}
