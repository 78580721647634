import React from "react";
import {Link, useLocation} from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import {
  AiOutlinePhone,
  AiOutlineMail,
  AiFillYoutube,
  AiOutlineTwitter,
  AiOutlineInstagram,
} from "react-icons/ai";
import "../Footer/FooterNew.css";

import gplaylogo from "../../assets/img/gplaylogo.png";
import appstorelogo from "../../assets/img/appstorelogo.png";
import footerlogo from "../../assets/img/footer-logo.png";
import ssl from "../../assets/img/SSL.png";
import alısveris from "../../assets/img/guvenli-alisveris.png";
import iso1 from "../../assets/img/27001.png"
import iso2 from "../../assets/img/9001-2015.png"
import iso3 from "../../assets/img/15504.png"

const FooterNew = () => {
  const location = useLocation();

  const isMobile = location.pathname.includes('mobile-payment')||location.pathname.includes('mobile-meet');

  return (
      !isMobile ? <footer className="main-footer">
      <div className="container footerContainer">
        <div className="row">
          <div className="footer-column col-lg-3 offset-lg-1 col-md-4">
            <div className="footer-widget">
              <div className="logo">
                <Link to="/">
                  <img src={footerlogo} alt="footer-logo" />
                </Link>
              </div>
              <p className="text">
                Yeni Nesil Sağlık Danışmanınız “Doctor Dedi”
              </p>
              <p className="text">
                Türkiye’nin en yenilikçi platformu olan doctordedi.com, her
                branştan hekimi ve yardımcı sağlık personelini bünyesinde
                barındıran yeni nesil bir danışmanlık platformudur.
              </p>
            </div>
          </div>
          <div className="footer-column col-lg-3 offset-lg-1 col-md-4">
            <div className="footer-widget link-widget">
              <h2>Sayfalar & Hizmetler</h2>
              <ul className="footer-list">
                <li>
                  <a href="/about">Neden Doctor Dedi?</a>
                </li>
                <li>
                  <a href="/doctorandbranch">Branslar & Doktorlar</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <a href="/sss">SSS</a>
                </li>
                <li>
                  <a href="/contact">İletişim</a>
                </li>
                <li>
                  <a href="/bepartner">Danışman Ol</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-column col-lg-3 col-md-4">
            <div className="footer-widget times-widget">
              <h2>İletişim Bilgileri</h2>
              <ul className="list-style-two">
                <li>
                  <Link>
                    <span className="icon">
                      <CiLocationOn />
                    </span>
                    Balcalı mah. Güney Kampüs/5 sk. Teknokent Blok no: 4/1 İç
                    Kapı no: 7 <br />
                    Sarıçam / Adana
                  </Link>
                </li>
                <li>
                  <Link>
                    <span className="icon">
                      <AiOutlinePhone />
                    </span>
                    +90 533 404 86 01
                  </Link>
                </li>
                <li>
                  <Link>
                    <span className="icon">
                      <AiOutlineMail />
                    </span>
                    info@doctordedi.com
                  </Link>
                </li>
              </ul>
              <ul className="social-box mt-3">
                <li className="icon">
                  <a
                    href="https://www.youtube.com/@doctordedi3951"
                    target="_blank"
                  >
                    <AiFillYoutube />
                  </a>
                </li>
                <li className="icon">
                  <a href="https://twitter.com/Doctordedi_" target="_blank">
                    <AiOutlineTwitter />
                  </a>
                </li>
                <li className="icon">
                  <a
                    href="https://www.instagram.com/doctordedi.official/"
                    target="_blank"
                  >
                    <AiOutlineInstagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-200custom">
        <div className="text-center">
          <ul className="app-box">
            <li>
              <img src={gplaylogo} alt="gplay" />
            </li>
            <li>
              <img src={appstorelogo} alt="appstore" />
            </li>
            <li>
              <img src={ssl} alt="ssl" width={200} height={200} />
            </li>
            <li>
              <img src={alısveris} alt="alısveris" width={200} height={200} />
            </li>
          </ul>
        </div>
      </div>
      <div className="container">
        <div className="text-center">
          <ul className="app-box">
            <li>
              <img src={iso1} alt="iso1-logo" width={200} height={200}/>
            </li>
            <li>
              <img src={iso2} alt="iso2-logo"  width={200} height={200}/>
            </li>
            <li>
              <img src={iso3} alt="iso2-logo"  width={200} height={200}/>
            </li>
          </ul>
        </div>
      </div>
    </footer> : null
  );
};

export default FooterNew;
