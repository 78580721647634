import {auth} from "./auth.reducer";
import {appointment} from "./appointment.reducer";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import { applyMiddleware, legacy_createStore as createStore, combineReducers } from 'redux'
import thunk from 'redux-thunk'

const rootReducer = combineReducers({
    auth,
    appointment
})

const persistConfig = {
    key: 'root',
    storage,
}

const composedEnhancers = composeWithDevTools(applyMiddleware(thunk))

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    persistedReducer,
    composedEnhancers
)

const  persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export {store, persistor}
