import BaseService from "../base/base.service";

export default class DoctorServiceWithoutToken extends BaseService {

    constructor() {
        super({});
        this.endpoint = '/web/doctor';
    }

    getDoctorListByBranchId = (branchId) => {
        return this.httpClient.get(this.endpoint + "/getDoctorListByBranchId", {params : {branchId:branchId},headers:null}).then(res => {
            return res.data;
        })
    }

    getDoctorById = (id) => {
        return this.httpClient.get(this.endpoint + "/getByDoctorId", { params: { id }, headers : this.headersWithParams});
    }

    getDoctorGalleryById = (id) => {
        return this.httpClient.get(this.endpoint + "/galeryList", { params: { doctorId:id }, headers : this.headersWithParams});
    }

    getDoctorCalendarList = (data) => {
        return this.httpClient.post(this.endpoint + "/doctorcalendardaylist", data,this.headers).then(res => {
            return res.data;
        })
    }

    emergencyDoctorList = () => {
        return this.httpClient.get(this.endpoint + "/emergencyDoctorList", null).then(res => {
            return res.data;
        })
    }

    applyToBePartner = (cv,data) => {
        let formData = new FormData();
        formData.append('cv', cv);
        formData.append('doctorRequest', data);

        return this.httpClient.post(this.endpoint + "/saveForSignUp", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        }).then(res => {
            return res.data;
        })
    }

    dynamicSearch = (search) => {
        return this.httpClient.get(this.endpoint + "/dynamicSearch", { params: { search:search }, headers : this.headersWithParams}).then(res => {
            return res.data;
        });
    }

}
