import React, { memo, useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
const def={
    view:null,
    contentClassName:null,
    closable:true,
    closeOnEscape:false,
    draggable:true,
    maximized:false,
    header:null,
    width:"50vw",
    height:"50vh"

}

export const dialog=React.createRef(null);

export const onShowDialog = (view,opt={
    contentClassName:null,
    closable:true,
    closeOnEscape:false,
    draggable:true,
    maximized:false,
    header:null,
    onHide:null,
    width:"50vw",
    height:"50vh"}) => {

   dialog.current&&dialog.current.setDialog({...def,view:view,...opt});

}

export const onCloseDialog = () => {
    dialog.current.setDialog({...def,visible:false})
}

const onSetRef=(prop)=>{

    dialog.current=prop;
}

const DialogProvider = () => {


    const [dialogview, setDialogview] = useState(def)



         useEffect(() => {
             if(!dialog.current){
                onSetRef({setDialog:setDialogview,dialog:dialogview});
             }
         }, [dialog.current])

  return (
    <Dialog
      onHide={()=>setDialogview(def)}
      style={{width:dialogview.width,height:dialogview.height}}
      maximizable
      modal
      keepInViewport
      position='top'
      closable={dialogview.closable}
      visible={dialogview&&dialogview.view&&React.isValidElement(dialogview.view)?true:false}
      >
      {dialogview.view}
    </Dialog>
  )
}

export default memo(DialogProvider);
