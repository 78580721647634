export const UI_SESSION_TIME = 6*600000;//60000 ms= 1dk

export default class SessionTimer{
    static sessionTimer () {
        let now = new Date();
        localStorage.setItem("sessionTimeOut",now);
        startTimer();
    }
}


function startTimer(){
    window.mInterval = setInterval(function () {
        let sessionTimeOut =   localStorage.getItem("sessionTimeOut");
        let dateDiff = new Date() - new Date(sessionTimeOut);
        if (dateDiff >= UI_SESSION_TIME) {
            window.location.href='/login';
            localStorage.clear();
            clearInterval(window.mInterval);
        }
    }, 5000);
}
