import React, {useEffect, useState} from 'react';
import ConsultServiceWithoutToken from "../../service/consult/consult.service.without.token";
import {useNavigate, useSearchParams} from "react-router-dom";
import './branch.search.css';
import {ProgressSpinner} from "primereact/progressspinner";

function BranchSearch(){
    const [branchList,setBranchList] = useState([]);
    const [loading,setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams("UTF-8");
    const [searchResult, setSearchResult] = useState();
    const consultService = new ConsultServiceWithoutToken();
    const navigate = useNavigate();

    useEffect(()=>{
        setLoading(true);
        consultService.find(searchParams.get('word')).then(res=>{
            if(res&&res.success){
                setBranchList(res.object);
            }else if(res.message){
                setSearchResult(res.message);
            }
            setLoading(false);
        })
    },[])

    const goDoctorsAndBrancesPage=(branch)=>{
        let selectedBranch = {
            name : branch.birimAdi,
            value : branch.id,
            disable : false
        }
        navigate('/doctorandbranch',{state:{isHomePageRoute:true,branch:selectedBranch}});
    }

    const renderLoading = () => {
        return <ProgressSpinner style={{ width: "30px", height: "30px" }} />;
    };

    return(
        <>
            <div style={{display:'flex',justifyContent:"center"}}>
                <span style={{fontSize:'3rem'}} className="text-center faq-title" >
                            Arama Sonuçları
                </span>
            </div>
            <div className="branchsearch-container">
                {loading&&renderLoading()}
                <h2 style={{color:"white"}}>{searchResult&&searchResult}</h2>
                <div className="branchsearch-box-area">
                    {branchList.length > 0 && branchList.map(branch => {
                        return (
                            <div className="branchsearch-slider-box">
                                <div className="detail">
                                    <p>
                                        <span style={{fontWeight:'bold',fontSize:'18px'}}>{branch.branch.birimAdi}</span><br/>
                                        {branch.branch.aciklama}
                                    </p>
                                </div>
                                <div>
                                    <span className="select-button" onClick={()=>goDoctorsAndBrancesPage(branch.branch)}> Seç </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default BranchSearch;
