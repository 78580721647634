import React from "react";
import "./flow.css";
import bg8 from "../../assets/img/bg8.png";

const Flow = () => {
  return (
    <div className="flow-container">
        <img src={bg8} alt="bg8" className="img-fluid" />
    </div>
  );
};

export default Flow;
