import { JitsiMeeting } from "@jitsi/react-sdk";
import React, {useEffect, useCallback, useContext} from "react";
import { useState } from "react";
import { useRef } from "react";
import {useNavigate} from "react-router-dom";
import { onShowDialog } from "../../../core/Dialog/DialogProvider";
import {MeetContext} from "./MeetContext";

const MeetPage = () => {
    const domain = "meeting.doctordedi.com";
    const navigate = useNavigate();
    const [name, setName,rmId, setRoomId] = useContext(MeetContext);

    const meet = useRef(null)
     const [meetapi, setMeetapi] = useState(null)
     const [loading, setLoading] = useState(false);

    // ALL OUR HANDLERS
    const handleClose = () => {
        console.log("handleClose");
    };

    const handleParticipantLeft = async (participant) => {
        console.log("handleParticipantLeft", participant);
        await getParticipants();
    };

    const handleParticipantJoined = async (participant) => {
        console.log("handleParticipantJoined", participant);
        await getParticipants();
    };

    const handleVideoConferenceJoined = async (participant) => {
        console.log("handleVideoConferenceJoined", participant);
        await getParticipants();
    };

    const handleVideoConferenceLeft = () => {
        console.log("handleVideoConferenceLeft");
        navigate("/thankyoupage");
    };

    // GETTING ALL PARTICIPANTS
    const getParticipants = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(meetapi.getParticipantsInfo());
            }, 0);
        });
    };


    useEffect(() => {
     if(meetapi){
        meetapi.addEventListeners({
            readyToClose: handleClose,
            participantLeft: handleParticipantLeft,
            participantJoined: handleParticipantJoined,
            videoConferenceJoined: handleVideoConferenceJoined,
            videoConferenceLeft: handleVideoConferenceLeft,
         });
     }

      return () => {
        setMeetapi(null);
      };
    }, [meetapi])


    //    useEffect(() => {
    //     viewMeet&&onShowDialog(viewMeet,{height:"100vh",width:"100vw",contentClassName:"overflow-hidden 100vw 100vh "})
    //      return () => {
    //         onShowDialog(null,{height:"100vh",width:"100vw"})
    //      };
    //    }, [viewMeet])
    const spiner=!meetapi &&<div className="w-100 d-flex mt-5 align-items-center flex-column justify-content-center">
                <h3 className="m-3 main-blue-color"  >Görüşme Başlatılıyor...</h3>
                <span className="pi pi-spin overflow-hidden pi-spinner " style={{ 'fontSize': '5em',color:"#1a98d5" }}></span>
         </div>

    return (
        <React.Fragment>
            {spiner}
            <JitsiMeeting
                domain={domain}
                roomName={rmId}
                configOverwrite={{
                    prejoinPageEnabled: false,
                    enableWelcomePage: false,
                    startWithAudioMuted: true,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false
                }}
                interfaceConfigOverwrite={{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
                }}
                userInfo={{
                    displayName: name
                }}

                onApiReady={(externalApi) => {
                    console.log("hazır");
                    setMeetapi(externalApi);


                    // buradan Jitsi Meet Harici API'sine özel olay dinleyicileri ekleyebilirsiniz
                    // komutları yürütmek için yerel olarak da saklayabilirsiniz
                }}
                getIFrameRef={(iframeRef) => {
                    iframeRef.className = "vh-100 vw-100 position-absolute overflow-hidden   bottom-0 top-0 left-0 right-0  ";
                    iframeRef.style.zIndex = 1500;
                    meet.current = iframeRef;
                }}
            />
        </React.Fragment>
    );
};

export default MeetPage;
