import React from 'react';
import {Card} from "primereact/card";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import {Button} from "primereact/button";
import AuthService from "../service/rest/auth.service";
import {useDispatch, useSelector} from "react-redux";
import {logout, setPasswordChanged} from "../redux/actions/auth.action";
import {useNavigate} from "react-router-dom";

function  ChangePassword(){

    const user = useSelector(state=> state.auth.user);

    const authService = new AuthService(user.accessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const _initialValues =  () =>{
        return {
            password1:'',
            password2:'',
            password2Confirm:''
        }
    }


    const _validationSchema=()=>{
        return Yup.object().shape({
            password1: Yup.string()
                .min(6,'En az 6 karakter girebilirsiniz')
                .required("Zorunlu alan"),
            password2: Yup.string()
                .min(6,'En az 6 karakter girebilirsiniz')
                .required("Zorunlu alan"),
            password2Confirm: Yup.string()
                .oneOf([Yup.ref('password2')],'Parolalar eşleşmiyor')
                .required("Zorunlu alan")

        })
    }

    const _handleSubmit = (values, { resetForm }) => {
        authService.changePassword({
            ...values,
            userId : user.userId
        }).then(res=>{
            if (res&&res.success){
                swal("Başarılı!", res.object, "success");
                dispatch(setPasswordChanged(true));
                navigate('/profile');
            }else{
                swal("Hata!", res.object, "error");
            }
        })
    }

    return(
        <div style={{display:"flex",justifyContent:'center'}}>
            <Card style={{ width: '35rem', marginBottom: '2em',marginTop:'2em',padding:'1em' }}>
                <Formik
                    initialValues={_initialValues()}
                    validationSchema={_validationSchema}
                    onSubmit={_handleSubmit}
                >
                    {({
                          values,
                          handleChange,
                          handleSubmit,
                          errors,
                          touched,
                          setFieldTouched,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <Field
                                    style={{margin:'1%'}}
                                    value={values.password1}
                                    onChange={handleChange('password1')}
                                    onBlur={() => setFieldTouched('password1')}
                                    type="password"
                                    className={
                                        'form-control' +
                                        (errors.password1 && touched.password1 ? ' is-invalid' : '')
                                    }
                                    placeholder="Mevcut Şifre"
                                />
                                {(errors.password1 && touched.password1) &&
                                    <ErrorMessage
                                        name="password1"
                                        component="div"
                                        className="invalid-feedback" />
                                }
                            </div>
                            <div className="Form-group">
                                <Field
                                    style={{margin:'1%'}}
                                    value={values.password2}
                                    onChange={handleChange('password2')}
                                    onBlur={() => setFieldTouched('password2')}
                                    type="password"
                                    className={
                                        'form-control' +
                                        (errors.password2 && touched.password2 ? ' is-invalid' : '')
                                    }
                                    placeholder="Yeni Şifre"
                                />
                                {(errors.password2 && touched.password2) &&
                                    <ErrorMessage
                                        name="password2"
                                        component="div"
                                        className="invalid-feedback" />
                                }
                            </div>
                            <div className="Form-group">
                                <Field
                                    style={{margin:'1%'}}
                                    value={values.password2Confirm}
                                    onChange={handleChange('password2Confirm')}
                                    onBlur={() => setFieldTouched('password2Confirm')}
                                    type="password"
                                    className={
                                        'form-control' +
                                        (errors.password2Confirm && touched.password2Confirm ? ' is-invalid' : '')
                                    }
                                    placeholder="Yeni Şifre Tekrar"
                                />
                                {(errors.password2Confirm && touched.password2Confirm) &&
                                    <ErrorMessage
                                        name="password2Confirm"
                                        component="div"
                                        className="invalid-feedback" />
                                }
                            </div>
                            <div style={{display:"flex",marginTop:'1rem',justifyContent:"center"}}>
                                <Button label="Şifre Değiştir"
                                        className="p-button-info p-button-sm" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card>
        </div>
    )
}

export default ChangePassword;
