import React, {useState} from "react";
import "../css/faq.css"

const FaqPage = () => {
    const [faqs,setFaqs]= useState([
        {
            id:1,
            question:"DoctorDedi Nedir?",
            answer:"Türkiye'nin en yenilikçi platformu olan doctordedi.com, her branştan hekimi ve yardımcı sağlık personelini bünyesinde barındıran yeni nesil bir danışmanlık platformudur"
        },
        {
            id:2,
            question: "Nasıl randevu alabilirim ?",
            answer: "Web sitemiz veya mobil uygulamalarımızdan kayıt olarak kolayca randevu oluşturabilirsiniz."
        },
        {
            id:3,
            question:"Ödeme yöntemi güvenli mi ?",
            answer:"Sitemiz SSL sertifakası ile korunmaktadır ve tüm ödemeleriniz sanal POS sistemi ile yapılmaktadır. "
        },
        {
            id:4,
            question:"Randevumu iptal edebilir miyim ? ",
            answer:"Evet iptal edebilirsiniz. Ancak randevuya 2 saatten az kaldığı durumlarda randevunuz iptal edilememektedir."
        }
    ])
    return(
        <section className="main-content">
            <div className="container d-flex flex-column justify-content-center align-items-center ">
                <h2 className="text-center d-flex align-items-center text-uppercase mb-5 faq-title" style={{fontSize:"3rem"}} >
                    Sıkça Sorulan Sorular
                </h2>
                <br />
                <div className="row flex-center">
                <br />
                        <div className="accordion  " style={{width:"75vw"}} id="accordionExample">
                        {faqs.map((faqs , id)=> {
                            return<div key={id} className="accordion-item">
                                <h2 className="accordion-header 0" id="headingOne">
                                    <button
                                        className="accordion-button  "
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={"#collapseOne"+id}
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        <div className="circle-icon">
                                            <i className="fa fa-question" />
                                        </div>
                                        <span className="question-title" id={faqs.id}>
                                            {faqs.question}
                                        </span> 
                                    </button>
                                </h2>
                                <div
                                    id={"collapseOne"+id}
                                    className="accordion-collapse collapse "
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body ">
                                        <p className="w-100" id={faqs.id}>{faqs.answer}</p>
                                    </div>
                                </div>
                            </div>
                        })}
                        </div>
                </div>
            </div>
        </section>

    );
}
export default FaqPage;
