import React from 'react';
import './not.found.css'
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";

const NotFoundPage =()=>{

    const navigate = useNavigate();

    const goHomePage=()=>{
        navigate('/');
    }

    return(
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <h1 style={{fontSize: '7.5em',margin: '15px 0px', fontWeight:'bold', color :'#0885bf'}} >404</h1>
            <h2 style={{fontWeight:'bold',color :'#0885bf'}}>Sayfa bulunamadı.</h2>
            <p style={{color :'#0885bf'}}>Ana sayfaya dönmek için tıklayınız.</p>
            <Button style={{backgroundColor:'#0885bf',borderColor:'#0885bf'}} onClick={goHomePage}>Ana Sayfa</Button>
        </div>
    )
}

export default NotFoundPage;
