import React from "react";
import "../blog/blog.css";
import cvd from "../../assets/img/cvd.png";
import { useLocation } from "react-router-dom";

const Blog = () => {


 const location= useLocation();
 
  return (
    <section>
      <h1 className="blog-title">BLOG</h1>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-content shadow">
              <div className="pages-content-header">
                <h2>Covid-19</h2>
                <img className="content-img img-fluid" src={cvd} alt="cvd" />
                <p style={{ paddingTop: "25px" }}>
                  Yeni Koronavirüs Hastalığı (COVID-19), ilk olarak Çin’in Vuhan
                  Eyaleti’nde Aralık ayının sonlarında solunum yolu belirtileri
                  (ateş, öksürük, nefes darlığı) gelişen bir grup hastada
                  yapılan araştırmalar sonucunda 13 Ocak 2020’de tanımlanan bir
                  virüstür. Salgın başlangıçta bu bölgedeki deniz ürünleri ve
                  hayvan pazarında bulunanlarda tespit edilmiştir. Daha sonra
                  insandan insana bulaşarak Vuhan başta olmak üzere Hubei
                  eyaletindeki diğer şehirlere ve Çin Halk Cumhuriyeti’nin diğer
                  eyaletlerine ve diğer dünya ülkelerine yayılmıştır.
                  <br />
                  <br />
                  Koronavirüsler, hayvanlarda veya insanlarda hastalığa neden
                  olabilecek büyük bir virüs ailesidir. İnsanlarda, birkaç
                  koronavirüsün soğuk algınlığından Orta Doğu Solunum Sendromu
                  (MERS) ve Şiddetli Akut Solunum Sendromu (SARS) gibi daha
                  şiddetli hastalıklara kadar solunum yolu enfeksiyonlarına
                  neden olduğu bilinmektedir. Yeni Koronavirüs Hastalığına
                  SAR-CoV-2 virüsü neden olur.
                </p>
                <br />
                <br />
                <p>
                  <b>Nasıl Yayılır ?</b>
                </p>
                <p>
                  Virüs, enfekte olan kişinin öksürmesi, hapşırması, konuşması,
                  şarkı söylemesi veya nefes alması sırasında ağzından veya
                  burnundan çıkan küçük sıvı partikülleri yoluyla yayılabilir.
                  Bu partiküllerin boyutu, büyük respiratuar damlacıklar ile
                  küçük aerosoller arasında değişiklik gösterir.
                  <br />
                  <br />
                  COVID-19 ile enfekte birinin yakınındayken virüsü solur veya
                  virüsün bulaştığı bir yüzeye dokunduktan sonra gözlerinize,
                  burnunuza veya ağzınıza dokunursanız enfekte olabilirsiniz.
                  Virüs, kapalı alanlarda ve kalabalık ortamlarda daha kolay
                  yayılır.
                </p>
                <br />
                <br />
                <p>
                  <b>Nasıl Korunuz ?</b>
                </p>
                <p>
                  Akut solunum yolu enfeksiyonlarının bulaşma riskini azaltmaya
                  yönelik öneriler, Yeni Koronavirüs enfeksiyonu için de
                  geçerlidir. Öksürme veya hapşırma sırasında ağız ve burun tek
                  kullanımlık mendille kapatılmalı, mendil yoksa dirseğin iç
                  kısmı kullanılmalıdır. Olabildiğince kalabalık ortamlardan
                  uzak durulmalıdır. Tokalaşma ve sarılmadan kaçınılmalıdır.
                  Kirli ellerle ağız, burun ve gözlere dokunulmamalıdır. El
                  hijyenine önem verilmelidir. Eller en az 20 saniye boyunca
                  sabun ve suyla yıkanmalı, sabun ve suyun olmadığı durumlarda
                  alkol içerikli el antiseptiği kullanılmalıdır. Sınıflar ve iş
                  yerleri başta olmak üzere kapalı alanlar sık sık
                  havalandırılmalıdır. Bağışıklık sistemini güçlendirmek için
                  dengeli ve sağlıklı beslenilmelidir. Gıdalar tüketilmeden önce
                  iyice yıkanmalıdır.
                  <br />
                  <br />
                  Son 14 gün içerisinde enfeksiyon görülen ülkelerin birinden
                  geldiyseniz cerrahi maske takarak en yakın sağlık kuruluşuna
                  başvurun. Eğer öksürüyorsanız, ateşiniz varsa ve nefes almakta
                  zorlanıyorsanız, cerrahi maske takarak en yakın sağlık
                  kuruluşuna başvurun. Evde izolasyon önerilen bir kişiyle aynı
                  odada bulunduğunuz anlarda maskenizi mutlaka takın
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
