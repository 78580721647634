import React from "react";
import "../css/about.css";
import aboutsec1img from "../assets/img/about1.png";
import aboutsec2img from "../assets/img/about2.png";
import aboutsec3img from "../assets/img/about3.png";
import aboutsec4img from "../assets/img/about4.png";

const AboutPage = () => {
  return (
    <>
      <section className="aboutpage">
        <section className="aboutsec1">
          <div className="aboutsec1info">
            <h3 className="about-title-1">Yeni Nesil Sağlık Danışmanınız</h3>
            <h2 className="about-title-2">"Doctor Dedi"</h2>
            <p>
              Türkiye'nin en yenilikçi platformu olan doctordedi.com, her
              branştan hekimi ve yardımcı sağlık personelini bünyesinde
              barındıran yeni nesil bir danışmanlık platformudur.
            </p>
            <p>
              Amacımız; dünyanın dört bir yanından sağlık hizmeti almak isteyen
              kullanıcılara online olarak danışmanlık hizmeti sunmaktır. Bu
              sayede sağlık turizmi desteklenirken dünyanın dört bir yanındaki
              hekimler ve yardımcı sağlık personeli, tek bir çatı altında
              toplanacaktır.
            </p>
            <p>
              Londra merkezli olan Doctor Dedi, başta Birleşik Krallık olmak
              üzere, Türkiye, Almanya, Fransa, Belçika, Hollanda ve Arap
              ülkelerinde (BAE, Katar, Bahreyn, Kuveyt) hizmet verecektir.
            </p>
          </div>
          <img id="aboutsec1img" src={aboutsec1img} alt="img" />
        </section>

        <section className="aboutsec2">
          <img id="aboutsec2img" src={aboutsec2img} alt="img" />

          <div id="aboutsec2info"> 
            <h2 className="about-title-3">Neden "Doctor Dedi"</h2>
            <p className="about-title-4">Çünkü;</p>

            <ul>
              <li className="aboutsec2li">
                Dünyanın dört bir yanında hizmet veren uzman doktorları ve
                sağlık personellerini tek bir çatı altında toplar.{" "}
              </li>
              <li className="aboutsec2li">
                Hastaların dilediği uzmandan, dilediği an, dilediği yerde
                danışmanlık almasına olanak tanır.{" "}
              </li>
              <li className="aboutsec2li">7/24 kesintisiz hizmet sunar. </li>
              <li className="aboutsec2li">
                Üstün altyapısı sayesinde veri takibini kolaylaştırır;
                hasta-doktor ilişkisinin yönetilmesini sağlar.{" "}
              </li>
              <li className="aboutsec2li">
                Sağlık turizmini online bir şekilde destekler.{" "}
              </li>
              <li className="aboutsec2li">
                Doktor ve sağlık personellerinin kendini tanıtmasına, aynı
                zamanda dünyanın dört bir yanına ulaşmasına yardımcı olur.{" "}
              </li>
              <li className="aboutsec2li">
                Daha sağlıklı bir yaşam için hastaneye gitmeye gerek kalmadan
                çözüm bulunabilmesine olanak tanır.{" "}
              </li>
              <li className="aboutsec2li">
                Uzun yolculuklara ve yorucu kuyruklara engel olur; hastanın
                ihtiyacı olan en doğru uzmana kolay bir şekilde ulaşmasını
                sağlar.
              </li>
            </ul>
          </div>
        </section>

        <section className="aboutsec3">
          <div id="aboutsec3info">
            <h2 className="about-title-5">Misyon</h2>

            <p>
              Yurt dışında yaşayan Türklerin Türk hekimlerine, Türk hekimlerin
              de yabancı uyruklu hastalara ulaşabilmesi amacıyla tasarlanan
              doctordedi.com, aslında sağlık turizmini harekete geçirme misyonu
              ile hareket etmektedir.
            </p>
            <p>
              Sisteme kayıtlı olan hekimlerin ve sağlık personellerinin, hiçbir
              aracıya ihtiyaç duymadan, rekabet kurallarına uygun şekilde
              kendini tanıtmasına imkan sağlayan doctordedi.com, hem hastaların
              doğru hekimlere ulaşabilmesini hem de hekimlerin dünyaya
              açılabilmesini destekler.
            </p>
          </div>

          <img id="aboutsec3img" src={aboutsec3img} alt="aboutsec3img" />
        </section>

        <section className="aboutsec4">
          <div id="aboutsec4info">
            <h2 className="about-title-6">Vizyon</h2>

            <p>
              Dünya çapında, alanında uzman hekimleri tek bir çatı altında
              toplama vizyonuyla hareket eden doctordedi.com, sağlık açısından
              danışılması gereken herhangi bir durum meydana geldiğinde, akla
              gelen ilk platform olmayı hedeflemektedir.
            </p>
            <p>
              Türk hekimler kadar yabancı uyruklu hekimlerle de iş birliği kurma
              vizyonuyla hareket eder; uluslararası bir sağlık platformu olmak
              için çalışır.
            </p>
          </div>

          <img id="aboutsec4img" src={aboutsec4img} alt="aboutsec4img" />
        </section>
      </section>
    </>
  );
};

export default AboutPage;
