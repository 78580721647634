import React, {useEffect} from "react";
import Slider from "../components/Slider/Slider";
import Flow from "../components/flow/Flow";
import MostPreferBranch from "../components/most-prefer-branch/MostPreferBranch";
import UserCommentFlex from "../components/UserComment/UserCommentFlex";
import MediaFlex from "../components/Media/MediaFlex";
import AdvantageFlex from "../components/advantage/AdvantageFlex";
import MainHeaderFlex from "../components/MainHeader/MainHeaderFlex";
import {useDispatch} from "react-redux";
import {AUTH_CONSTANT} from "../redux/const";

const HomePages = () => {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch({type : AUTH_CONSTANT.CLEAR_ERR});
    },[])

  return (
    <>
      <MainHeaderFlex />
      <Flow/>
      <Slider />
      <MostPreferBranch/>
        {true == false ? <UserCommentFlex /> : ''}
        <MediaFlex />
      <AdvantageFlex />
    </>
  );
};

export default HomePages;
