import BaseService from "../base/base.service";

export default class ContactServiceWithoutToken extends BaseService {
  constructor() {
    super();
    this.endpoint = "/web/contact";
  }

  sendEmail = (data) => {
    return this.httpClient
      .post(this.endpoint + "/sendEmail",data, null)
      .then((res) => {
        return res.data;
      });
  };

}
