import React, {useState, useEffect, useContext} from 'react'
import {Button} from 'react-bootstrap';
import { Button as PrimeButton} from 'primereact/button';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {addHours, format} from 'date-fns'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
  addAppointmentId, addExchangePrice, addHour, addPrice,
  clearState,
  setActiveStep as storeActiveStep,
  setActiveStep,
  setIsPaymentFail, setIsPaymentStep
} from "../../redux/actions/appointment.actions";
import Appointment from "../../components/appointment/Appointment";
import AppointmentService from "../../service/appointment/appointment.service";
import UserProfileService from "../../service/user-profile/user.profile.service";
import DoctorService from "../../service/doctor/doctor.service";
import CommentLinkServiceWithoutToken from "../../service/comment-link/comment.link.service.without.token";
import "./userProfile.css";
import {MeetContext} from "../../components/jitsi/native/MeetContext";
import swal from "sweetalert";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import SessionTimer from "../../util/session-timer";
import {APPOINTMENT_TYPE} from "../../redux/const";
import MultiSelectComponent from "../../components/select/MultiSelectComponent";
import {Divider} from "primereact/divider";
import GalleriaTabView from "./GalleriaTabView";
import {onShowDialog} from "../../core/Dialog/DialogProvider";
import {
  validateFacebookUrl,
  validateInstagramUrl,
  validateTwitterUrl,
  validateYouTubeUrlChannel
} from "../../util/util";
import SeansEdit from "./SeansEdit";
import { addMinutes } from 'date-fns';
import {Tooltip} from "primereact/tooltip";

export const UserProfile = () => {
  let user = useSelector(state => state.auth.user);

  const appointmentService = new AppointmentService(user.accessToken);
  const userProfileService = new UserProfileService(user.accessToken);
  const doctorService = new DoctorService(user.accessToken);
  const commentLinkService = new CommentLinkServiceWithoutToken();
  const appointment = useSelector(state => state.appointment);
  const [phone, setPhone] = useState(null);
  const [languages, setLanguages] = useState([]);

  const [selectedTab, setSelectedTab] = useState('active-appointments');
  const [userRole, setUserRole] = useState('user');
  const [selectedPhoto, setSelectedPhoto] = useState({});
  const [selectedPdf, setSelectedPdf] = useState({});
  const [cancelReason, setCancelReason] = useState('');
  const [cancelModalData, setCancelModalData] = useState({
    isVisible: false,
    appointment: null,
  });

  const [showAppointmentDialog,setShowAppointmentDialog] = useState(false);
  const [showGalleriEditDialog,setShowGalleriEditDialog] = useState(false);
  const [showSeansEditDialog,setShowSeansEditDialog] = useState(false);
  const [editModalData, setEditModalData] = useState({});

  const [renderingAppointments, setRenderringAppointments] = useState([]);
  const [activeAppointments, setActiveAppointments] = useState([]);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [userData, setUserData] = useState({});

  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [isPastLoading, setIsPastLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [cvLoading, setCvLoading] = useState(false);

  const [showPaymentFailMessage, setShowPaymentFailMessage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams("UTF-8");
  const [name, setName,rmId, setRoomId] = useContext(MeetContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const location = useLocation();

  useEffect(()=>{
    if(searchParams.get('message') && appointment.isPaymentStep){
      dispatch(setIsPaymentFail(true));
      setShowPaymentFailMessage(true);
    }
  },[searchParams])


  useEffect(()=>{
    decideActiveStep();
    SessionTimer.sessionTimer();
  },[])

  useEffect(()=>{
    if(appointment.isPaymentFail){
      decideActiveStep();
    }
  },[appointment.isPaymentFail])

  useEffect(()=>{
    if(appointment.activeStep){
      setShowAppointmentDialog(true);
    }
  },[appointment.activeStep])

  useEffect(() => {
    if (location.state) {
      if(location.state&&location.state.isGalleriaEdit) {
        setShowGalleriEditDialog(location.state.isGalleriaEdit);
      }

      if(location.state&&location.state.isSeansEdit) {
        setShowSeansEditDialog(location.state.isSeansEdit);
      }

      if(location.state&&location.state.isEdit) {
        setIsEditMode(location.state.isEdit);
      }
    }
  }, [location]);

  useEffect(() => {
    if (user.userRoles[0] && user.userRoles[0].name === 'ROLE_DOCTOR') {
      setUserRole('doctor');
      getDoctorUserData();
    } else {
      getUserProfileData();
    }

  }, [user]);

  useEffect(() => {
    if (selectedTab === 'active-appointments') {
      if (user.userRoles[0] && user.userRoles[0].name === 'ROLE_DOCTOR') {
        getActiveAppointmentsByDoctor();
      } else {
        getActiveAppointments();
      }
    } else {
      if (user.userRoles[0] && user.userRoles[0].name === 'ROLE_DOCTOR') {
        getPastAppointmentsByDoctor();
      } else {
        getPastAppointments();
      }
    }
  }, [selectedTab]);

  const decideActiveStep=()=>{

    if (appointment && appointment.isPaymentStep && appointment.isPaymentFail){
      dispatch(setActiveStep(4));
      setShowAppointmentDialog(true);
    }else{
      if (appointment.appointmentType === APPOINTMENT_TYPE.EMERGENCY){
          if (appointment.isPaymentStep){
            dispatch(setActiveStep(4));
          }else{
            dispatch(setActiveStep(3));
          }
          setShowAppointmentDialog(true);
      }else{
        if (appointment && appointment.branch.id && appointment.doctor.id && appointment.isNewAppointment){ //doctor profilinden geldiyse
          dispatch(setActiveStep(2));
          setShowAppointmentDialog(true);
        }
      }
    }

  }

  const renderFailMessage=()=>{
    if (appointment && appointment.isPaymentStep && appointment.isPaymentFail){
      return (
          <Dialog
              visible={showPaymentFailMessage}
              style={{ width: '50vw',minHeight: '15vw' }}
              onHide={() => setShowPaymentFailMessage(false)}
          >
            <div style={{display:"flex",flexDirection:"column"}}>
              <div style={{display:"flex",flex:1}}>
                <div style={{display:"flex",width:"20%",alignItems:"center",justifyContent:"center"}}>
                  <i className="fa-solid fa-circle-exclamation" style={{fontSize:"100px",color:"red"}}></i>
                </div>
                <div style={{display:"flex",width:"80%"}}>
                  <div style={{display:"flex",flexDirection:"column",flex:1}}>
                    <div style={{borderBottom:'1px solid black',width:'100%'}}>
                      <h3 style={{color:"black"}}>Ödeme başarısız</h3>
                    </div>
                    <p>
                      {searchParams.get('message')}
                    </p>
                  </div>
                </div>
              </div>
              <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <p>Lütfen tekrar deneyiniz</p>
              </div>
            </div>
          </Dialog>
      )
    }
  }

  const getUserProfileData = () => {
    setIsUserLoading(true);

    userProfileService.getUserProfileById(user.userId)
      .then((data) => {
        if (data.success) {
          setUserData({ userType: 'user', ...data.object});
          setEditModalData({ ...data.object });
          setPhone(data&&data.object&&data.object.phoneNumber ? data.object.phoneNumber : '')
        }
      })
      .finally(() => {
        setIsUserLoading(false);
      });
  };

  const getDoctorUserData = () => {
    setIsUserLoading(true);

    doctorService.getDoctorByUserId(user.userId)
      .then(({data}) => {
        if (data.success) {
          setUserData({ userType: 'doctor', ...data.object,languages:JSON.parse(data.object.languages)});
          setEditModalData({ ...data.object });
          if(data.object.languages){
            setLanguages(JSON.parse(data.object.languages));
          }
          setPhone(data.object.phone)
        }
      })
      .finally(() => {
        setIsUserLoading(false);
      });
  };

  const getActiveAppointmentsByDoctor = () => {
    setIsActiveLoading(true);

    appointmentService.fetchActiveAppointmentsByDoctor(user.userId)
      .then(({ data }) => {
        if (data.success) {
          setActiveAppointments(data.object);
          setRenderringAppointments(data.object);
        }
      })
      .finally(() => {
        setIsActiveLoading(false);
      });
  };

  const getPastAppointmentsByDoctor = () => {
    setIsPastLoading(true);

    appointmentService.fetchPastAppointmentsByDoctor(user.userId)
      .then(({ data }) => {
        if (data.success) {
          setPastAppointments(data.object);
          setRenderringAppointments(data.object);
        }
      })
      .finally(() => {
        setIsPastLoading(false);
      });
  };

  const getActiveAppointments = () => {
    setIsActiveLoading(true);

    appointmentService.fetchActiveAppointmentsByUser(user.userId)
      .then(({ data }) => {
        if (data.success) {
          setActiveAppointments(data.object);
          setRenderringAppointments(data.object);
        }
      })
      .finally(() => {
        setIsActiveLoading(false);
      });
  };

  const getPastAppointments = () => {
    setIsPastLoading(true);

    appointmentService.fetchPastAppointmentsByUser(user.userId)
      .then(({ data }) => {
        if (data.success) {
          setPastAppointments(data.object);
          setRenderringAppointments(data.object);
        }
      })
      .finally(() => {
        setIsPastLoading(false);
      });
  };

  const appointmentCard = (appointment) => {
    return (
      <>
        <div className='col-md-12'>
          <div className='row pe-2 fw-bold justify-content-end'>
            Randevu Zamanı : { format(new Date(appointment.appointmentTime), 'dd/MM/yyyy HH.mm') }
          </div>

          <div className='row align-items-center'>
            <div className='col-md-2'>
              <img
                src={ appointment.doctorInfoDto.photoUrl || "https://cdn-icons-png.flaticon.com/512/149/149071.png" }
                alt="profile"
                className="profile-photo"
              />
            </div>

            <div className='col-md-10 px-2'>
              <div className='row doctor-name-label'>
                  { doctorFullName(appointment.doctorInfoDto) }
              </div>

              <div className='row doctor-contact-info-label'>
                { appointment.doctorInfoDto.branch }
              </div>

              <div className='row'>
                { getPaymentStatus(appointment.paymentDataDto.paymentStatus) }
              </div>
            </div>

            <div className='row justify-content-end align-items-center mt-2'>
              {
                selectedTab === 'active-appointments'
                ? activeAppointmentCardActions(appointment)
                : pastAppointmentCardActions(appointment)
              }
            </div>
          </div>
          {showPaymentFailMessage && renderFailMessage()}
        </div>
      </>
    )
  };

  const getPaymentStatus=(status)=>{
    switch (status){
      case 'ODEME_ALINDI' : return 'Ödeme Alındı';
      case 'ASKIDA' : return 'Askıda';
      case 'IADE' : return 'İade';
      case 'ODEME_BEKLIYOR' : return 'Ödeme Bekliyor';
      default : return 'Ödeme durumu bilinmiyor';
    }
  }
  const phoneOnChange=(val)=>{
    setPhone(val);
  }
  const doctorAppointmentCard = (appointment) => {
    return (
      <>
        <div className='col-md-12'>
          <div className='row pe-2 fw-bold justify-content-end'>
            Randevu Zamanı : { format(new Date(appointment.appointmentTime), 'dd/MM/yyyy HH.SS') }
          </div>

          <div className='row align-items-center'>
            <div className='col-md-2'>
              <img
                src={ appointment.userDto.photoUrl || "https://cdn-icons-png.flaticon.com/512/149/149071.png" }
                alt="profile"
                className="profile-photo"
              />
            </div>

            <div className='col-md-10 px-2'>
              <div className='row doctor-name-label'>
                  { `${appointment.userDto.name} ${appointment.userDto.surname}` }
              </div>

              <div className='row doctor-contact-info-label'>
                { appointment.userDto.phone }
              </div>

              <div className='row'>
                { getPaymentStatus(appointment.paymentDataDto.paymentStatus) }
              </div>
            </div>

            <div className='row justify-content-end mt-2'>
              {
                selectedTab === 'active-appointments'
                ? activeAppointmentCardActions(appointment)
                : pastAppointmentCardActions(appointment)
              }
            </div>
          </div>
          {showPaymentFailMessage && renderFailMessage()}
        </div>
      </>
    )
  };

  const doctorFullName = (doctorInfo) => {
    return `${doctorInfo.unvan} ${doctorInfo.name} ${doctorInfo.surname}`;
  };

  const joinAppointment=(appointmentId)=>{
    appointmentService.findById(appointmentId).then(res=>{
      if(res && res.success && res.object){
        let jitsiUrl = res.object.videoChatAppoinment.videoUUID;
        setRoomId(jitsiUrl);
        navigate('/meet-prepare');
      }
    })
  }

  const activeAppointmentCardActions = (appointment) => {
    const currentAppointmentTime = new Date(appointment.appointmentTime);
    const fifteenMinutesAgo = addMinutes(currentAppointmentTime,-15);

    let isAppointmentJoinable = false;
    let isAppointmentStart = false;

    let diff = new Date().getTimezoneOffset();

    let offsetTr = 180 + (diff);

    let date = new Date();
    const currentTime = addHours(date,offsetTr/60);

    if (fifteenMinutesAgo <= currentTime){
      isAppointmentJoinable = true;
    }

    if (new Date() > currentAppointmentTime){
      isAppointmentStart = true;
    }

    return (
      <>
        <div className='col-md-auto me-2'>
          {!isAppointmentStart&&appointment.paymentDataDto.paymentStatus === 'ODEME_ALINDI' &&
            <Button
                variant="outline-danger"
                className='d-btn'
                onClick={() => openCancelModal(appointment)}
            >
              İptal Et
            </Button>
          }
        </div>

        <div className='col-md-auto me-2'>
          {appointment.paymentDataDto.paymentStatus === 'ODEME_BEKLIYOR' &&
              <Button
                  variant="outline-success"
                  className='d-btn'
                  onClick={() => completePayment(appointment.id)}
              >
                Ödemeyi Tamamla
              </Button>
          }
        </div>

        <div className='col-md-auto me-2'>
            <Button
                disabled={appointment.paymentDataDto.paymentStatus !== 'ODEME_ALINDI' || ( appointment.paymentDataDto.paymentStatus === 'ODEME_ALINDI' && !isAppointmentJoinable)}
                variant="outline-primary"
                className='d-btn'
                onClick={()=>joinAppointment(appointment.id)}
            >
              Randevuya Katıl
            </Button>
          <Tooltip target=".customClassName" mouseTrack mouseTrackLeft={10} />
          {!isAppointmentJoinable && <span className="customClassName"   data-pr-tooltip="Randevu başlamasına 15 dk'dan az bir süre  kala  aktif olacaktır." data-pr-position="right"> <i className="pi pi-info-circle" style={{ fontSize: '1rem', color: 'var(--orange-300)' }}> </i> </span>}
        </div>
      </>
    )
  };

  const pastAppointmentCardActions = (appointment) => {
    return (
        <>
          <div className='col-md-auto'>
            {userRole === 'user' && !appointment.canceled && appointment.completed && appointment.paymentStatusText !== 'IADE' && <Button
                variant='outline-primary'
                className='d-btn me-2'
                onClick={() => pointSession(appointment.id)}
            >
              Seansı Puanla
            </Button>}

            <Button
                variant={!appointment.canceled && appointment.completed ? 'success' : 'danger'}
                className='d-btn'
            >
              { appointment.canceled && appointment.completed ? 'İptal Edildi' : !appointment.canceled && appointment.completed ? 'Tamamlandı' : 'Tamamlanmadı' }
            </Button>
          </div>
        </>
    )
  };

  const pointSession = (appointmentId) => {
    commentLinkService.findByAppointmentId(appointmentId).then(res=>{
      if(res && res.success && res.object){
        let commentLink = res.object.link;
        const url = new URL(commentLink);

        navigate(url.pathname);
      }
    })
  }



  const openCancelModal = (appointment) => {
    setCancelModalData({
      appointment,
      isVisible: true,
    });
  };

  const completePayment = async (id) => {
    let resAppointment =  await appointmentService.findById(id);

    if(resAppointment && resAppointment.success === true){
      dispatch(storeActiveStep(4));
      dispatch(setIsPaymentStep(true));
      dispatch(addAppointmentId(resAppointment.object.appointmentId));
      dispatch(addPrice(resAppointment.object.paymentDataDto.price));
      dispatch(addExchangePrice(resAppointment.object.paymentDataDto.exchangePrice));

    }else if(resAppointment && resAppointment.success === false){{
      if(resAppointment.message){
        swal("Hata",resAppointment.message,"error");
      }
    }
    }
  };

  const closeCancelModal = () => {
    setCancelModalData({ isVisible: false, appointment: null });
  };

  const cancelAppointment = (id) => {

    if (cancelReason === ''){
      swal("Uyarı","Lütfen iptal nedenini belirtiniz.","warning");
      return;
    }

    if (cancelReason.length < 25){
      swal("Uyarı","En az 25 karakter giriniz.","warning");
      return;
    }

    appointmentService.cancel({appointmentId:id,cancelReason}).then(res=>{
      if (res && res.success === false && res.message){
        swal("Hata",res.message,"error");
      }else if (res && res.success){
        swal("Başarılı","Randevu iptal talebiniz alınmıştır. Gerekli kontrollerin ardından iptal işleminiz gerçekleştirilecektir.","success");
        getActiveAppointments();
        closeCancelModal();
      }
    })
  };

  const cancelModalFooter = (id) => {
    return (
      <>
        <div className='row justify-content-end'>
          <div className='col-md-auto'>
            <Button
              variant="outline"
              className='d-btn'
              onClick={() => closeCancelModal()}
              >
              Vazgeç
            </Button>
          </div>

          <div className='col-md-auto me-2'>
            <Button
              variant="outline-danger"
              className='d-btn'
              onClick={() => cancelAppointment(id)}
            >
              İptal Et
            </Button>
          </div>
        </div>
      </>
    )
  };

  const cancelModal = () => {
    if (cancelModalData.isVisible) {
      return (
        <>
          <Dialog
            header="Randevu İptali"
            visible={cancelModalData.isVisible}
            style={ window.innerWidth <= 768 ? {  width: '100vw' } : {width: '50vw'}}
            onHide={() => closeCancelModal()}
            footer={() => cancelModalFooter(cancelModalData.appointment.id)}
          >
            {
              userRole === 'doctor' ?
                `${cancelModalData.appointment.userDto.name} ${cancelModalData.appointment.userDto.surname} ile
                olan ${format(new Date(cancelModalData.appointment.appointmentTime), 'dd/MM/yyyy HH.SS')} tarihli
                randevunuzu iptal etmek istediğinize emin misiniz ?`
              :
                `${doctorFullName(cancelModalData.appointment.doctorInfoDto)} ile
                olan ${format(new Date(cancelModalData.appointment.appointmentTime), 'dd/MM/yyyy HH.SS')} tarihli
                randevunuzu iptal etmek istediğinize emin misiniz ?`
            }

            <div className="mt-2" style={{display:"flex",flexDirection:"column"}}>
              <label>İptal Nedeni (*)</label>
              <InputTextarea value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} rows={5} cols={30} />
            </div>

          </Dialog>
        </>
      )
    }
  };

  const saveEditChanges = () => {
    if (!editModalData.name){
      swal("Uyarı","Lütfen isminizi kontrol ediniz.","warning");
      return;
    }
    if (!editModalData.surname){
      swal("Uyarı","Lütfen soyisminizi kontrol ediniz.","warning");
      return;
    }
    if (phone === undefined || phone === null || phone === '' || !isValidPhoneNumber(phone)){
      swal("Hata", "Lütfen telefon numaranızı kontrol ediniz.", "error");
      return;
    }
    if (userRole === 'user') {
      const payload = { userId: user.userId,id:editModalData.id,name:editModalData.name,surname:editModalData.surname,phoneNumber:phone};

      userProfileService.updateUser(selectedPhoto,JSON.stringify(payload))
        .then((res) => {
          if(res&&res.success){
            getUserProfileData();
            swal("Başarılı", "Profiliniz başarı ile güncellenmiştir.", "success");
          }else{
            swal("Hata!", res.message, "error");
          }
        });
    } else if (userRole === 'doctor') {
      if (languages.length == 0){
        swal("Uyarı", "En az bir dil seçmelisiniz.", "warning");
        return;
      }

      if (editModalData.instagram){
        if (!validateInstagramUrl(editModalData.instagram)){
          swal("Uyarı","Lütfen geçerli bir instagram linki girin.","warning");
          return;
        }
      }

      if (editModalData.facebook){
        if (!validateFacebookUrl(editModalData.facebook)){
          swal("Uyarı","Lütfen geçerli bir facebook linki girin.","warning");
          return;
        }
      }

      if (editModalData.twitter){
        if (!validateTwitterUrl(editModalData.twitter)){
          swal("Uyarı","Lütfen geçerli bir twitter linki girin.","warning");
          return;
        }
      }

      if (editModalData.youtube){
        if (!validateYouTubeUrlChannel(editModalData.youtube)){
          swal("Uyarı","Lütfen geçerli bir youtube linki girin.","warning");
          return;
        }
      }

      if (!editModalData.iban){
        swal("Uyarı","Lütfen iban girin.","warning");
        return;
      }

      if (editModalData.iban&&editModalData.iban.length<26){
        swal("Uyarı", "Iban min 26 karakter olabilir.", "warning");
        return;
      }

      if (!editModalData.ibanFullName){
        swal("Uyarı","Lütfen iban tam ismini girin.","warning");
        return;
      }

      if (!editModalData.perPriceWithTl){
        swal("Uyarı", "Lütfen Seans/TL bilgisi girin.", "warning");
        return;
      }

      if (!editModalData.paymentInfoCheck){
        swal("Uyarı","Lütfen Ödemelerin yukarıdaki IBAN ve isme yapılacağını onaylıyorum kutucuğunu onaylayın.","warning");
        return;
      }

      if (!editModalData.perPriceWithEuro){
        swal("Uyarı", "Lütfen Seans/Euro bilgisi girin.", "warning");
        return;
      }

      const payload = { ...editModalData, phone,languages };

      doctorService.updateDoctor(selectedPhoto,JSON.stringify(payload))
        .then((res) => {
          if(res&&res.success){
            getDoctorUserData();
            swal("Başarılı", "Profiliniz başarı ile güncellenmiştir.", "success");
          }else{
            swal("Hata!", res.message, "error");
          }
        });
    }

    setUserData({...editModalData});

    setIsEditMode(false);
    navigate('/profile');
  };

  const cancelEditChanges = () => {
    setEditModalData(userData);

    setIsEditMode(false);
    navigate('/profile');
  };

  const editModalFooter = () => {
    return (
      <>
        <Divider align="center"/>
        <div className='row justify-content-end'>
          <div className='col-md-auto'>
            <Button
              variant="outline"
              className='d-btn'
              onClick={() => cancelEditChanges()}
              >
              Vazgeç
            </Button>
          </div>

          <div className='col-md-auto me-2'>
            <PrimeButton
              label="Kaydet"
              onClick={() => saveEditChanges()}
            />
          </div>
        </div>
      </>
    )
  };

  const uploadPdf=(pdf)=>{

    setCvLoading(true);
    doctorService.uploadCv(pdf,editModalData.id).then(res=>{
      if (res&&res.success == true){
        swal("Başarılı",res.message,"success");
        setCvLoading(false);
      }else{
        swal("Hata",res.message,"error");
        setCvLoading(false);
      }
    }).catch(e=>{
      setCvLoading(false);
    })
  }

  const previewCV=()=>{

    setCvLoading(true);

    doctorService.getCvByDoctorId(editModalData.id).then(res=>{
      if (res&&res.success === true){
        const view= <div style={{display:"flex",flexDirection:'column',height:'100%'}}>
          <div style={{display:"flex",height:'90%'}}>
            <iframe src={res.object.cvUrl} height="100%" width="100%"/>
          </div>
        </div>

        onShowDialog(view,{width:'80%',height:'100%'});
        setCvLoading(false);
      }else{
        if (res.message){
          swal("Hata",res.message,"error")
        }
        setCvLoading(false);
      }

    }).catch(e=>{
      setCvLoading(false);
    })
  }

  const editModal = () => {
    const genderItems = [
      {label: 'Erkek', value: 'MALE'},
      {label: 'Kadın', value: 'FEMALE'},
      {label: 'Belirtilmedi', value: 'NO_CHOICE'},
    ];

    if (isEditMode) {
      return (
        <>
          <Dialog
            header="Profil Düzenle"
            visible={isEditMode}
            style={ window.innerWidth <= 768 ? {  width: '100vw' } : {width: '50vw'}}
            onHide={() => cancelEditChanges()}
            footer={() => editModalFooter()}
          >
            <div className='row'>
              <div className='row mt-3'>
                <div className='col-md-12 pe-2'>
                  <div>
                    <label>Profil Resmi</label>
                  </div>

                  <div className='row'>
                    <div className="col-md-7 mb-2">
                      <img
                          className='profile-photo-sidebar'
                          src={editModalData.profilePhotoUrl || 'https://archive.org/download/no-photo-available/no-photo-available.png'}
                      />
                    </div>
                    {userRole === 'doctor' &&<div className="col-md-5" style={{display:'flex',alignItems:'flex-start',height:'50px',justifyContent:'flex-end'}}>
                      <div style={{display:'flex',alignItems:"center",justifyContent:"flex-end",height:'100%',padding:'.3rem'}}>
                        {cvLoading && renderCvLoading()}
                      </div>
                      <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                          <i onClick={()=> previewCV()} style={{color:'black',cursor:'pointer'}} className="fa-solid fa-eye"></i>
                          <a style={{color:'black'}}>Cv görüntüle</a>
                        </div>
                      </div>
                      <Divider style={{height:'1em'}} align="center" layout="vertical" >
                      </Divider>
                      <FileUpload
                          mode="basic"
                          name="demo[]"
                          url="/"
                          accept="application/pdf"
                          chooseOptions={{label: 'Cv Yükle', icon: 'fa-solid fa-upload'}}
                          onSelect={(e) => {
                            uploadPdf(e.files[0])
                            setSelectedPdf(e.files[0]);
                          }}
                          onUpload={() => { console.log('uploaded'); }}
                      />
                    </div>}
                  </div>

                  <div className='row'>
                    <FileUpload
                        className='mt-1'
                        chooseLabel={ editModalData.profilePhotoUrl ? 'Değiştir' : 'Yükle' }
                        mode="basic"
                        name="demo[]"
                        url="/"
                        accept="image/*"
                        onSelect={(e) => {
                          setSelectedPhoto(e.files[0]);
                        }}
                        onUpload={() => { console.log('uploaded'); }}
                    />
                  </div>
                </div>
              </div>

              <Divider align="center">
                <span className="p-tag">Temel Bilgiler</span>
              </Divider>

              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-6 pe-2'>
                    <div>
                      <label>İsim</label>
                    </div>

                    <InputText
                      value={editModalData.name}
                      onChange={(e) => setEditModalData({...editModalData, name: e.target.value})}
                    />
                  </div>

                  <div className='col-md-6 ps-2'>
                    <div>
                      <label>Soyisim</label>
                    </div>

                    <InputText
                      value={editModalData.surname}
                      onChange={(e) => setEditModalData({...editModalData, surname: e.target.value})}
                    />
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-6 pe-2'>
                    <div>
                      <label>E-posta</label>
                    </div>

                    <InputText value={editModalData.email} disabled/>
                  </div>

                  <div className='col-md-6 ps-2'>
                    <div>
                      <label>Telefon Numarası</label>
                    </div>

                    <PhoneInput
                        className="form-control form-control-not-block"
                        placeholder="Telefon"
                        value={phone}
                        disabled={true}
                        focusInputOnCountrySelection={true}
                        onChange={phoneOnChange}
                    />
                  </div>
                </div>

                {
                  userRole === 'doctor' ?
                    <>
                      <div className='row mt-3'>
                        <div className='col-md-6 pe-2'>
                          <div>
                            <label>Branş</label>
                          </div>

                          <InputText value={editModalData&&editModalData.tibbiBirim&&editModalData.tibbiBirim.birimAdi} disabled/>
                        </div>

                        <div className='col-md-6 pe-2'>
                          <div>
                            <label>Uzmanlık Alanı</label>
                          </div>

                          <InputText value={editModalData&&editModalData.profession}
                                     onChange={(e) => setEditModalData({...editModalData, profession: e.target.value})}
                          />
                          <span style={{fontSize:'12px',fontStyle:'italic'}}><i className="fa-solid fa-circle-info"></i>Birden fazla uzmanlık alanınız olması durumunda aralarına # ekleyerek giriş yapınız. <br/> Örn: Uzmanlık Alanı1#Uzmanlık Alanı 2 </span>
                        </div>
                      </div>

                      <div className='row mt-3'>
                        <div className='col-md-12'>
                          <div>
                            <label>Diller</label>
                          </div>

                          <MultiSelectComponent languages={languages} setLanguages={setLanguages}/>
                        </div>
                      </div>
                      <Divider align="center">
                        <span className="p-tag">Ödeme Bilgileri</span>
                      </Divider>
                      <div className='row mt-3'>
                        <div className='col-md-6 pe-2'>
                          <div>
                            <label>Iban</label>
                          </div>

                          <InputText value={editModalData&&editModalData.iban}
                                     onChange={(e) => setEditModalData({...editModalData, iban: e.target.value})}
                          />
                        </div>

                        <div className='col-md-6 pe-2'>
                          <div>
                            <label>Iban Tam İsmi</label>
                          </div>

                          <InputText value={editModalData&&editModalData.ibanFullName}
                                     onChange={(e) => setEditModalData({...editModalData, ibanFullName: e.target.value})}
                          />
                        </div>
                        <div className='col-md-6 pe-2 pt-2'>
                          <div className="field-checkbox">
                            <input checked={editModalData.paymentInfoCheck} type="checkbox" onChange={e => setEditModalData({...editModalData, paymentInfoCheck: e.target.checked})} />
                            <label htmlFor="binary">
                              <span style={{fontSize:'12px',fontStyle:'italic'}}><i className="fa-solid fa-circle-info"></i>Ödemelerin yukarıdaki IBAN ve isme yapılacağını onaylıyorum. </span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <Divider align="center">
                        <span className="p-tag">Seans ve Soru Ücretleri</span>
                      </Divider>

                      <div className='row mt-3'>
                        <div className='col-md-3 pe-2'>
                          <div>
                            <label>Seans/TL</label>
                          </div>

                          <InputText
                            value={editModalData.perPriceWithTl}
                            onChange={(e) => setEditModalData({...editModalData, perPriceWithTl: e.target.value})}
                          />
                        </div>

                        <div className='col-md-3 pe-2'>
                        <div>
                            <label>Seans/Dolar</label>
                          </div>

                          <InputText
                            value={editModalData.perPriceWithDolar}
                            onChange={(e) => setEditModalData({...editModalData, perPriceWithDolar: e.target.value})}
                          />
                        </div>

                        <div className='col-md-3'>
                          <div>
                            <label>Seans/Euro</label>
                          </div>

                          <InputText
                            value={editModalData.perPriceWithEuro}
                            onChange={(e) => setEditModalData({...editModalData, perPriceWithEuro: e.target.value})}
                          />
                        </div>

                        <div className='col-md-3 ps-2'>
                          <div>
                            <label>Seans/Sterlin</label>
                          </div>

                          <InputText
                            value={editModalData.perPriceWithGbp}
                            onChange={(e) => setEditModalData({...editModalData, perPriceWithGbp: e.target.value})}
                          />
                        </div>
                      </div>

                      <div className='row mt-3'>
                        <div className='col-md-3 pe-2'>
                          <div>
                            <label>Soru/TL</label>
                          </div>

                          <InputText
                            value={editModalData.quePriceWithTl}
                            onChange={(e) => setEditModalData({...editModalData, quePriceWithTl: e.target.value})}
                          />
                        </div>

                        <div className='col-md-3 pe-2'>
                        <div>
                            <label>Soru/Dolar</label>
                          </div>

                          <InputText
                            value={editModalData.quePriceWithDolar}
                            onChange={(e) => setEditModalData({...editModalData, quePriceWithDolar: e.target.value})}
                          />
                        </div>

                        <div className='col-md-3'>
                          <div>
                            <label>Soru/Euro</label>
                          </div>

                          <InputText
                            value={editModalData.quePriceWithEuro}
                            onChange={(e) => setEditModalData({...editModalData, quePriceWithEuro: e.target.value})}
                          />
                        </div>

                        <div className='col-md-3 ps-2'>
                          <div>
                            <label>Soru/Sterlin</label>
                          </div>

                          <InputText
                            value={editModalData.quePriceWithGbp}
                            onChange={(e) => setEditModalData({...editModalData, quePriceWithGbp: e.target.value})}
                          />
                        </div>
                      </div>

                      <Divider align="center">
                        <span className="p-tag">Sosyal Medya Linkleri</span>
                      </Divider>

                      <div className='row mt-3'>
                        <div className='col-md-12 pe-2 mt-2'>
                          <div>
                            <label>Instagram</label>
                          </div>

                          <InputText
                              value={editModalData.instagram}
                              onChange={(e) => setEditModalData({...editModalData, instagram: e.target.value})}
                          />
                          <span style={{fontSize:'12px',fontStyle:'italic'}}>Örn: https://instagram.com/doctordedi</span>
                        </div>

                        <div className='col-md-12 pe-2 mt-2'>
                          <div>
                            <label>Facebook</label>
                          </div>

                          <InputText
                              value={editModalData.facebook}
                              onChange={(e) => setEditModalData({...editModalData, facebook: e.target.value})}
                          />
                          <span style={{fontSize:'12px',fontStyle:'italic'}}>Örn: https://facebook.com/doctordedi</span>
                        </div>

                        <div className='col-md-12 mt-2'>
                          <div>
                            <label>Twitter</label>
                          </div>

                          <InputText
                              value={editModalData.twitter}
                              onChange={(e) => setEditModalData({...editModalData, twitter: e.target.value})}
                          />
                          <span style={{fontSize:'12px',fontStyle:'italic'}}>Örn: https://twitter.com/doctordedi</span>
                        </div>

                        <div className='col-md-12 mt-2'>
                          <div>
                            <label>Youtube</label>
                          </div>

                          <InputText
                              value={editModalData.youtube}
                              onChange={(e) => setEditModalData({...editModalData, youtube: e.target.value})}
                          />
                          <span style={{fontSize:'12px',fontStyle:'italic'}}>Örn: https://youtube.com/@doctordedi</span>
                        </div>
                      </div>

                      <div className='row mt-3'>
                        <div className='col-md-12'>
                          <div>
                            <label>Biyografi</label>
                          </div>

                          <InputTextarea
                            style={{height:'250px'}}
                            value={editModalData.aciklama}
                            onChange={(e) => setEditModalData({...editModalData, aciklama: e.target.value})}
                          />
                        </div>
                      </div>
                    </>
                  :
                    <></>
                }
              </div>
            </div>
          </Dialog>
        </>
      )
    }
  };

  const renderAppointmentDialog=()=>{
    return(
        <Dialog header="" style={{boxShadow: 'rgba(0, 0, 0, 1) 2px 5px 15px'}} className="appointment-dialog" visible={showAppointmentDialog} position="top"  onHide={() => {
          setShowAppointmentDialog(false);
          setSelectedTab('active-appointments');
          if (user.userRoles[0] && user.userRoles[0].name === 'ROLE_USER') {
            getActiveAppointments();
          }
          dispatch(clearState());
        }}>
          <Appointment/>
        </Dialog>
    )
  }

  const renderGalleriaEditDialog=()=>{
    return(
        <Dialog header="Galeri Düzenle"
                style={{boxShadow: 'rgba(0, 0, 0, 1) 2px 5px 15px'}}
                className="appointment-dialog"
                visible={showGalleriEditDialog} position="top"
                  onHide={() => {
                  navigate('/profile');
                  setShowGalleriEditDialog(false);
                }}
              >
          <GalleriaTabView/>
        </Dialog>
    )
  }

  const renderSeansEditDialog=()=>{
    return(
        <Dialog header="Seans Bilgileri"
                style={{boxShadow: 'rgba(0, 0, 0, 1) 2px 5px 15px'}}
                className="appointment-dialog"
                visible={showSeansEditDialog} position="top"
                onHide={() => {
                  navigate('/profile');
                  setShowSeansEditDialog(false);
                }}
        >
          <SeansEdit/>
        </Dialog>
    )
  }

  const renderCvLoading=()=>{
    return(
        <div className="loading-div">
          <ProgressSpinner style={{width: '1rem', height: '1rem'}} />
        </div>
    )
  }

  return (
    <>
     <div className='row g-0 justify-content-center' style={{ height: '100%'}}>
        <div className='col-md-2'>
          {
            isUserLoading ?
            <ProgressSpinner />
            :
            <Sidebar data={userData}/>
          }
        </div>

        <div className='col-md-6 ps-4 pt-2' style={{ height: '100%'}}>
          <div className='row user-actions'>
            <div className='col-auto me-1'>
              <Button
                variant="outline-primary"
                className='d-btn'
                active={ selectedTab === 'past-appointments'}
                disabled={ isActiveLoading || isPastLoading }
                onClick={() => setSelectedTab('past-appointments')}
                >
                Geçmiş Randevularım
              </Button>
            </div>

            <div className='col-auto me-1'>
              <Button
                variant="outline-primary"
                className='d-btn'
                disabled={ isActiveLoading || isPastLoading }
                active={ selectedTab === 'active-appointments' }
                onClick={() => setSelectedTab('active-appointments')}
                >
                Aktif Randevularım
              </Button>
            </div>

            {
              userRole === 'user' ?
              <div className='col-auto'>
                  <Button
                    variant="outline-success"
                    className='d-btn'
                    disabled={ isActiveLoading || isPastLoading }
                    active={ selectedTab === 'new-appointment'}
                    onClick={() => {
                      setShowAppointmentDialog(true);
                    }}
                  >
                    Yeni Randevu
                  </Button>
                </div>
              :
                <></>
            }
          </div>

          <div className='row appointment-list-wrapper'>
            {
              isActiveLoading || isPastLoading ?
              <ProgressSpinner />
              :
              <div className='col-md-12'>
                {
                  renderingAppointments.length
                  ?
                  renderingAppointments.map((appointment) => (
                    <div className='row p-2 mt-2 appointment-card' key={appointment.id}>
                      {
                        userRole === 'doctor' ?
                          doctorAppointmentCard(appointment)
                        :
                          appointmentCard(appointment)
                      }
                    </div>
                  ))
                  :
                  selectedTab !== 'new-appointment' ? selectedTab === 'active-appointments' ?
                      'Aktif randevunuz bulunmamaktadır.' : selectedTab === 'past-appointments' ? 'Geçmiş randevunuz bulunmamaktadır.' : '' :  ''
                }
              </div>
            }
          </div>
          { cancelModal() }
          {renderAppointmentDialog()}
          {userRole === 'doctor' && renderGalleriaEditDialog()}
          {userRole === 'doctor' && renderSeansEditDialog()}
          { editModal() }
        </div>
      </div>
    </>
  )
}
