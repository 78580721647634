import {Calendar} from "primereact/calendar";
import React, {useEffect, useState} from "react";
import {addLocale} from "primereact/api";
import tr from "../../locale/tr.json";
import './date-and-time-picker.css'
import DoctorCalendarService from "../../service/doctor/doctor.calendar.service";
import {useDispatch, useSelector} from "react-redux";
import {addDate, addDoctorCalendarId, addHour, setActiveStep} from "../../redux/actions/appointment.actions";
import {HOURS} from "../../redux/const";
import { Card } from 'primereact/card';
import DoctorServiceWithoutToken from "../../service/doctor/doctor.service.without.token";

const DateAndTimePicker = () => {

    addLocale('tr', tr);

    const today = new Date();

    const [doctorCalendar,setDoctorCalendar] = useState([]);
    const [selectedHour,setSelectedHour] = useState('');
    const [viewDate, setViewDate] = useState(new Date());
    const [availableDays, setAvailableDays] = useState([]);
    let user = useSelector(state => state.auth.user);

    const doctorCalendarService = new DoctorCalendarService(user.accessToken);
    const doctorService = new DoctorServiceWithoutToken();

    const dispatch = useDispatch();
    let selectedDoctorId = useSelector(state => state.appointment.doctor.id);

    function getDifference(a, b) {
        return a.filter(element => {
            return !b.includes(element);
        });
    }

    useEffect(() => {
        if (viewDate){
            let now = new Date(viewDate);
            let year = now.getFullYear();
            let month = now.getMonth() + 1;

            let request={
                "doctorId": selectedDoctorId,
                "year": year,
                "month": month
            }

            doctorService.getDoctorCalendarList(request).then(res=>{
                if (res&&res.object){
                    setAvailableDays(res.object);
                }
            })
        }

    },[viewDate]);

    const findDoctorScheduler = selected =>{
        let selectedDate = new Date(selected.value);
        dispatch(addDate(selectedDate));

        if (isNaN(selectedDate)){
            selectedDate = new Date();
        }

        let day = selectedDate.getDate();
        let month = selectedDate.getMonth() + 1;
        let year = selectedDate.getFullYear();

        let request={
            "doctorId": selectedDoctorId,
            "year": year,
            "month": month,
            "day": day
        }

        doctorCalendarService.getDoctorAvailableHours(request).then(res=>{
            if (res && res.object.length > 0){
                let matchesHours = [];
                let result=[];
                res.object.map(doctorCalendar => {
                    let doctorCalendarSplit = doctorCalendar.hour.split(':');
                    let splittedHour='';
                    if (doctorCalendarSplit.length>0){
                        splittedHour = doctorCalendarSplit[0] + ':' + doctorCalendarSplit[1];
                    }
                    let filterResult = HOURS.filter(hour => hour == splittedHour);
                    if (filterResult.length>0){
                        doctorCalendar.disabled = false;
                        matchesHours.push(filterResult[0]);
                    }else{
                        doctorCalendar.disabled = true;
                    }
                    doctorCalendar.hour = splittedHour;
                    result.push({hour:doctorCalendar.hour,disabled:doctorCalendar.disabled,id: doctorCalendar.id});
                });

                let notMatchHour = getDifference(HOURS,matchesHours);
                notMatchHour.map(e=>{
                    result.push({hour:e,disabled:true});
                });

                var collator = new Intl.Collator([], {numeric: true});
                setDoctorCalendar(result.sort((a, b) => collator.compare(a.hour, b.hour)));
            }else{
                let result = [];
                HOURS.map(e=>{
                    result.push({hour:e,disabled:true});
                })
                setDoctorCalendar(result);
            }
        })
    }

    useEffect(()=>{
        if (selectedHour){
            dispatch(setActiveStep(3))
        }
    },[selectedHour])

    useEffect(()=>{
        dispatch(addDate(today));
        findDoctorScheduler(today);
    },[])

    const addSelectedHour= (hour,id) =>{
        setSelectedHour(hour);
        dispatch(addHour(hour));
        dispatch(addDoctorCalendarId(id));
    }

    const dateTemplate = (date) => {
        if (availableDays.filter(e => e.day === date.day && e.month === date.month + 1 && e.year === date.year).length > 0 && compareDates(date)) {
            return (
                <div className='doctor-calendar-available-day fw-bold'>
                    <div className='row'>
                    </div>
                    <div className='row'>
                        {date.day}
                    </div>
                </div>
            );
        }
        else {
            return date.day;
        }
    };

    const compareDates=(date)=>{
        let dateIsLaterFromToday=false;

        let todayMonth = today.getMonth() + 1;
        let todayYear =  today.getFullYear();

        if (date.year > todayYear){
            dateIsLaterFromToday = true;
        }else if ((date.year == todayYear) && (date.month +1 > todayMonth)){
            dateIsLaterFromToday = true;
        }else if((date.month +1 == todayMonth) && (date.day >= today.getDate())){
            dateIsLaterFromToday = true;
        }

        return dateIsLaterFromToday;
    }

    return(
        <div style={{width:'100%',marginBottom:'5em'}}>
            <div>
                <Calendar minDate={today} maxDate={new Date(new Date().setDate(today.getDate() + 30))}
                          className='doctor-calendar' inline locale={'tr'}
                          dateTemplate={dateTemplate}
                          viewDate={viewDate}
                          onViewDateChange={(e) => {
                              setViewDate(e.value);
                          }}
                          onChange={findDoctorScheduler} />
            </div>
            <div>
            </div>
            <Card title="Lütfen aşağıdan randevu saatinizi seçin" style={{ fontSize:'inherit', width: '100%', marginBottom: '2em',marginTop:'1%' }}>
                <div className="hour-area">
                    {doctorCalendar.map((doctorCalendar,index)=>{
                    return (
                        doctorCalendar.disabled == true ? <div className="hour-item-disabled" key={"itemdisabled"+index}>
                            <span>{doctorCalendar.hour}</span>
                        </div> :
                            <div className="hour-item" key={"item"+index}>
                                <span onClick={() => addSelectedHour(doctorCalendar.hour,doctorCalendar.id)}>{doctorCalendar.hour}</span>
                            </div>
                    )
                })}
             </div>
            </Card>
        </div>
    )
}

export default DateAndTimePicker;
