import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {store,persistor} from "./redux/reducers/index";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import 'react-credit-cards/es/styles-compiled.css';
import SnackBarProvider from "./core/SnackBar/SnackBarProvider";
import DialogProvider from "./core/Dialog/DialogProvider";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
      <Provider store={store} >
        <DialogProvider   />
        <SnackBarProvider>
          <PersistGate loading={null} persistor={persistor}>
              <App />
          </PersistGate>
        </SnackBarProvider>
      </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
