import React, {useEffect} from 'react';
import './doctor-box-home-page.css';
import ReactStars from "react-rating-stars-component";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addDoctor} from "../../redux/actions/appointment.actions";
import {getIp} from "../../util/util";
import {AUTH_CONSTANT} from "../../redux/const";

const DoctorBoxHomePage = ({doctor}) =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const locale = useSelector(state => state.auth.locale);

    const getIpInfo= async ()=>{
        let ipRes = await getIp();
        dispatch({type : AUTH_CONSTANT.SET_LOCALE,payload : {locale: {currency: ipRes.currency, ip: ipRes.ip,lang:ipRes.lang}}});
    }

    useEffect(()=>{
        getIpInfo();
    },[])

    const goDoctorPage=(doctor)=>{
        dispatch(addDoctor({id:doctor.id,name:doctor.name,surname:doctor.surname}));
        navigate(`/doctor/${doctor.id}`);
    }

    return(
        <div id="doctor-box-home-page-div" style={{boxShadow: 'rgba(0, 0, 0, 1) 2px 5px 15px',display:"flex",width:'18%',backgroundColor:'#0885bf',flexDirection:'column',margin:'1%',borderRadius:'8%',height:'28rem'}}>
            <div style={{display:"flex",justifyContent:'center',alignItems:"center",padding:'5%'}}>
                <img src={doctor.photoUrl ? doctor.photoUrl : require('../../assets/img/2.1.png')} alt={'doctor'} className="profile-photo-sidebar" style={{ borderRadius: '50%', backgroundColor:'white',border:'1px solid white'}} />
            </div>
            <div style={{display:"flex",flex:4,flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <div className="doctor-box-span" ><span className="doctor-box-span-text" style={{fontWeight:'bold'}}>{doctor.unvan + ' ' + doctor.name + ' ' + doctor.surname}</span></div>
                <div className="doctor-box-span" ><span className="doctor-box-span-text">Seans Ücreti: { locale ? locale.currency === 'TL' ?  doctor.perPriceWithTl : doctor.perPriceWithEuro : doctor.perPriceWithTl} {locale&&locale.currency}</span></div>
                <div className="doctor-box-span" >
                    <ReactStars
                        color="light-gray"
                        count={5}
                        edit={false}
                        size={24}
                        value={doctor.rate ? doctor.rate : 0}
                        activeColor="#ffd700"
                    />
                </div>
                <div className="doctor-box-span" >
                    <span onClick={()=>goDoctorPage(doctor)} className="doctor-box-span-text-detay">Detay</span>
                </div>
            </div>
        </div>
    )
}

export default DoctorBoxHomePage;
