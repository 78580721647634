import React from "react";
import { useState,useEffect } from "react";
import DoctorListHomePage from "../components/doctor-list/DoctorListHomePage";
import SelectComponent from "../components/select/SelectComponent";
import BranchServiceWithoutToken from "../service/branch/branch.service.without.token";
import { ProgressSpinner } from "primereact/progressspinner";
import {useDispatch, useSelector} from "react-redux";
import {addBranch} from "../redux/actions/appointment.actions";
import {useLocation} from "react-router-dom";

const defaultBranch = {
    name : "Acil Tıp",
    value : 19,
    disable : false
}

export default function DoctorsAndBranches() {
  const branchService = new BranchServiceWithoutToken();
  const [data, setData] = useState([]);
  const [selectedBranchData, setSelectedBranchData] = useState({name:'',value:'',disabled:false});
  const [branchId, setBranchId] = useState(null);
  const [loading, setLoading] = useState(false);
  const appointment = useSelector(state=> state.appointment);
  const dispatch = useDispatch();
  const location = useLocation();
  window.scrollTo(0, 0)

  useEffect(() => {
    branchService.list().then((res) => {
      if (res && res.object.length > 0) {
        let dataSearch = res.object.map((branch) => ({
          name: branch.birimAdi,
          disabled: false,
          value: branch.id,
        }));

        setData(dataSearch);

        let selectedBranch;
        if(location && location.state && location.state.isHomePageRoute){
            selectedBranch = location.state.branch;
        }else{
            if (appointment&&appointment.branch&&appointment.branch.id){
               let filteredBranch = dataSearch.filter(e => e.value == appointment.branch.id);
                selectedBranch = filteredBranch&&filteredBranch.length == 1 ? filteredBranch[0] : defaultBranch;
            }else{
                selectedBranch = defaultBranch;
            }
        }

        setSelectedBranchData(selectedBranch);

      }
    });
  }, []);

  const setSelectedBranch=(branchId)=>{
      setBranchId(branchId);
      dispatch(addBranch({id:branchId}));
  }

  const renderLoading = () => {
    return <ProgressSpinner style={{ width: "30px", height: "30px" }} />;
  };

  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",marginBottom:'20em'}}>
      <div style={{marginTop:'3em'}}>
        <SelectComponent
          selectTitle={"Branş Seç"}
          options={data}
          autoReload={true}
          selectedBranch={selectedBranchData}
          setSelectedValue={setSelectedBranch}
        />
      </div>
      <div style={{minHeight:'50px',marginTop:'1%'}}>{loading && renderLoading()}</div>
      <div>
      </div>
      <div style={{display:"flex",width:'100%',flexWrap:"wrap",alignItems:"center",justifyContent:"center"}}>
        <DoctorListHomePage
          selectedBranchId={branchId}
          setLoading={(val) => {
            setLoading(val);
          }}
        />
      </div>
    </div>
  );
}
