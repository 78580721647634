import BaseService from "../base/base.service";

export default class MobileService extends BaseService {

    constructor(token) {
        super({});
        this.endpoint = '/mobile';
    }

    validateToken = (encryptedData) => {
        return this.httpClient.get(this.endpoint + "/validate", {params : {encryptedData:encryptedData},headers:this.headers}).then(res => {
            return res.data;
        })
    }
}
