import React, {useEffect, useState} from "react";
import "./most-prefer-branch.css";
import bg10 from "../../assets/img/bg10.png";
import BranchServiceWithoutToken from "../../service/branch/branch.service.without.token";
import {useNavigate} from "react-router-dom";

const iconPath = '../../assets/branch-icons/';

const MostPreferBranch = () => {

    const [branchList,setBranchList] = useState([]);
    const branchService = new BranchServiceWithoutToken();
    const navigate = useNavigate();

    useEffect(()=>{
        branchService.findMostPreferBranches().then(res=>{
            if (res&&res.object.length>0&&res.success === true){
                setBranchList(res.object);
            }
        })
    },[])

    const goDoctorsAndBrancesPage=(branch)=>{
        let selectedBranch = {
            name : branch.birimAdi,
            value : branch.id,
            disable : false
        }
        navigate('/doctorandbranch',{state:{isHomePageRoute:true,branch:selectedBranch}});
    }

  return (
      <div className="branches-container" style={{ backgroundImage: `url(${bg10})` }}>
          <div className="branches-text-area">
            <h1 className="branches-title">
                EN ÇOK TERCİH <br></br> EDİLEN BRANŞLAR
            </h1>
          </div>
            <div className="branches-box-area">
                {branchList.length > 0 && branchList.map((branch,keyind)=>{
                    return (
                        <div key={keyind} className="branches-box">
                            <img src= {branch.iconPath} width="150px"  width="150px" alt="icon" />
                            <a href="#" style={{textDecoration:'none'}}>
                                <h4 onClick={()=> goDoctorsAndBrancesPage({birimAdi:branch.birimAdi,id:branch.id})}>{branch.birimAdi}</h4>
                            </a>
                            <p>{branch.aciklama}</p>
                        </div>
                    )
                })}
            </div>
    </div>
  );
};

export default MostPreferBranch;
