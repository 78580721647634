import React, { useState } from "react";
import "../css/blog_page.css";
import { Link } from "react-router-dom";
import Blogcard from "../components/blogcard/Blogcard";

const BlogPage = () => {
 

  const articles=[
    {
      id: 1,
      title: "Covid-19 Nedir?",
      desc:
        "COVID-19'a yakalanan kişilerin çoğu, hafif ila orta düzeyde semptomlar göstermekte ve özel bir tedaviye ihtiyaç duymadan iyileşmektedir. Bazıları ise ciddi şekilde hastalanmakta ve tıbbi yardıma ihtiyaç duymaktadır.",
    },
    {
      id: 2,
      title: "Check-up Nedir ?",
      desc:
        "Check up tam kapsamlı bir sağlık taramasıdır. Kişilerin farkında olmadığı mevcut bir takım hastalıkların veya olası sağlık sorunlarının tanı ve tedavisinin yapılmasına olanak tanıyan bir dizi medikal kontrol yöntemidir",
    },
  ];

  
  return (
    <div>
      
        <h3 className="blog-title">BLOG</h3>
        <div className="main-sperator" ></div>
        <div className="container blog-container d-flex flex-column align-items-center">
          {articles.map((item,ind)=><Blogcard id={item.id} title={item.title} description={item.desc}  key={ind} />)}
        </div>
       
    </div>
  );
};

export default BlogPage;
