import React, {useEffect, useState} from 'react';
import { Calendar } from 'primereact/calendar';
import {addLocale} from "primereact/api";
import tr from "../../locale/tr";
import {useSelector} from "react-redux";
import {Card} from "primereact/card";
import {HOURS} from "../../redux/const";
import DoctorCalendarService from "../../service/doctor/doctor.calendar.service";
import './seans-edit.css'
import { ConfirmDialog } from 'primereact/confirmdialog';
import {isBefore} from "../../util/date-util";
import {Button as PrimeButton} from "primereact/button";
import swal from "sweetalert";

 const SeansEdit =()=>{
     addLocale('tr', tr);

     const today = new Date();
     const [showPopup, setShowPopup] = useState(false);
     let user = useSelector(state => state.auth.user);
     const [submitting,setSubmitting] = useState(false);
     const [doctorCalendar,setDoctorCalendar] = useState([]);
     const [selectedCalendarDate,setSelectedCalendarDate] = useState(today);
     const [selectedYearMonthMap,setSelectedYearMonthMap] = useState(new Map());
     const doctorCalendarService = new DoctorCalendarService(user.accessToken);

     useEffect(()=>{
         findDoctorScheduler(today);
         selectedYearMonthMap.clear();
     },[])

     const addYearMonthMap=(doctorCalendar,hour)=>{

         const yearMonth = doctorCalendar.year + "#" + doctorCalendar.month + "#" + doctorCalendar.day;

         if (!selectedYearMonthMap.get(yearMonth)){
             selectedYearMonthMap.set(yearMonth,[hour]);
         }else{
             let existYearMonth = selectedYearMonthMap.get(yearMonth);
             const filterObj = existYearMonth.find(ym=> ym == hour);
             if (!filterObj){
                 existYearMonth.push(hour);
             }
         }

         setSelectedYearMonthMap(selectedYearMonthMap);
     }

     const  deleteFromYearMonthMap=(doctorCalendar,hour)=>{
         const yearMonth = doctorCalendar.year + "#" + doctorCalendar.month + "#" + doctorCalendar.day;

         let existYearMonth = selectedYearMonthMap.get(yearMonth);

         if (existYearMonth&&existYearMonth.length>0){
             const index = existYearMonth.indexOf(hour);

             if (index > -1) {
                 existYearMonth.splice(index, 1);
             }

             if (existYearMonth.length==0){
                 selectedYearMonthMap.set(yearMonth,[]);
             }

         }

         setSelectedYearMonthMap(selectedYearMonthMap);
     }

     const onChangeDate = selected =>{
         let selectedDate = new Date(selected.value);

         if (isNaN(selectedDate)){
             selectedDate = new Date();
         }

         if (!isAllExist()){
             setSelectedCalendarDate(selectedDate);
             setShowPopup(true);
            return;
        }else{
             setSelectedCalendarDate(selectedDate);
             findDoctorScheduler(selectedDate);
        }
     }

     const getDate=()=>{
         let day = selectedCalendarDate.getDate();
         let month = selectedCalendarDate.getMonth() + 1;
         let year = selectedCalendarDate.getFullYear();

         return day + "/" + month + "/" + year;
     }

     const findDoctorScheduler = selectedDate =>{
         let day = selectedDate.getDate();
         let month = selectedDate.getMonth() + 1;
         let year = selectedDate.getFullYear();

         let request={
             "doctorId": user.doctorId,
             "year": year,
             "month": month,
             "day": day
         }

         doctorCalendarService.getDoctorAvailableHours(request).then(async res=>{
             selectedYearMonthMap.clear();
             await setSelectedYearMonthMap(selectedYearMonthMap);

             if (res && res.success && res.object.length > 0){
                 let matchesHours = [];
                 let result=[];

                 res.object.map(doctorCalendar => {
                     let doctorCalendarSplit = doctorCalendar.hour.split(':');
                     let splittedHour='';
                     if (doctorCalendarSplit.length>0){
                         splittedHour = doctorCalendarSplit[0] + ':' + doctorCalendarSplit[1];
                     }
                     let filterResult = HOURS.filter(hour => hour == splittedHour);
                     if (filterResult.length>0){
                         doctorCalendar.selected = true;
                         doctorCalendar.isExist = true;
                         doctorCalendar.unSelected = false;

                         addYearMonthMap(doctorCalendar,splittedHour);

                         matchesHours.push(filterResult[0]);
                     }else{
                         doctorCalendar.selected = false;
                         doctorCalendar.unSelected = true;
                         doctorCalendar.isExist = false;
                     }
                     doctorCalendar.hour = splittedHour;
                     result.push({isExist:doctorCalendar.isExist,year:doctorCalendar.year,month:doctorCalendar.month,day:doctorCalendar.day,hour:doctorCalendar.hour,selected:doctorCalendar.selected,unSelected:doctorCalendar.unSelected,id: doctorCalendar.id});
                 });

                 let notMatchHour = getDifference(HOURS,matchesHours);
                 notMatchHour.map(e=>{
                     result.push({year,month,day,hour:e,unSelected:true,selected:false});
                 });

                 var collator = new Intl.Collator([], {numeric: true});
                 setDoctorCalendar(result.sort((a, b) => collator.compare(a.hour, b.hour)));
             }else{
                 let result = [];
                 HOURS.map(e=>{
                     result.push({year,month,day,hour:e,unSelected:true,selected:false,isExist:false});
                 })
                 setDoctorCalendar(result);
             }
         })
     }

     function getDifference(a, b) {
         return a.filter(element => {
             return !b.includes(element);
         });
     }


     const onHourSelect=(year,month,day,hour)=>{
         doctorCalendar.forEach(dc=>{
             if (dc.year == year && dc.month == month && dc.day == day && dc.hour == hour){
                 if (dc.selected){
                     dc.unSelected = true;
                     dc.selected = false;
                     deleteFromYearMonthMap(dc,hour);
                 }else{
                     dc.unSelected = false;
                     dc.selected = true;
                     addYearMonthMap(dc,hour);
                 }
             }
         })

         setDoctorCalendar([...doctorCalendar]);
     }

     const isAllExist=()=>{
         const existArr = doctorCalendar.filter(e=> e.isExist == true);
         let selectedCount = 0;

         existArr.forEach(dc=>{
             const yearMonth = dc.year + "#" + dc.month + "#" + dc.day;
             let arr = selectedYearMonthMap.get(yearMonth);
             if (arr && arr.length > 0 && arr.find(e=> e == dc.hour) && dc.selected){
                 selectedCount++
             }
         })

         const yearMonth = selectedCalendarDate.getFullYear() + "#" + (selectedCalendarDate.getMonth() + 1) + "#" + selectedCalendarDate.getDate();

         const selectedArr = selectedYearMonthMap.get(yearMonth);

         if ((!selectedArr && existArr.length==0)){
             return true;
         }

         if (selectedArr && selectedArr.length > selectedCount){
             return false;
         }

         if (existArr && existArr.length>0 && selectedCount == existArr.length){
             return true;
         }
         return false;
     }

     const save=(validate)=>{
         if (validate){
             if (isAllExist()){
                 swal("Uyarı", "Lütfen önce saat seçiniz.", "warning")
                 return;
             }
         }

         const selectedDateKey = selectedYearMonthMap.keys().next().value;
         const request = {
             doctorId : user.doctorId,
             date: selectedDateKey,
             hours : selectedYearMonthMap.get(selectedDateKey)
         }

         setSubmitting(true);

         doctorCalendarService.save(request).then(res=>{
             if (res && res.success && res.message){
                 selectedYearMonthMap.clear();
                 setSelectedYearMonthMap(selectedYearMonthMap);
                 findDoctorScheduler(selectedCalendarDate);
                 swal("Başarılı", res.message, "success")
                 setSubmitting(false);
             }else{
                 swal("Uyarı", res.message, "warning");
                 setSubmitting(false);
             }
         }).catch(e=>{
             console.log(e);
             setSubmitting(false);
         })
     }

     const accept = () => {
         save(false);
     }

     const reject = () => {
        selectedYearMonthMap.clear();
        findDoctorScheduler(selectedCalendarDate);
     }

     const renderPopup=()=>{
         return(
             <ConfirmDialog rejectLabel={"Kaydetmeden devam et"} acceptLabel={"Kaydet ve devam et"} visible={showPopup} onHide={() => setShowPopup(false)} message="Lütfen seçilen gün için seçtiğiniz saatleri kayderek devam edin. Kaydetmeden devam ederseniz seçtiğiniz saatler kaydolmayacaktır."
                            header={<div>

                            </div>} icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />
         )
     }

    const getDateFromDoctorCalendar=(dc)=>{
        const parseArr = dc.hour.split(':');
        return new Date(dc.year, dc.month -1 , dc.day, parseArr[0], parseArr[1])
    }

     return(
        <div className='col-md-8 pe-2' style={{width:'100%'}}>
            <Calendar minDate={today} maxDate={new Date(new Date().setDate(today.getDate() + 14))} value={selectedCalendarDate}
                      className='doctor-calendar' inline locale={'tr'}
                      onChange={onChangeDate}
            />

            <Card title="Lütfen aşağıdan uygun çalışma saat/saatlerinizi seçin" style={{ fontSize:'inherit', width: '100%', marginBottom: '2em',marginTop:'1%' }}>
                <div style={{display:'flex',justifyContent:'flex-end'}}>
                    <span style={{padding:'5px'}}> <i className="pi pi-calendar"></i> {getDate()}</span>
                    <span style={{padding:'5px'}}> <i className="pi pi-stop" style = {{color:"lightseagreen",backgroundColor:'lightseagreen'}}></i> Seçilebilir</span>
                    <span style={{padding:'5px'}}> <i className="pi pi-stop" style = {{color:"orange",backgroundColor:'orange'}}></i> Seçili</span>
                </div>
                <div className="hour-area">
                    {doctorCalendar.map((doctorCalendar,index)=>{
                        let returnObj = isBefore(getDateFromDoctorCalendar(doctorCalendar),new Date()) ?
                            <div className= "hour-item-disabled">
                                <span>{doctorCalendar.hour}</span>
                            </div>
                            :<div onClick={()=>onHourSelect(doctorCalendar.year,doctorCalendar.month,doctorCalendar.day,doctorCalendar.hour)} className= {doctorCalendar.selected ? "hour-item-selected" : "hour-item"} key={"itemdisabled"+index}>
                                <span>{doctorCalendar.hour}</span>
                            </div>;

                        return returnObj;
                    })}
                </div>
            </Card>
            <div style={{display:"flex",justifyContent:"flex-end",marginBottom:'1em'}}>
                <PrimeButton
                    disabled={submitting}
                    label="Kaydet"
                    onClick={()=>save(true)}
                />
            </div>
            {renderPopup()}
        </div>
    )
}

export default SeansEdit;
