import React from 'react'
import { BsShieldCheck, BsShieldExclamation } from "react-icons/bs";
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import "./sidebar.css";
import {findFlag} from "../../util/util";
import {SocialIcon} from "react-social-icons";
import {Divider} from "primereact/divider";

export const Sidebar = ({ data }) => {
  const location = useLocation();

  const contactInfoIcon = (isActive) => {
    let isActivateIcon = <BsShieldExclamation size={24} color={"#dc3545"}/>;

    if (isActive) {
      isActivateIcon = <BsShieldCheck size={24} color={"#198754"}/>;
    }

    return(
      <>
        { isActivateIcon }
      </>
    )
  };

  const doctorDediInfo = {
    email: 'info@doctordedi.com',
    phoneNumber: '533 404 86 01',
  };

  const isDoctorUser = () => {
    return data.userType === 'doctor';
  };

  const fullName = () => {
    let name = `${data.name} ${data.surname}`;

    if (isDoctorUser()) {
      name = `${data.unvan} ${data.name} ${data.surname}`;
    }

    return name;
  };

  const userContactInfo = () => {
    return (
      <>
        <div className='row fw-bold mt-1 mjustify-center'>
          E-Posta:
        </div>

        <div className='row align-items-center mjustify-center'>
          <div className='col-auto me-1'>
            { data.email }
          </div>

          <div className='col-auto'>
            { contactInfoIcon(data.confirmed) }
          </div>
        </div>

        <div className='row fw-bold mt-1 mjustify-center'>
          Telefon:
        </div>

        <div className='row align-items-center mjustify-center'>
          <div className='col-auto me-1'>
            { data.phoneNumber }
          </div>

          <div className='col-auto'>
            { contactInfoIcon(data.confirmed) }
          </div>
            <Divider align="center">
            </Divider>
        </div>
      </>
    )
  };

  const professionArea=()=>{
      if (data.profession){
          let profession = data.profession;

          let arr = profession.split('#');

          if (arr.length>0){
              return (
                  arr.map(el=>{
                      return <span className="mtext-center"><i className="fa-solid fa-arrow-right"></i>{el}</span>
                  })
              )
          }
      }
  }

  const doctorContactInfo = () => {
    return (
      <>
        <div className='row align-items-center mjustify-center'>
          <div className='col-auto fw-bold me-1' style={{ color: '#198754' }}>
            Onaylandı
          </div>
            <div className='col-auto'>
            { contactInfoIcon(data.approved) }
          </div>
        </div>
          <div className='row align-items-center mjustify-center'>
              { <span className="mtext-center" style={{fontSize:'1.2em'}}> { data.tibbiBirim.birimAdi } </span>}
          </div>
          <div style={{fontStyle:"italic",fontSize:'0.8em'}} className='row align-items-center'>
              {professionArea()}
          </div>
        <div className='row align-items-center mjustify-center'>
           { languageInfo() }
        </div>

        <div className='row fw-bold mt-1 mjustify-center'>
          E-Posta:
        </div>

        <div className='row align-items-center mjustify-center'>
          <div className='col-auto me-1'>
            { doctorDediInfo.email }
          </div>
        </div>

        <div className='row fw-bold mt-1 mjustify-center'>
          Telefon:
        </div>

        <div className='row align-items-center mjustify-center'>
          <div className='col-auto me-1'>
            { doctorDediInfo.phoneNumber }
          </div>
        </div>

          <Divider align="center">
          </Divider>
          <div className='row align-items-center'>
              { socialMediaInfo() }
          </div>
      </>
    )
  };

  const contactInfoSection = () => {
    if (isDoctorUser()) {
      return (<>{ doctorContactInfo() }</>)
    } else {
      return (<>{ userContactInfo() }</>)
    }
  };

    const languageInfo = () => {
        return (
            <>
                <div className='row align-items-center'>
                    <div style={{display:"flex"}} className="mjustify-center">
                        { data&&data.languages && data.languages.length>0 && data.languages.map(language=>{
                            return (
                                <span style={{padding:'1%'}}>{findFlag(language.name)}</span>
                            )
                        }) }
                    </div>
                </div>
            </>
        )
    };

    const socialMediaInfo =()=>{
        return (
            <>
                <div className='row align-items-center'>
                    <div style={{display:"flex"}} className="mjustify-center">
                        {data.instagram &&<SocialIcon style={{margin:'1%',width:'32px',height:'32px'}} url={data.instagram} />}
                        {data.facebook &&<SocialIcon style={{margin:'1%',width:'32px',height:'32px'}} url={data.facebook} />}
                        {data.twitter &&<SocialIcon style={{margin:'1%',width:'32px',height:'32px'}} url={data.twitter} />}
                        {data.youtube &&<SocialIcon style={{margin:'1%',width:'32px',height:'32px'}} url={data.youtube} />}
                    </div>
                </div>
            </>
        )
    }

  return (
    <>
      <div className='row g-0 p-2 sidebar'>
        <div className='col-auto'>
          <div className='row justify-content-center'>
            <div className='col-auto'>
              <img
                src={data.profilePhotoUrl || "../../assets/img/2.1.png"}
                alt="profile"
                className="profile-photo-sidebar"
              />
            </div>
          </div>

          {
            location.pathname.includes('/profile') ?
              <div className='row justify-content-center mt-2'>
                <div className='col-auto'>
                  <Link to={`/profile`} state={{ isEdit: true }} className="dropdown-item">
                    <Button
                      variant="outline-primary"
                      className='d-btn'
                      size="sm"
                    >
                      Profili Düzenle
                    </Button>
                  </Link>
                </div>
              </div>

            :
              <></>
          }

          <div className='row fw-bold mt-2 mjustify-center'>
            { fullName() }
          </div>

          { contactInfoSection() }
        </div>
      </div>
    </>
  )
}
