import {APPOINTMENT_CONSTANT, APPOINTMENT_TYPE} from "../const";

const INITIAL_STATE={
    branch : {
        id: null,
        birimAdi:''
    },
    doctor : {
        id: null,
        name:'',
        surname:''
    },
    selectedDate : null,
    hour: '',
    price:'',
    exchangePrice: '',
    doctorCalendarId:'',
    activeStep:0,
    id:'',
    isNewAppointment: false,
    isPaymentFail: false,
    isPaymentStep: false,
    appointmentType: APPOINTMENT_TYPE.NORMAL
}

export const appointment = (state = INITIAL_STATE,action) => {
    switch (action.type) {
        case  APPOINTMENT_CONSTANT.ADD_BRANCH :
            return {...state,branch: action.payload.branch}
        case  APPOINTMENT_CONSTANT.ADD_DOCTOR :
            return {...state,doctor: action.payload.doctor}
        case  APPOINTMENT_CONSTANT.ADD_DOCTOR_CALENDAR_ID :
            return {...state,doctorCalendarId: action.payload.doctorCalendarId}
        case  APPOINTMENT_CONSTANT.ADD_APPOINTMENT_ID :
            return {...state,id: action.payload.appointmentId}
        case  APPOINTMENT_CONSTANT.ADD_DATE :
            return {...state,selectedDate: action.payload.selectedDate}
        case  APPOINTMENT_CONSTANT.ADD_HOUR :
            return {...state,hour: action.payload.hour}
        case  APPOINTMENT_CONSTANT.ADD_PRICE :
            return {...state,price: action.payload.price}
        case  APPOINTMENT_CONSTANT.ADD_EXCHANGE_PRICE :
            return {...state,exchangePrice: action.payload.exchangePrice}
        case  APPOINTMENT_CONSTANT.SET_ACTIVE_STEP :
            return {...state,activeStep: action.payload.activeStep}
        case  APPOINTMENT_CONSTANT.SET_IS_NEW_APPOINTMENT :
            return {...state,isNewAppointment: action.payload.isNewAppointment}
        case  APPOINTMENT_CONSTANT.SET_IS_PAYMENT_STEP :
            return {...state,isPaymentStep: action.payload.isPaymentStep }
        case  APPOINTMENT_CONSTANT.SET_IS_PAYMENT_FAIL :
            return {...state,isPaymentFail: action.payload.isPaymentFail }
        case  APPOINTMENT_CONSTANT.SET_APPOINTMENT_TYPE :
            return {...state,appointmentType: action.payload.appointmentType }
        case  APPOINTMENT_CONSTANT.CLEAR_STATE :
            return INITIAL_STATE;
        default :
            return state;
    }
}
