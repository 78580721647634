import React from "react";
import "./EmergencyDoctor.css";
import acildoktor from "../../assets/img/acil-button.png";
import whatsapp from "../../assets/img/whatsapp-btn.png";
import {Link, useLocation} from "react-router-dom";

const EmergencyDoctor = () => {

    const location = useLocation();

    const isMobile = location.pathname.includes('mobile-payment')||location.pathname.includes('mobile-meet');

  return (
      !isMobile ?
    <div>
      <div className="emergency-doctor img-fluid">
          <Link to="/emergencydoctor">
        <img src={acildoktor} alt="acil" />
      </Link>
      </div>
        <a href="https://wa.me/5334048601">
      <div className="whatsapp"   >
        <img src={whatsapp} alt="wp" />
      </div>
        </a>
        <div className="mobile-bottom">
            <div className="noLogin">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="emergency-doctor-btn">
                                <Link to="/emergencydoctor">
                                <button><p>Acil Doktor!</p></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> : null
  );
};

export default EmergencyDoctor;
