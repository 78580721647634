import React, {useEffect} from 'react';
import {useSearchParams} from "react-router-dom";


const MobilePaymentFail=()=>{

    const [searchParams, setSearchParams] = useSearchParams("UTF-8");

    useEffect(()=>{
        if(searchParams.get('message')){
            window.ReactNativeWebView.postMessage(JSON.stringify({success:true,message: searchParams.get('message')}));
        }
    },[searchParams])

    return(
        <></>
    )
}

export default MobilePaymentFail;
