import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import DoctorServiceWithoutToken from "../../service/doctor/doctor.service.without.token";
import DoctorBox from "../appointment/DoctorBox";
import {useNavigate} from "react-router-dom";
import {ProgressSpinner} from "primereact/progressspinner";
import {getIp} from "../../util/util";
import {AUTH_CONSTANT} from "../../redux/const";
import {useDispatch} from "react-redux";

const EmergencyDoctorSelect = () => {
    const [showEmergencyDoctorDialog,setShowEmergencyDoctorDialog] = useState(false);
    const [loading,setLoading] = useState(false);
    const [emergencyDoctorList,setEmergencyDoctorList] = useState([]);
    const doctorService = new DoctorServiceWithoutToken();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        setShowEmergencyDoctorDialog(true);
        setLoading(true);
        doctorService.emergencyDoctorList().then(async res=>{
            if(res&&res.success&&res.object.length > 0){
                setEmergencyDoctorList(res.object);
                let ipRes = await getIp();
                dispatch({type : AUTH_CONSTANT.SET_LOCALE,payload : {locale: {currency: ipRes.currency, ip: ipRes.ip,lang:ipRes.lang}}});

                setLoading(false);
            }
        }).catch(e => {
            setLoading(false);
        })
    },[]);

    const renderLoading=()=>{
        return(
            <div className="container flex-div">
                <ProgressSpinner style={{width: '50px', height: '50px'}} />
            </div>
        )
    }

    const renderEmergencyDoctorDialog=()=>{
        return(
            <Dialog header="" style={{boxShadow: 'rgba(0, 0, 0, 1) 2px 5px 15px'}}
                    className="appointment-dialog"
                    visible={showEmergencyDoctorDialog} position="top"
                    onHide={() => {
                        setShowEmergencyDoctorDialog(false);
                        navigate('/');
                    }}>
                <div className="container flex-div">
                    <div style={{width:'100%',display:"flex",justifyContent:'center',borderBottom:'1px solid #0885bf'}}>
                        <span style={{fontSize:'5vw',color:'red'}} className="text-center faq-title" >
                            Acil Doktorlar
                        </span>
                    </div>
                    {loading && renderLoading()}
                    {emergencyDoctorList.length > 0 && emergencyDoctorList.map((emergencyDoctor,index)=>{
                        return(
                            <DoctorBox
                                key={index}
                                showBranchInfo={true}
                                showPaymentButton={true}
                                doctor={emergencyDoctor}/>
                        )
                    })}
                </div>
            </Dialog>
        )
    }

    return(
        <>
            {showEmergencyDoctorDialog&&renderEmergencyDoctorDialog()}
        </>
)
}

export default EmergencyDoctorSelect;
