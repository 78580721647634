import { onShowSnackBar } from "../../core/SnackBar/SnackBarProvider";
import AuthService from "../../service/rest/auth.service";
import {AUTH_CONSTANT} from "../const";
import {getIp} from "../../util/util";

const authService = new AuthService();

export const signIn =  async(data,navigate,location,dispatch)  => {

    dispatch({type : AUTH_CONSTANT.SET_LOGIN_LOADING,payload : {loginLoading: true}});

        try {
           const res = await authService.signIn(data);
           let origin ='';

        if (res){
            if (res.success === false && res.responseCode === 1){
                dispatch({type : AUTH_CONSTANT.SIGN_IN,payload : res.object});
                dispatch(setPhone(res.object.phone));
                origin = location.state? location.state.from ? location.state.from.pathname : '/smscodechecker' : '/smscodechecker' ;
            }else if (res.success === false && res.message){
                dispatch({type : AUTH_CONSTANT.LOGIN_ERR,payload : {err: res.message}});
                dispatch({type : AUTH_CONSTANT.SET_LOGIN_LOADING,payload : {loginLoading: false}});
                return;
            }else if(res.accessToken){
                if (res.userRoles[0].name === 'ROLE_DOCTOR'){
                    if (!res.passwordChanged){
                        dispatch({type : AUTH_CONSTANT.SIGN_IN,payload : res});
                        origin = '/changepassword';
                    }else if (!res.kvkkAggrementApproved || !res.privacyAggrementApproved){
                        dispatch({type : AUTH_CONSTANT.SIGN_IN,payload : res});
                        dispatch(setKVKKAgrrement(res.kvkkAggrementApproved))
                        dispatch(setPrivacyAgrrement(res.privacyAggrementApproved))
                        origin = '/approveaggrements';
                    }else{
                        dispatch({type : AUTH_CONSTANT.SIGN_IN,payload : res});
                        origin = '/profile';
                        dispatch(setRequestUser({}));
                    }
                }else{
                    dispatch({type : AUTH_CONSTANT.SIGN_IN,payload : res});
                    origin = '/profile';
                    dispatch(setRequestUser({}));
                }
            }

            let ipRes = await getIp();
            dispatch({type : AUTH_CONSTANT.SET_LOCALE,payload : {locale: {currency: ipRes.currency, ip: ipRes.ip,lang:ipRes.lang}}});

            dispatch({type : AUTH_CONSTANT.SET_LOGIN_LOADING,payload : {loginLoading: false}});
            if (origin){
                navigate(origin,{ replace: true });
            }
        }else{
            dispatch({type : AUTH_CONSTANT.SET_LOGIN_LOADING,payload : {loginLoading: false}});
        }
        } catch (error) {
            dispatch({type : AUTH_CONSTANT.SET_LOGIN_LOADING,payload : {loginLoading: false}});
            onShowSnackBar(error);
        }



}

export const logout = () => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.LOG_OUT ,payload : null});
}

export const setError = (err) => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.LOGIN_ERR ,payload : {err:err}});
}

export const setRequestUser = (requestUser) => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.SET_REQUEST_USER ,payload : {requestUser:requestUser}});
}

export const setPhone = (phone) => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.SET_PHONE ,payload : {phone}});
}

export const clearError = () => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.LOGIN_ERR , payload : null});
}

export const setLocale = (locale) => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.SET_LOCALE , payload : {locale:locale}});
}

export const setPasswordChanged = (passwordChanged) => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.SET_PASSWORD_CHANGED , payload : {passwordChanged:passwordChanged}});
}

export const setIsMobilePayment = (isMobilePayment) => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.SET_IS_MOBILE_PAYMENT,payload : {isMobilePayment}});
}

export const setKVKKAgrrement = (kvkkAggrementApproved) => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.SET_KVKK_AGGREMENT,payload : {kvkkAggrementApproved}});
}

export const setPrivacyAgrrement = (privacyAggrementApproved) => (dispatch) => {
    dispatch({type : AUTH_CONSTANT.SET_PRIVACY_AGGREMENT,payload : {privacyAggrementApproved}});
}
