import React, { useState, useEffect }  from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar/Sidebar'
import { Button } from 'react-bootstrap';
import { BsFillCalendarDateFill, BsFillPatchQuestionFill } from "react-icons/bs";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { TabView, TabPanel } from 'primereact/tabview';
import { Galleria } from 'primereact/galleria';
import { ProgressSpinner } from 'primereact/progressspinner';
import tr from '../../locale/tr';
import "./doctorProfile.css";
import DoctorServiceWithoutToken from "../../service/doctor/doctor.service.without.token";
import {setIsNewAppointment} from "../../redux/actions/appointment.actions";
import CommentServiceWithoutToken from "../../service/comment/comment.service.without.token";
import {Rating} from "@mui/material";
import {format} from "date-fns";


export const DoctorProfile = () => {
  addLocale('tr', tr);
  const user = useSelector(state => state.auth.user);
  const locale = useSelector(state => state.auth.locale);
  const doctorService = new DoctorServiceWithoutToken();
  const commentService = new CommentServiceWithoutToken();
  const [selectedTab, setSelectedTab] = useState(0);
  const [pictureUrlList, setPictureUrlList] = useState([]);
  const [videoUrlList, setVideoUrlList] = useState([]);
  const [patientReviews, setPatientReviews] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);
  const [viewDate, setViewDate] = useState(new Date());
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [doctorData, setDoctorData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();

  const getDoctorProfileData = () => {
    setIsLoading(true);

    doctorService.getDoctorById(params.doctorId)
      .then(({ data }) => {
        if (data.success) {
          setDoctorData({ userType: 'doctor', ...data.object, languages : JSON.parse(data.object.languages)});

          doctorService.getDoctorGalleryById(params.doctorId)
            .then((res) => {
                if(res&&res.data&&res.data.object){
                    if (res.data.object.pictureList){
                        let imageUrls = [];
                        res.data.object.pictureList.map((image,index)=>{
                            imageUrls.push({id:image.id,image:image.url})
                        })
                        setPictureUrlList(imageUrls);
                    }

                    if (res.data.object.videoList){
                        setVideoUrlList(res.data.object.videoList);
                    }
                }
            })

            commentService.getCommentListById(params.doctorId).then(res=>{
                let commentData = [];
                if(res&&res.object){
                    res.object.map((comment,index)=>{
                        commentData.push({ id: index, comment: comment.comment, date: new Date(comment.commentDate), rating : comment.rating })
                    })
                    setPatientReviews(commentData);
                }
            })
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDoctorProfileData();
  }, [params]);

  const itemTemplate = (item) => {
    return (
      <img
        src={item.image}
        className='doctor-gallery-media'
        alt='doctor_media'
      />
    )
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.image}
        className='doctor-gallery-media-thumbnail'
        alt='doctor_media'
      />
    )
  };

  const dateTemplate = (date) => {
    if (availableDays.filter(e => e.day === date.day && e.month === date.month + 1 && e.year === date.year).length > 0 && compareDates(date)) {
        return (
            <div className='doctor-calendar-available-day fw-bold'>
              <div className='row'>
                {/* <BsPersonCircle size={18}/> */}
              </div>

              <div className='row'>
                {date.day}
              </div>
            </div>
        );
    }
    else {
        return date.day;
    }
  };

  const goUserPage=()=>{
    dispatch(setIsNewAppointment(true));

    if(user.accessToken){
      navigate('/profile');
    }else{
      navigate('/login');
    }
  }

  const doctorPrice=(doctorData)=>{
      return (
          <div className='row justify-content-center fw-bold'>
              <div className='col-md-auto doctor-price-label'>
                  { locale ? locale.currency === 'TL' ?  doctorData.perPriceWithTl : doctorData.perPriceWithEuro : doctorData.perPriceWithTl} {locale&&locale.currency}
              </div>
          </div>
      )
  }

  const doctorQuestionPrice = (doctorData)=>{
      return (
          <div className='row justify-content-center fw-bold'>
              <div className='col-md-auto doctor-price-label'>
                  { locale ? locale.currency === 'TL' ?  doctorData.quePriceWithTl : doctorData.quePriceWithEuro : doctorData.quePriceWithTl} {locale&&locale.currency}
              </div>
          </div>
      )
  }

  useEffect(() => {
      if (viewDate){
          let now = new Date(viewDate);
          let year = now.getFullYear();
          let month = now.getMonth() + 1;

          let request={
              "doctorId": params.doctorId,
              "year": year,
              "month": month
          }

          doctorService.getDoctorCalendarList(request).then(res=>{
              if (res&&res.object){
                  setAvailableDays(res.object);
              }
          })
      }

  },[viewDate]);

    const compareDates=(date)=>{
        let dateIsLaterFromToday=false;

        let todayMonth = today.getMonth() + 1;
        let todayYear =  today.getFullYear();

        if (date.year > todayYear){
            dateIsLaterFromToday = true;
        }else if ((date.year == todayYear) && (date.month +1 > todayMonth)){
            dateIsLaterFromToday = true;
        }else if((date.month +1 == todayMonth) && (date.day >= today.getDate())){
            dateIsLaterFromToday = true;
        }

        return dateIsLaterFromToday;
    }

  const checkDisabled=()=>{
      let disabled=true;

      availableDays.forEach(day => {
          disabled = !compareDates(day);
      });

      return disabled;
  }

  return (
    <>
      <div className='row g-0 justify-content-center'>
        <div className='col-md-2'>
          {
            isLoading ?
            <ProgressSpinner/>
            :
            <Sidebar data={doctorData}/>
          }
        </div>

        <div className='col-md-6 content-section'>
          {
            isLoading ?
            <ProgressSpinner/>
            :
            <>
              <div className='row p-3'>
                <div className='col-md-8 pe-2'>
                  <Calendar minDate={today} maxDate={new Date(new Date().setDate(today.getDate() + 30))} className='doctor-calendar' inline locale={'tr'} dateTemplate={dateTemplate}
                            viewDate={viewDate}
                            onViewDateChange={(e) => {
                                setViewDate(e.value);
                            }}/>
                </div>

                <div className='col-md-4 price-column'>
                  <div className='row align-items-center justify-content-center price-wrapper'>
                    <div className='col-md-auto p-3'>
                      <BsFillCalendarDateFill size={44} color='#0785c7'/>
                    </div>

                    <div className='col-md-auto'>
                      <div className='row fw-bold'>
                        Seans Ücreti
                      </div>
                      {doctorPrice(doctorData)}
                    </div>
                  </div>

                  <div className='row align-items-center justify-content-center price-wrapper'>
                    <div className='col-md-auto p-3'>
                      <BsFillPatchQuestionFill size={44} color='#0785c7'/>
                    </div>

                    <div className='col-md-auto'>
                      <div className='row fw-bold'>
                        Soru Ücreti
                      </div>

                      {doctorQuestionPrice(doctorData)}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row justify-content-center px-3'>
                <div className='col-md-auto'>
                  <Button
                    disabled={checkDisabled()}
                    variant="outline-primary"
                    className='d-btn'
                    onClick={goUserPage}
                  >
                    Yeni Randevu Oluştur
                  </Button>
                </div>
              </div>

              <div className='row p-3'>
                <TabView activeIndex={selectedTab} onTabChange={(e) => setSelectedTab(e.index)} color="#0785c7">
                    <TabPanel header="Galeri">
                        <div className='row'>
                            <div className='col-md'>
                                {pictureUrlList.length >0 ? <Galleria value={pictureUrlList} item={itemTemplate} thumbnail={thumbnailTemplate}></Galleria> : 'Henüz fotoğraf yüklenmemiş.'}
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel header="Biyografi">
                      <div className='col-md-auto'>
                        { doctorData.aciklama || 'Henüz biyografi girilmemiş.' }
                      </div>
                    </TabPanel>

                    <TabPanel header="Hasta Görüşleri">
                      {
                          patientReviews.length > 0 ? patientReviews.map((review) => (
                          <div className='row patient-review' key={review.id}>
                            <div className='col-md-12'>
                              <div className='row'>
                                <div className='col-md-4 fw-bold'>
                                   {review.comment}
                                </div>

                                <div className='col-md-4'>
                                  {format(new Date(review.date.toString()), 'dd/MM/yyyy HH.SS')}
                                </div>

                                  <div className='col-md-4 fw-bold'>
                                      <div className="rating-container">
                                          <Rating
                                              readOnly
                                              value={review.rating}
                                          />
                                      </div>
                                  </div>
                              </div>

                            </div>
                          </div>
                        )) : <span> Henüz görüş girilmemiş. </span>
                      }
                    </TabPanel>

                    <TabPanel header="Video">
                        <div className='row'>
                          <div className='col-md-12'>
                              {videoUrlList.length >0 ?
                                   videoUrlList.map(videoUrl=>{
                                       return(
                                           <iframe title='doctor-video' src={videoUrl.url} className='doctor-video'/>
                                       )
                                   }) :
                                  'Henüz video yüklenmemiş.'}
                          </div>
                        </div>
                    </TabPanel>
                </TabView>
              </div>
            </>
          }
        </div>
      </div>
    </>
  )
}
