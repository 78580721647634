import BaseService from "../base/base.service";

export default class ConsultServiceWithoutToken extends BaseService {

    constructor(token) {
        super(token);
        this.endpoint = '/web/consult';
    }

    find = (word) => {
        return this.httpClient.get(this.endpoint + "/find", {params : {word : word} , headers : this.headersWithParams}).then(res => {
            return res.data;
        })
    }

}
