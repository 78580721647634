import React, {useEffect} from 'react';
import {Card} from "primereact/card";
import AuthService from "../service/rest/auth.service";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {setKVKKAgrrement, setPrivacyAgrrement} from "../redux/actions/auth.action";
import {useNavigate} from "react-router-dom";

function  ApproveAggrements(){

    const user = useSelector(state=> state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authService = new AuthService(user.accessToken);


    const approve = () => {
        authService.approveAggrements({
            userId:user.userId,
            kvkkAggrementApproved: true,
            privacyAggrementApproved: true
        }).then(res=>{
            if(res&&res.success){
                dispatch(setKVKKAgrrement(true));
                dispatch(setPrivacyAgrrement(true));
            }
        })
    }

    const renderAggrementKVKK=()=>{
        return(
            <div>
                <iframe src="./documents/KVKK Sözleşmesi.pdf" style={{height:'800px'}} width="100%"/>
            </div>
        )
    }

    const renderAggrementPrivacy=()=>{
        return(
            <div>
             <iframe src="./documents/Gizlilik Sözleşmesi.pdf" style={{height:'800px'}} width="100%"/>
            </div>
        )
    }

    useEffect(()=>{
        if (user.kvkkAggrementApproved&&user.privacyAggrementApproved){
            navigate('/profile',{ replace: true });
        }
    },[user.kvkkAggrementApproved,user.privacyAggrementApproved])

    return(
            <Card style={{marginBottom: '2em',marginTop:'2em',padding:'1em' }}>
                    <div style={{lineHeight:3,textDecoration:"underline",color:'#0885bf',fontWeight:"bold",fontSize:'1.2rem'}}>
                        <span>Lütfen sayfanın en altında yer alan onayla butonu yardımıyla sözleşmeleri onayladığınızı belirtiniz.</span>
                    </div>
                        {renderAggrementKVKK()}
                        {renderAggrementPrivacy()}
                    <div style={{display:"flex",height:'9%',justifyContent:"center",marginTop:'1%'}}>
                        <Button label="Okudum, onaylıyorum" onClick={()=> {approve()}} className="p-button-outlined p-button-primary" />
                    </div>
            </Card>
    )
}

export default ApproveAggrements;
