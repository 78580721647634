
import axios, {AxiosInstance} from "axios"



export default class AxiosConfigurationService {

  constructor(token){
    let BASE_URL = "https://ec2-18-157-160-59.eu-central-1.compute.amazonaws.com/api";
    this.httpClient  = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    });

    const errorHandler = ApiStatusServiceInstance.getErrorHandler();
    const progressHandler = ApiStatusServiceInstance.getProgressHandler();

    const apiConfig = {
      progressCallback: progressHandler,
      errorHandler: data => {

        let msg = data.message && data.message.length ? data.message : (data.error && data.error.length ? data.error : null)
        // let msgEN= 'Username or Password Incorrect!';
        // let msgTR='Kullanıcı Adı veya Şifre Hatalı!';
        // if(msg === 'Bad credentials' ){
        //   if (i18next.language=='en') {
        //     errorHandler(msgEN)
        //   } else {
        //     errorHandler(msgTR)
        //     //console.error(data)
        //   }
        // }
          errorHandler(msg);
      }
    };

    // Mock Authentication
    if(token){
      this.httpClient.interceptors.request.use(config => {
        // let accessToken = window.sessionStorage.getItem('jwt_access_token');
        config.headers = {
          ...config.headers,
          "Authorization": `Bearer ${token}`
        }

        localStorage.setItem("sessionTimeOut",new Date());

        return config
     })
    }


    applyInterceptors(this.httpClient , apiConfig)

  }


}
 class mcallbacks {
  progress= new Map()
  error= new Map()
}
export class ApiStatusService {

  callbacks=new mcallbacks()

  onProgress(key, callback) {
    let callbacks = this.callbacks.progress;
    if (!callbacks.has(key)) {
      callbacks.set(key, callback);
      return true
    } else {
      return false
    }
  }

  onError(key, callback){
    let callbacks = this.callbacks.error;
    if (!callbacks.has(key)) {
      callbacks.set(key, callback);
      return true
    } else {
      return false
    }
  }

  getProgressHandler(){
    const callbacks = this.callbacks.progress;
    return (status) => {
      callbacks.forEach(c => {
        c(status)
      })
    }
  }

  getErrorHandler() {
    const callbacks = this.callbacks.error;
    return (error) => {
      callbacks.forEach(c => {
        c(error)
      })
    }
  }
}

export const ApiStatusServiceInstance = new ApiStatusService();


/**
 * @param axios:AxiosInstance
 * @param config:ApiConfig
 * @returns {*}
 */
export function applyInterceptors(axios, config) {
  const interceptors = axios.interceptors;
  //const {t}=useTranslation('LoginPage')
  const inProgress = typeof config.progressCallback === 'function' ? config.progressCallback : (() => void 0);
  const errorHandler = typeof config.errorHandler === 'function' ? config.errorHandler : (error) => alert(JSON.stringify(error));
  const oauth = config.oauth || {} ;

  interceptors.request.use(
    requestSuccessInterceptor(inProgress, oauth),
    requestErrorInterceptor(inProgress)
  );

  interceptors.response.use(
    responseSuccessInterceptor(inProgress),
    responseErrorInterceptor(inProgress, oauth, errorHandler)
  );

  return axios
}
const requestSuccessInterceptor=(inProgress, oauth) =>  {
  return config => {
    inProgress(true);
    if (oauth && typeof oauth.getAccessToken === 'function') {
      return new Promise((resolve, reject) => {
        oauth.getAccessToken()
          .then(at => {
            config.headers.Authorization = 'Bearer ' + at;
            resolve(config)
          })
          .catch(() => {
            reject('OAuth: Access Token Error')
          })
      })
    } else {
      return config
    }
  }
}

const requestErrorInterceptor=(inProgress)=> {
  return error => {
    inProgress(false);
    return error
  }
}

const responseSuccessInterceptor=(inProgress)=> {
  return response => {
    inProgress(false);
    if (response.config.responseType === 'blob' && response.config.filename) {
      //saveAs(response.data, response.config.filename)
    }
    return response
  }
}

const responseErrorInterceptor=(inProgress, oauth, errorHandler) =>  {
  return error => {
    return Promise.reject(error).finally(() => {
      inProgress(false);
      const {response, config} = error;
      if (response) {
        if (response.status === 401) {
          if (oauth && typeof oauth.login === 'function') {
            oauth.login()
          }
        } else {
          if (config.responseType === 'blob') {
            handleBlobError(config, errorHandler, response.data)
          } else {
            handleError(config, errorHandler, response.data)
          }
        }
      } else {
        handleError({}, errorHandler, {message: 'Connection Error'})
      }
    })
  }
}

function handleBlobError(config, errorHandler, errorDataBlob) {
  readBlob(errorDataBlob)
    .then(data => JSON.parse(data))
    .then(json => {
      handleError(config, errorHandler, json)
    })
}

function handleError(config, errorHandler, errorData) {
  if (!config.disableErrorMessage) {
    errorHandler(errorData)
  }
}

function readBlob(blob){
  return new Promise(((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      resolve(reader.result)
    });
    reader.addEventListener('error', (e) => {
      reject(e)
    });
    reader.readAsText(blob)
  }))
}
