const TR = {
   search_doctor:'Doktor ara, Kategorilerden  . . .',
   welcome:"Hoş Geldiniz",
   language:'Dil',
   hello:'Merhaba',
   settings:'Ayarlar',
   help:'Yardım',
   faqs:'Sıkça Sorulan Sorular',
   specialist_doctor:'Özel Doktorlar',
   get_care:'Sağlık Bilgilerim',
   request_a_doctor:'Doktor Randevusu İsteyin',
   emergency_doctors:'Acil Doctorlar',
   view_all:'Hepsini Göster',


   doctordedi_health:'Doctordedi önce sağlık dedi',
   doctordedi_issues:'Doctordedi ?',
   doctordedi_issues_text:'Hasta veya bireylerin sağlık sorunlarını konusunda uzman doktorlarla sohbet veya canlı sohbet  ile kolayca iletişim kurabilir.',
   doctordedi_who:'Kimler faydalanabilir ?',
   doctordedi_who_text:'Hasta veya Sağlık sorunları olan herkes faydalanabilir',
   notification:'Uyarılar',
   favoritedoctors:'Favori Doctorlarım',
   logout:'Çıkış Yap',
   onboarding_title0:'Binlerce doktor',
   onboarding_desc0:'Binlerce Doktora anında ulaşın. Doktorlarla kolayca iletişim kurabilir ve ihtiyaçlarınız için iletişime geçebilirsiniz.',
   onboarding_title1:"Doktorla canlı konuşma",
   onboarding_desc1:"Daha iyi tedaviniz ve\n Reçeteniz için doktorla kolayca bağlantı kurun ve\n görüntülü sohbet başlatın.",
   onboarding_title2:"doktorlarla sohbet et",
   onboarding_desc2:"Doktordan randevu alın.\n Randevu mektubu ile doktorla sohbet edin.Danışman alın.",
   placeholder_pass:"Şifre",
   signup_title0:"Onaylıyorum",
   signup_title1:"Hesap Oluştur",
   signup_message: 'Lütfen Kvkk ve Gizlilik sözleşmeleri onaylayın',
   signup_placeholder0:"İsim",
   signup_placeholder1:"Telefon Numarası",
   signup_placeholder2:"Şifre",
   signup_text:"Kvkk Sözleşmesi'ni okudum kabul ediyorum.",
   signup_text1:"Gizlilik sözleşmesi'ni okudum,kabul ediyorum.",
   signup_text2:"Zaten hesabınız var mı?",
   forgotpas_text0:"Parolanızı mı unuttunuz",
   forgotpas_text1:"Tek kullanımlık şifrenizi almak için lütfen aşağıya telefon numaranızı giriniz.",
   forgotpas_mainplaceholder:"Telefon numaranızı girin",
   forgotpas_title:"Tek kullanımlık şifre gönder",
   otpscrenn_text0:"Tek kullanımlık şifre",
   otpscrenn_text1:"Numaranıza gönderilen Tek Kullanımlık Şifre numarasını aşağıya yazınız.",
   otpscrenn_text2:"Kodu tekrar gönder",
   otpscrenn_title:"Şifre Değiştir",
   newpass_text0:"Kurtarma şifresi",
   newpass_text1:"Yeni şifrenizi girin ve şifrenizi sıfırlamak için şifrenizi onaylayın.",
   newpass_mainplacholder:"Parolanı Gir",
   newpass_mainplacholder1:"Parolayı onaylayın",
   newpass_placholder:"Şifreyi Onayla",
   newpass_title:"Şifreyi yenile",
   editprof_header:"Profili Düzenle",
   editprof_palcholder:"İsim",
   editprof_palcholder1:"Soyisim",
   editprof_palcholder2:"Cinsiyet",
   editprof_palcholder3:"Doğum tarihi",
   editprof_palcholder4:"Yaş",
   editprof_title:"Kaydet",
   faq_desc0:"DoctorPoint Pro danışma ağına dahil olmayan herhangi bir doktor türü var mı?",
   faq_desc1:"Sınırsız çevrimiçi danışma nasıl çalışır?",
   faq_desc2:"Kaç çevrimiçi danışma kullanabilirim?",
   faq_desc3:"Aile üyeleri hesabımı kullanabilir mi?",
   faq_desc4:"Bir Doctor Point Pro Üyeliğinin kaç üyesi olabilir?",
   faq_header:"SSS",
   help_header:"yardım",
   help_placeholder:"Mesajınızı yazın",
   help_text:"Bir e-posta göndermek için yukarıdaki formu doldurun ve ekip üyelerimizden biri sorunuzu en kısa sürede dönüş sağlıyacaktır.",
   help_title:"Postayı gönder",
   notif_name0:"Seri hatırlatma",
   notif_number0:"Seri numaranız randevu listesine başarıyla eklenir. Seri numarası 25. Doktor Noktası sizi 15 dakikadan önce fark edecektir.",
   notif_name1:"Randevu alarmı",
   notif_number1:"Randevunuz 15 dakika sonra başlayacaktır. Uygulamayla kalın ve kendinize iyi bakın.",
   notif_name2:"Randevu onaylandı",
   notif_number2:"Randevunuz randevu listesine eklenecektir. Sizi 10 Haziran 2020'de saat 11: 00'de arayacak.",
   notif_header:"Bildirim",
   notif_text:"Bugün - 10 Haziran 2020",
   notif_text1:"11 Haziran 2020",
   pati_header:"Hastanın detayları",
   pati_text0:"Yaş aralığınızı veya türünüzü seçin",
   pati_text1:"Erkek",
   pati_text2:"Kadın",
   pati_placeholder:"Açıklamayı girin...",
   pati_title:"Sonraki 1/4",
   pati2_text0:"Ne zamandır bu semptomları yaşıyorsun?",
   pati2_text1:"6 gün önce",
   pati2_title:"'İlerle 2/4'",
   pati3_text0:"Şu anda başka bir ilaç kullanıyor musunuz?",
   pati3_title:"'İlerle 3/4'",
   pati4_text0:"Bilinen herhangi bir ilaç alerjiniz var mı?",
   pati4_text1:"Bilinen alerjilerinizi listeleyin",
   pati4_placeholder:'Bilinen alerjilerinizi girin...',
   pati4_placeholder1:'Geçerli Konumu girin...',
   pati4_title:"Talep",
   doctordetail_header:"Doktor Detay",
   doctordetail_title:'Randevu al',
   paymnet_header:"Ödeme",
   payment_text0:"Banka / kredi kartı ekleyerek, aşağıdakileri kabul etmiş olursunuz:",
   payment_text1:"Şartlar ve Koşullar",
   payment_mainplac:"Kart sahibinin tam adını girin",
   payment_placeholder:"Kart numarası",
   payment_mainplac1:"Kart numarasını girin",
   payment_placeholder1:"Son kullanma tarihi",
   payment_mainplac2:"CVV giriniz"

};


  export default TR;