import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useEffect} from "react";
import DoctorService from "../../service/doctor/doctor.service";
import DoctorBox from "./DoctorBox";

const DoctorList = ({setLoading}) =>{

    const [branchId,setBranchId] = useState(useSelector(state => state.appointment.branch.id));
    const doctorService = new DoctorService(useSelector(state => state.auth.user.accessToken));
    const [doctorList,setDoctorList] = useState([]);

    useEffect(()=>{
        setLoading(true);
        doctorService.getDoctorListByBranchId(branchId).then(res=>{
            if (res&&res.object.length>0){
                setDoctorList(res.object);
                setLoading(false);
            }
        }).catch(error=>{
            setLoading(false);
        })
    },[branchId])

    return(
        doctorList.length>0&&doctorList.map(doctor=>{
            return(
                <DoctorBox doctor={doctor}/>
            )
        })
    )
}

export default DoctorList;
