import { useRef } from "react";
import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "primeicons/primeicons.css";
import {Link, useLocation} from "react-router-dom";
import logo from "../../assets/img/logo.png";
import "./TopBanner.css";
import { useSelector } from "react-redux";
import { useAuth } from "../../auth/use.auth";

function TopBanner() {
  const navRef = useRef();
  const { onLogout } = useAuth();
  const location = useLocation();

  const isMobile = location.pathname.includes('mobile-payment')||location.pathname.includes('mobile-meet');

  let user = useSelector((state) => state.auth.user);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
      !isMobile ?
    <header>
      <div style={{display:"flex",flex:3,justifyContent:"start"}}>
        <Link to="/">
          <img className="logo" src={logo} alt="logo" />
        </Link>
      </div>
      <nav ref={navRef}>
        <Link to="/about" onClick={showNavbar}>
          Neden DoctorDedi
        </Link>
        <Link to="/doctorandbranch" onClick={showNavbar}>
          Branşlar ve Doktorlar
        </Link>

        <Link to="/blog" onClick={showNavbar}>
          Blog
        </Link>
        <Link to="/sss" onClick={showNavbar}>
          SSS
        </Link>
          <Link to="/contact" onClick={showNavbar}>
              İletişim
          </Link>
        {!user.accessToken ?<Link style={{padding:'5px'}} to="/bepartner" onClick={showNavbar} className="doctor-register-btn">
          Danışman Ol
        </Link> : '' }
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>

      <div style={{display:"flex",flex:1,justifyContent:"flex-end"}}>
      {user.accessToken ? (
        <div className="dropdown navbar-profile pull-right">
          <button
            className="btn dropdown-toggle"
            style={{marginTop:'18%'}}
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className="pi pi-user"
              style={{ fontSize: "1.5em", color: "#1a98d5" }}
            ></i>
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link to={`profile`} className="dropdown-item">
                Profil
              </Link>
            </li>
            <li>
              <Link
                to={`profile`}
                state={{ isEdit: true }}
                className="dropdown-item"
              >
                Profili Düzenle
              </Link>
            </li>
              {user&&user.userRoles&& user.userRoles[0]&&user.userRoles[0].name ==='ROLE_DOCTOR' && <li>
                  <Link
                      to={`profile`}
                      state={{isSeansEdit:true}}
                      className="dropdown-item"
                  >
                      Seans Bilgileri
                  </Link>
              </li>}
              {user&&user.userRoles&& user.userRoles[0]&&user.userRoles[0].name ==='ROLE_DOCTOR' && <li>
              <Link
                to={`profile`}
                state={{isGalleriaEdit:true}}
                className="dropdown-item"
              >
                Galeriyi Düzenle
              </Link>
            </li>}
            <li>
              <Link
                to={`changepassword`}
                state={{ isEdit: true }}
                className="dropdown-item"
              >
                Şifre Değiştir
              </Link>
            </li>
            <li>
              <Link to="/" onClick={onLogout} className="dropdown-item">
                Çıkış Yap
              </Link>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
      </div>
    </header> : null
  );
}

export default TopBanner;
