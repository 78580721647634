import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import "./contanier.css"
const Contanier = ({children,isScrollTopReset=true}) => {
    const location =useLocation();
    const ref = useRef(null)
    
    const onScrollTop = () => {
        if(isScrollTopReset){
          ref.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
    

    useEffect(() => {
       if(ref.current&&location.pathname){
        
        onScrollTop()
       }
    }, [ref.current,location.pathname])


  return (
    <div className='main-contanier' ref={ref}  >
      {children}
    </div>
  )
}

export default Contanier
