import React, { useEffect, useState } from "react";
import "../../css/contact.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import contactimg from "../../assets/img/contact1.png";
import contactimg1 from "../../assets/img/contactimg1.png";
import contactimg2 from "../../assets/img/contactimg2.png";
import DoctorServiceWithoutToken from "../../service/doctor/doctor.service.without.token";
import swal from "sweetalert";
import BranchServiceWithoutToken from "../../service/branch/branch.service.without.token";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {ProgressSpinner} from "primereact/progressspinner";
import {FileUpload} from "primereact/fileupload";
import {Divider} from "primereact/divider";

const ContactPage = () => {
  const doctorService = new DoctorServiceWithoutToken();
  const branchService = new BranchServiceWithoutToken();
  const [phone, setPhone] = useState(null);
  const [validationError, setValidationError] = useState("");

  const [branchList, setBranchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const phoneOnChange = (val) => {
    setPhone(val);
    setValidationError("");
  };

  const _initialValues = () => {
    return {
      name: "",
      surname: "",
      email: "",
      branchId: 19,
    };
  };

  const _validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required("Zorunlu alan"),
      surname: Yup.string().required("Zorunlu alan"),
      email: Yup.string()
        .email("Geçersiz e-posta formatı")
        .required("Zorunlu Alan"),
      branchId: Yup.string().required("Zorunlu Alan"),
    });
  };

  const renderLoading=()=>{
    return(
        <div style={{display:"flex",justifyContent:"center"}} className="container">
          <ProgressSpinner style={{width: '30px', height: '30px'}} />
        </div>
    )
  }

  const _handleSubmit = (values, { resetForm }) => {
    if (
      phone === undefined ||
      phone === null ||
      phone === "" ||
      !isValidPhoneNumber(phone)
    ) {
      setValidationError("Lütfen telefon numaranızı kontrol ediniz.");
      return;
    }

    if (!selectedPdf){
      setValidationError("Lütfen cv seçiniz.");
      return;
    }

    setValidationError("");

    setLoading(true);

    let request = {
      ...values,
      phone: phone,
      branchData: { id: values.branchId },
      nameSurname: values.name + " " + values.surname,
    }

    doctorService
      .applyToBePartner(selectedPdf,JSON.stringify(request))
      .then((res) => {
        if (res && res.success) {
          if (res.message) {
            resetForm();
            setPhone(null);
            setLoading(false);
            return swal(
              "Başarılı!",
              "Danışmanlık talebiniz alınmıştır. Onay sürecinin ardından sms ve email ile bilgilendirileceksiniz.",
              "success"
            );
          }
        } else {
          setLoading(false);
          if (res.message) {
            return swal("Hata!", res.message, "error");
          }
          swal(
            "Hata!",
            "Bir sorun oluştu. Lütfen sonra tekrar deneyiniz.",
            "error"
          );
        }
      })
      .catch((res) => {
        swal("Hata!", "Lütfen sonra tekrar deneyiniz.", "error");
      }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    branchService.list().then((res) => {
      if (res && res.object.length > 0) {
        let dataSearch = res.object.map((branch) => ({
          name: branch.birimAdi,
          disabled: false,
          value: branch.id,
        }));

        setBranchList(dataSearch);
      }
    });
  }, []);

  return (
    <Formik
      initialValues={_initialValues()}
      validationSchema={_validationSchema}
      onSubmit={_handleSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldTouched,
      }) => (
        <section className="contactsec">
          <img className="contactimg" src={contactimg1} alt="contact" />
          <div className="contactformbase">
            <img
              className="contactborderimg img-responsive"
              style={{ backgroundColor: "white" }}
              src={contactimg}
              alt="contact"
            />
            <h3 className="contactformh3">Danışman Ol</h3>
            {loading&&renderLoading()}
            <Form className="contactform" onSubmit={handleSubmit}>
              <div className="div_contactform_namemail">
                <Field
                  style={{
                    margin: "1%",
                    borderRadius: "2.5em",
                    textAlign: "center",
                    border: "3px solid #0785c7",
                  }}
                  value={values.name}
                  onChange={handleChange("name")}
                  onBlur={() => setFieldTouched("name")}
                  className={
                    "form-control" +
                    (errors.name && touched.name ? " is-invalid" : "")
                  }
                  placeholder="Ad"
                />
                {errors.name && touched.name && (
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                )}
              </div>
              <div className="div_contactform_namemail">
                <Field
                  style={{
                    margin: "1%",
                    borderRadius: "2.5em",
                    textAlign: "center",
                    border: "3px solid #0785c7",
                  }}
                  value={values.surname}
                  onChange={handleChange("surname")}
                  onBlur={() => setFieldTouched("surname")}
                  className={
                    "form-control" +
                    (errors.surname && touched.surname ? " is-invalid" : "")
                  }
                  placeholder="Soyad"
                />
                {errors.surname && touched.surname && (
                  <ErrorMessage
                    name="surname"
                    component="div"
                    className="invalid-feedback"
                  />
                )}
              </div>
              <div className="div_contactform_namemail">
                <Field
                  style={{
                    margin: "1%",
                    borderRadius: "2.5em",
                    textAlign: "center",
                    border: "3px solid #0785c7",
                  }}
                  value={values.email}
                  onBlur={() => setFieldTouched("email")}
                  onChange={handleChange("email")}
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                  placeholder="Email Adresiniz"
                />

                {errors.email && touched.email && (
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                )}
              </div>

              <div>
                <PhoneInput
                  className="form-control form-control-not-block"
                  placeholder="Telefon"
                  value={phone}
                  onChange={phoneOnChange}
                  style={{
                    margin: "1%",
                    borderRadius: "2.5em",
                    textAlign: "center",
                    border: "3px solid #0785c7",
                  }}
                />
              </div>

              <Field
                as="select"
                name="branchId"
                className={
                  "form-control" +
                  (errors.branchId && touched.branchId ? " is-invalid" : "")
                }
                style={{
                  margin: "1%",
                  borderRadius: "2.5em",
                  textAlign: "center",
                  border: "3px solid #0785c7",
                }}
              >
                {branchList.map((branch) => {
                  return <option value={branch.value}>{branch.name}</option>;
                })}
              </Field>
              <div style={{display:"flex",justifyContent:"center"}}>
                <FileUpload
                    mode="basic"
                    name="demo[]"
                    url="/"
                    accept="application/pdf"
                    chooseOptions={{label: 'Cv Seç', icon: 'fa-solid fa-upload'}}
                    onSelect={(e) => {
                      setSelectedPdf(e.files[0]);
                      setValidationError("");
                    }}
                    onUpload={() => { console.log('uploaded'); }}
                />
              </div>
              <Divider align="center" >
              </Divider>
              <div className="signup-button-div">
                <span style={{ color: "red", fontSize: "12px" }}>
                  {validationError}
                </span>
              </div>
              <button className="contactformbutton">GÖNDER</button>
            </Form>
          </div>

          <img className="contactimg" src={contactimg2} alt="contact" />
        </section>
      )}
    </Formik>
  );
};

export default ContactPage;
