import React, { useState, createContext } from "react";

export const MeetContext = createContext();

export const MeetProvider = ({ children }) => {
    const [name, setName] = useState("");
    const [roomId, setRoomId] = useState("");

    return (
        <MeetContext.Provider value={[name, setName,roomId,setRoomId]}>
            {children}
        </MeetContext.Provider>
    );
};
