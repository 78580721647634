import AxiosConfigurationService from "../axios.configuration.service"

export default class BaseService extends AxiosConfigurationService {
  // constructor(token) {
  //   super(token);
  // }

  findAll = () => {
    return this.httpClient.get(this.endpoint, this.headers).then(res => res.data).catch(error => {
      return error.message
    })
  }

  findById = (id) => {
    return this.httpClient.get(this.endpoint + '/' + id, this.headers).then(res => res.data).catch(error => {
      return error.message
    })
  }

  create = (data) => {
    return this.httpClient.post(this.endpoint, data, this.headers).then(res => {
      return res.data;
    }).catch(error => {

    })
  }

  createWithoutToken = (data) => {
    return this.httpClient.post(this.endpoint, data, null).then(res => {
      return res.data;
    }).catch(error => {

    })
  }

  remove = (id) => {
    return this.httpClient.delete(this.endpoint + '/' + id, this.headers).then(res => res.data).catch(error => {
      return error.message
    })
  }

  update = (data) => {
    return this.httpClient.put(this.endpoint + "/update", data, this.headers).then(res => {
      return res.data;
    })
  }

  findByPagination = (data) => {
    data.privilege = this.privilege;
    return this.httpClient.post(this.endpoint + "/findByPagination", data, this.headers).then(res => res.data).catch(error => {

    })
  }

}
