import BaseService from "../base/base.service";

export default class DoctorCalendarService extends BaseService {

    constructor(token) {
        super(token);
        this.endpoint = '/doctorCalendar';
    }

    getDoctorCalendarList = (data) => {
        return this.httpClient.post(this.endpoint + "/list", data,this.headers).then(res => {
            return res.data;
        })
    }

    getDoctorAvailableHours = (data) => {
        return this.httpClient.post(this.endpoint + "/availableHours", data,this.headers).then(res => {
            return res.data;
        })
    }

    save = (data) => {
        return this.httpClient.post(this.endpoint + "/saveNew", data,this.headers).then(res => {
            return res.data;
        })
    }

}
